export class ContextModel {
    token: string = "";
    tokenExpiryTime: string = "";
    isFatchinToken: boolean = false;
    userId: number = 0;
    userKey: string = "";
    userRole: any = "";
    loginClick: boolean = true;
    firstName: string = "";
    lastName: string = "";
    fullName: string = "";
    email: string = "";
    countryID: number = 91;
    isAlert: boolean = false;
    alertMessage: string = "";
    alertType: string = "success";
    isLoadding: boolean = false;
    isMenuVisible: boolean = false;
    isThemeDark: boolean = false;
    isUnauthorized: boolean = false;
    AndroidAppVersion: string = "";
    AndroidBuildNumber: number = 0;
  }
  