
import { GET, POST } from "./common/http";
import { BASE_URL } from "./common/const";


export const GetDocumentDetailById = (params: any) =>
  GET(`${BASE_URL}/api/File/GetDetailById?` + params, null);

export const GetDocumentList = (params: any) =>
  GET(`${BASE_URL}/api/File/GetList?` + params, null);

export const GetDocumentBreadcrumbList = (params: any) =>
  GET(`${BASE_URL}/api/File/GetBreadcrumbList?` + params, null);

export const DeleteDocument = (data: any) =>
  POST(`${BASE_URL}/api/File/Delete`, data);

export const UpdateDocument = (data: any) =>
  POST(`${BASE_URL}/api/File/Update`, data, true);

export const CreateDocument = (data: any) =>
  POST(`${BASE_URL}/api/File/Create`, data, true);
 
export const ShareDocument = (data: any) =>
  POST(`${BASE_URL}/api/File/ShareDocument`, data, true);

export const RemoveAccess = (data: any) =>
  POST(`${BASE_URL}/api/File/RemoveDocumentAccess`, data);

export const UpdateAccess = (data: any) =>
  POST(`${BASE_URL}/api/File/UpdateDocumentAccess`, data);

