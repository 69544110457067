import React, { useContext, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import './../../theme/SignIn.scss';
import { Box, Button, FormControl, Fade, TextField, Typography, Grid, Paper, FormLabel, IconButton, InputAdornment, FormControlLabel, Checkbox, styled, Stack, Alert } from '@mui/material';
import logo from '../../assets/images/logo.png';
import { Link, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Login } from '../../services/AuthService'
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { PreferencesContext } from '../../PreferenceContext';
import { ContextModel } from '../../services/models/Context';

const CustomTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: '#fff', // Set the desired background color
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.grey[300],
            borderWidth: '1px',
            BorderStyle: 'solid'
        },
        '&:hover fieldset': {
            borderColor: theme.palette.grey[500],
            borderWidth: '1px',
            BorderStyle: 'solid'
        },
        '&.Mui-focused fieldset': {
            borderWidth: "2px",
            borderColor: theme.palette.primary.main,
        },
    },
}));

const formInitialValues = {
    email: "",
    password: "",
    isRemember: false
};

export default function SignIn() {
    const [loading, setLoading] = useState(false);
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [showAlert, setAlertMessage] = useState({
        isSuccess: false,
        message: "",
    });
    const [values, setValues] = useState({
        password: '',
        showPassword: "",

    })
    let navigate = useNavigate();

    // ----for Validation--- 
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email("Enter a valid email.")
            .required("Email is required."),
        password: Yup.string()
            .required("Password is required."),
    });

    // registration
    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    }

    async function submitLoginForm(value: any) {
        let email = value.email
        let password = value.password
        setLoading(true)
        try {
            const user: any = await Login({

                Username: email,
                Password: password

            })
            if (user) {
                let curuserInfo: ContextModel = new ContextModel();
                curuserInfo.token = user.data.token;
                curuserInfo.tokenExpiryTime = user.data.tokenExpiryTime;
                curuserInfo.userId = user.data.userId;
                curuserInfo.userKey = user.data.userKey;
                curuserInfo.userRole = user.data.roles[0].toLowerCase();
                curuserInfo.firstName = user.data.firstName;
                curuserInfo.lastName = user.data.lastName;
                curuserInfo.fullName = user.data.fullName;
                curuserInfo.email = user.data.email;
                curuserInfo.isMenuVisible = true;
                curuserInfo.isLoadding = true;
                curuserInfo.isFatchinToken = false;
                updateUserContext(curuserInfo);                
                navigate('/home')
            }
            setLoading(false)
        } catch (error) {
            if (error) {
                setAlertMessage({
                    isSuccess: true,
                    message: error as string,
                });
            }
            setLoading(false)
        }

    }

    return (
        <Fade in={true} timeout={{ enter: 500 }}>
            <Box className="signin-container">
                <Paper className="paper-singin">
                    <Grid item className="signin-wrapper" >
                        <React.Fragment>
                            <Box
                                className="box-container"
                                sx={{ my: 2 }}>
                                <Box
                                    className="paper-container-forgot paper-pre-signin"
                                    sx={{ justifyItems: "center" }}
                                >
                                    <Grid
                                        display='flex'
                                        justifyContent="start"
                                        item>
                                        <img loading="lazy" className='img-page'
                                            src={logo} alt="logo" />
                                    </Grid>
                                    <Grid item className='pre-signin-text' sx={{ fontSize: '18px !important',color:"#ac8445",pt:2 }}><b>Welcome to Tiya Group</b></Grid>
                                    <Formik
                                        initialValues={formInitialValues}
                                        onSubmit={(values) => {
                                            submitLoginForm(values);
                                        }}
                                        validationSchema={validationSchema}
                                    >
                                        {(props) => {
                                            return (

                                                <Grid container>
                                                    <Form>
                                                        {showAlert.isSuccess ? (
                                                            <Grid marginTop={2}>
                                                                <Stack sx={{ width: "100%" }}>
                                                                    <Alert severity="error">{showAlert.message}</Alert>
                                                                </Stack>
                                                            </Grid>
                                                        ) : (
                                                            ""
                                                        )}
                                                        <Grid container spacing={1} marginTop={1}>
                                                            <Grid item xs={12}>
                                                                <FormControl fullWidth>
                                                                    <FormLabel sx={{ display: 'flex', justifyContent: 'left', fontSize: '13px' }}>Email</FormLabel>
                                                                    <CustomTextField
                                                                        name="email"
                                                                        autoFocus
                                                                        autoCapitalize='none'
                                                                        type="email"
                                                                        fullWidth
                                                                        size="small"
                                                                        margin="dense"
                                                                        value={props.values.email}
                                                                        inputProps={{ enterKeyHint: 'next' }}
                                                                        onChange={props.handleChange("email")}
                                                                        onBlur={props.handleBlur}
                                                                        tabIndex={1}
                                                                    />
                                                                    <Typography
                                                                        variant="inherit"
                                                                        className="validation-message"
                                                                    >
                                                                    </Typography>
                                                                </FormControl>
                                                            </Grid>

                                                            <Grid item xs={12} marginTop={1}>
                                                                <FormLabel sx={{ display: 'flex', justifyContent: 'left', fontSize: '13px' }}>Password</FormLabel>
                                                                <CustomTextField
                                                                    name="password"
                                                                    placeholder=""
                                                                    // label="Password*  "
                                                                    fullWidth
                                                                    size="small"
                                                                    margin="dense"
                                                                    value={props.values.password}
                                                                    onChange={props.handleChange("password")}
                                                                    onBlur={props.handleBlur}
                                                                    tabIndex={2}
                                                                    inputProps={{ enterKeyHint: 'next' }}
                                                                    type={values.showPassword ? "text" : "password"}
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <IconButton
                                                                                    style={{ paddingRight: 20, paddingLeft: 0 }}
                                                                                    aria-label="toggle password visibility"
                                                                                    onClick={handleClickShowPassword}
                                                                                    edge="end"
                                                                                    id="eyebutton"
                                                                                >
                                                                                    {values.showPassword ? (
                                                                                        <VisibilityOff />
                                                                                    ) : (
                                                                                        <Visibility />
                                                                                    )}
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }}
                                                                />
                                                                <Typography
                                                                    variant="inherit"
                                                                    className="validation-message"
                                                                >
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Box className="forgot-psw-box">
                                                                    {/* <Typography>
                                                                        <FormControlLabel
                                                                            className="forgot-psw-button"
                                                                            control={<Checkbox name="isRemember" checked={props.values.isRemember} value={props.values.isRemember} onChange={props.handleChange("isRemember")} color="primary" />}
                                                                            label={
                                                                                <Box component="div" fontSize={13}>
                                                                                    Remember me
                                                                                </Box>
                                                                            }
                                                                        >
                                                                        </FormControlLabel>
                                                                    </Typography> */}
                                                                    <Typography>
                                                                        <Button
                                                                            disableRipple
                                                                            color="inherit"
                                                                            tabIndex={4}
                                                                            className="forgot-psw-button"
                                                                            onClick={() => navigate('/forgot-password')}
                                                                        >
                                                                            Forgot Password?
                                                                        </Button>
                                                                    </Typography>
                                                                </Box>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid container spacing={3} marginTop={0}>
                                                            <Grid item xs={12} textAlign="right">
                                                                <LoadingButton
                                                                    color="primary"
                                                                    loading={loading}
                                                                    fullWidth
                                                                    tabIndex={3}
                                                                    loadingPosition="start"
                                                                    startIcon={<></>}
                                                                    variant="contained"
                                                                    type="submit"
                                                                    className='submit-btn button-border-remove'
                                                                    name="submit">
                                                                    Sign In
                                                                </LoadingButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Form>
                                                </Grid>)
                                        }}
                                    </Formik>
                                </Box>
                            </Box>
                        </React.Fragment>
                    </Grid>
                </Paper>
            </Box>
        </Fade>
    );
}