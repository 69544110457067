import { GET, POST } from "./common/http";
import { BASE_URL } from "./common/const";


export const GetUserGroupDetailById = (params: any) =>
    GET(`${BASE_URL}/api/UserGroup/GetDetailById?` + params, null);

export const GetUserGroupList = (params: any) =>
    GET(`${BASE_URL}/api/UserGroup/GetList?` + params, null);

export const GetUserGroupDocumentList = (params: any) =>
    GET(`${BASE_URL}/api/UserGroup/GetListDocument?` + params, null);

export const DeleteUserGroup = (data: any) =>
    POST(`${BASE_URL}/api/UserGroup/Delete`, data);

export const GetUserGroupMemberList = (params: any) =>
    GET(`${BASE_URL}/api/UserGroup/GetUserGroupMemberList?` + params, null);

export const UpdateUserGroup = (data: any) =>
    POST(`${BASE_URL}/api/UserGroup/Update`, data);

export const CreateUserGroup = (data: any) =>
    POST(`${BASE_URL}/api/UserGroup/Create`, data);

export const TransferMembersToAnotherUserGroup = (data: any) =>
    POST(`${BASE_URL}/api/UserGroup/TransferMembers`, data);

