import { GET, POST } from "./common/http";
import { BASE_URL } from "./common/const";


export const Login = (data: any) =>
   POST(`${BASE_URL}/api/Auth/Login`, data);

export const ForgotPassword = (data: any) =>
   POST(`${BASE_URL}/api/Auth/ForgotPassword`, data);

export const ResetPassword = (data: any) =>
   POST(`${BASE_URL}/api/Auth/ResetPassword`, data);

export const SetPassword = (data: any) =>
   POST(`${BASE_URL}/api/Auth/SetPassword`, data);

export const CheckIsExpireInvitation = (data: any) =>
   POST(`${BASE_URL}/api/Auth/CheckIsExpireInvitation`, data);

export const RefreshToken = (data: any) =>
   POST(`${BASE_URL}/api/Auth/RefreshToken`, data);

export const Info = (params: any) =>
   GET(`${BASE_URL}/api/Auth/Info?` + params, null);

