import { GET, POST } from "./common/http";
import { BASE_URL } from "./common/const";


export const GetTagDetailById = (params: any) =>
  GET(`${BASE_URL}/api/Tag/GetDetailById?` + params, null);

export const GetTagList = (params: any) =>
  GET(`${BASE_URL}/api/Tag/GetList?` + params, null);

export const DeleteTag = (data: any) =>
  POST(`${BASE_URL}/api/Tag/Delete`, data);

export const UpdateTag = (data: any) =>
  POST(`${BASE_URL}/api/Tag/Update`, data);

export const CreateTag = (data: any) =>
  POST(`${BASE_URL}/api/Tag/Create`, data);
 

