import React, { useContext, useEffect, useState } from 'react'
import {
  Box, Grid, IconButton, InputAdornment, TextField, Button, styled, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ListItemText,
  Tooltip,
  Pagination,
  Stack,
  useMediaQuery,
  useTheme,
  DialogContent,
  Dialog,
  Typography,
  FormControl,
  DialogContentText,
  DialogActions,
  Avatar,
  ListItemIcon,
  DialogTitle,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import LoadingButton from "@mui/lab/LoadingButton";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from "@mui/icons-material/Search";
import { useLocation, useNavigate } from 'react-router-dom';
import '../../theme/style.scss';
import { EncryptDecryptService } from '../../services/common/EncryptionService';
import { PAGE_SIZE } from '../../services/common/const';
import { PreferencesContext } from '../../PreferenceContext';
import { AlertType } from '../../services/models/AlertType';
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form } from 'formik';
import * as yup from "yup";
import { CreateDocument, DeleteDocument, GetDocumentBreadcrumbList, GetDocumentDetailById, GetDocumentList, ShareDocument, UpdateDocument, RemoveAccess, UpdateAccess } from '../../services/FileService';
import FolderIcon from '@mui/icons-material/Folder';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import GridOnIcon from '@mui/icons-material/GridOn';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import ImageIcon from '@mui/icons-material/Image';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import CodeIcon from '@mui/icons-material/Code';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DownloadIcon from '@mui/icons-material/Download';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';
import { format } from 'date-fns';
import CircularProgress from '@mui/material/CircularProgress';
import CustomBreadcrumbsHome from '../../components/BreadcrumbsComponent/CustomBreadcrumbsHome';
import { MultiSelect } from '../../components/Common/MultiSelect';
import { GetUserGroupDocumentList, GetUserGroupList } from '../../services/UserGroupService';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import DatePickerComponent from '../../components/Common/DatePicker';
import dayjs from 'dayjs';
import { GetTagList } from '../../services/TagService';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import FolderZipIcon from '@mui/icons-material/FolderZip';

const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: '#fff', // Set the desired background color
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.grey[300],
      borderWidth: '1px',
      BorderStyle: 'solid'
    },
    '&:hover fieldset': {
      borderColor: theme.palette.grey[500],
      borderWidth: '1px',
      BorderStyle: 'solid'
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: "2px",
    },
  },
}));

interface Data {
  id: number;
  Name: string;
  none: string;
}
let groupColl = {
  document_id: 0,
  user_group_id: 0,
  group_name: "",
  access_type: "",
  is_deleted: false
}
let tagColl = {
  documentId: 0,
  tag_id: 0,
  tag_name: "",
  is_deleted: false
}
const initialForm = {
  documentId: 0,
  documentName: "",
  mode: "",
  documentTypeCode: "",
  fileOriginalName: "",
  fileView: "",
  subjectSearch: "",
  documentNameSearch: "",
  registrationNumberSearch: "",
  startDateOfLetterSearch: "",
  endDateOfLetterSearch: "",
  startRegistrationDateSearch: "",
  endRegistrationDateSearch: "",
  startExpirationDateSearch: "",
  endExpirationDateSearch: "",
  userGroupId: [groupColl],
  tagId: [tagColl]
}
const validateSchema = yup.object().shape({
  documentName: yup.string().required("Folder Name is required."),
});
function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc" | "";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  numSelected: number;
  order: Order;
  orderBy: string;
  rowCount: number;
}



//Table Header
function EnhancedTableHead(props: EnhancedTableProps) {
  return (
    <TableHead className='table-header-color'>
      <TableRow>
        <TableCell
          sx={{
            display: {
              xs: "table-cell",
              sm: "table-cell",
              md: "table-cell",
              lg: "table-cell",
            },
            fontWeight: "bold",
            width: "20%"
          }}
          align="left"
        >
          Name
        </TableCell>
        <TableCell
          sx={{
            display: {
              xs: "none",
              sm: "table-cell",
              md: "table-cell",
              lg: "table-cell",
            },
            fontWeight: "bold",
            width: "10%"
          }}
          align="left"
        >
          Reg/Ref No
        </TableCell>
        <TableCell
          sx={{
            display: {
              xs: "none",
              sm: "table-cell",
              md: "table-cell",
              lg: "table-cell",
            },
            fontWeight: "bold",
            width: "10%"
          }}
          align="center"
        >
          Date of Letter
        </TableCell>
        <TableCell
          sx={{
            display: {
              xs: "none",
              sm: "table-cell",
              md: "table-cell",
              lg: "table-cell",
            },
            fontWeight: "bold",
            width: "10%"
          }}
          align="left"
        >
          Tag Name
        </TableCell>
        <TableCell
          sx={{
            display: {
              xs: "none",
              sm: "table-cell",
              md: "table-cell",
              lg: "table-cell",
            },
            fontWeight: "bold",
            width: "10%"
          }}
          align="left"
        >
          Subject
        </TableCell>
        <TableCell
          sx={{
            display: {
              xs: "none",
              sm: "table-cell",
              md: "table-cell",
              lg: "table-cell",
            },
            fontWeight: "bold",
            width: "10%"
          }}
          align="left"
        >
          Owner
        </TableCell>
        <TableCell
          sx={{
            display: {
              xs: "none",
              sm: "table-cell",
              md: "table-cell",
              lg: "table-cell",
            },
            fontWeight: "bold",
            width: "5%"
          }}
          align="left"
        >
          File size
        </TableCell>
        <TableCell
          sx={{
            display: {
              xs: "none",
              sm: "table-cell",
              md: "table-cell",
              lg: "table-cell",
            },
            fontWeight: "bold",
            width: "10%"
          }}
          align="center"
        >
          Last Modified
        </TableCell>
        <TableCell
          sx={{
            display: {
              xs: "none",
              sm: "table-cell",
              md: "table-cell",
              lg: "table-cell",
            },
            fontWeight: "bold",
            width: "30%"
          }}
          align="left"
        >
          {''}
        </TableCell>

      </TableRow>
    </TableHead>
  );
}

const Document: any = {
  documentCollection: [],
};
const edService = new EncryptDecryptService();

const parameters: any = {
  pageIndex: 1,
  pageSize: PAGE_SIZE,
  sortColumn: "",
  sortDirection: "",
  searchValue: "",
  subjectSearch: "",
  documentNameSearch: "",
  registrationNumberSearch: "",
  startDateOfLetterSearch: "",
  endDateOfLetterSearch: "",
  startRegistrationDateSearch: "",
  endRegistrationDateSearch: "",
  startExpirationDateSearch: "",
  endExpirationDateSearch: "",
};

export default function Home() {
  const Documentdata = {
    title: "Add Document",
    button: "Save",
    documentId: 0,
    isAddPopUp: false,
  };
  const navigate = useNavigate()
  const theme = useTheme()
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [documentsFiles, setDocumentsFile] = useState<any>([]);
  const [anchorEl, setAnchorEl3] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [OpenAccess, setOpenAccess] = useState(false);
  const [Open, setOpen] = useState(false);
  const [OpenAdvanceSearch, setOpenAdvanceSearch] = useState(false);
  const [DocumentName, setDocumentName] = useState('');
  const [accessDocumentId, setAccessDocumentId] = useState(0);
  const [temp, setTemp] = useState(false)
  const [folderData, setFolderData] = useState(initialForm);


  const [hasEditAccess, setHasEditAccess] = useState(false)

  const [accessTypeGroupShare, setAccessTypeGroupShare] = useState('VIEWER')
  const [accessGroupId, setAccessGroupId] = useState(0)
  const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorElMenu);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>, groupId: any, accessType: string) => {
    setAccessTypeGroupShare(accessType);
    setAccessGroupId(groupId);
    setAnchorElMenu(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAccessGroupId(0);
    setAnchorElMenu(null);
  };


  const [accessTypeShare, setAccessTypeShare] = useState('VIEWER')
  const [anchorElMenuGroup, setAnchorElMenuGroup] = React.useState<null | HTMLElement>(null);
  const openMenuGroup = Boolean(anchorElMenuGroup);
  const handleMenuGroupClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElMenuGroup(event.currentTarget);
  };
  const handleMenuGroupClose = () => {
    setAnchorElMenuGroup(null);
  };
  const handleShareAccessType = (props: any, accessType: string) => {
    const coll = props.values?.userGroupId?.map(x => {
      return { ...x, access_type: accessType }
    }) || [];
    props.setFieldValue("userGroupId", coll, true);
    setAccessTypeShare(accessType);
    setAnchorElMenuGroup(null);
  };


  const handleClose = (val: any) => {
    setOpen(false);
    setTemp(false);
    setFolderData(initialForm);
  };

  const handleCloseAccess = (val: any) => {
    setOpenAccess(false);
    setFolderData(initialForm);
  };

  const handleCloseAdvanceSearch = (val: any) => {
    setOpenAdvanceSearch(false);
  };

  function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 9)) & 0xff;
      color += `00${value.toString(12)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }
  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 25,
        height: 25,
        fontSize: 15,
      },
      children: `${name?.split(" ")[0][0]?.toUpperCase() || ''}`,
    };
  }

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let urlParams = useQuery();
  let curRawdocumentId = urlParams.get("d");
  let curdocumentId = 0;
  if (curRawdocumentId !== null && curRawdocumentId !== undefined) {
    let decId = (curRawdocumentId);
    if (decId !== "" && decId !== null && decId !== undefined) {
      curdocumentId = +decId;
    } else {
      navigate("/home");
    }
  }
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  const getFormattedFileSize = (bytes, decimals = 0) => {
    if (bytes === 0) return "0 Bytes";
    // JV
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  //Get Data
  async function fetchData(documentId: number) {
    try {
      //setLoading(true);
      let params = new URLSearchParams();
      params.append("documentId", documentId.toString());
      const result: any = await GetDocumentDetailById(params);
      let editDetails = result.data.data;
      let updatedValue = {
        documentId: editDetails.documentId,
        documentName: editDetails.documentName,
        fileOriginalName: editDetails.fileOriginalName || "",
        fileView: editDetails.fileView || "",
        mode: "U",
        documentTypeCode: editDetails.documentTypeCode,
        userGroupId: editDetails.userGroupId
      };
      setFolderData(updatedValue);

      if (updatedValue.fileView) {
        const fileName = updatedValue.fileOriginalName;
        const documentfile = dataURLtoFile(updatedValue.fileView, fileName);
        setDocumentsFile([documentfile]);
        return documentfile;
      }
      //setLoading(false);
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }
  }
  const downloadDocumentFile = (selectedFile) => {
    if (selectedFile) {
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(selectedFile);
      downloadLink.download = selectedFile.name;
      downloadLink.click();
    }
  };

  const handleClickDownload = async (id: number) => {
    let documentFile;
    if (id > 0) documentFile = await fetchData(id);
    downloadDocumentFile(documentFile);
  }
  const handleClickView = async (id: number) => {
    debugger
    let documentFile;
    if (id > 0) documentFile = await fetchData(id);
    documentFile.preview = URL.createObjectURL(documentFile);
    setPreviewDocDialog(true);
    setPreviewDoc([documentFile]);
  }
  const handleClickOpen = (id: number) => {
    if (id > 0) fetchData(id);
    setOpen(true);
  }
  const handleClickOpenAdvanceSearch = () => {
    setOpenAdvanceSearch(true);
  }
  const CloseSearch = () => {
    setValue("");
    navigate(
      0
    )
  }
  const handleClickOpenAccess = (Name: string, documentId: any) => {
    //if (id > 0) fetchData(id);
    setAccessDocumentId(documentId)
    setDocumentName(Name);
    setOpenAccess(true);
  }
  const matches = useMediaQuery("(max-width:699px)");
  const [param, setParam] = useState(parameters);
  const [selected, setSelected] = useState<readonly number[]>(
    Document.documentCollection);
  const [documentId, setDocumentId] = useState(0);
  const [groupId, setGroupId] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [pageStartIndex, setPageStartIndex] = useState(1);
  const [pageEndIndex, setPageEndIndex] = useState(1);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [value, setValue] = useState("");
  const [openDeleteModal, setopenDeleteModal] = React.useState(false);
  const [openAccessModal, setopenAccessModal] = React.useState(false);
  const [order, setOrder] = useState<Order>("");
  const [orderBy, setOrderBy] = useState<keyof Data>("none");
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE);
  const [resultData, setResultData] = useState(Document);
  const [page_Index, setPage_Index] = useState(1);
  const [refreshData, setRefreshData] = useState(0);
  const [counData, setCounData] = useState(0);
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  const [isLoading, setLoading] = useState(false);
  const [Curr_page, setCurrPage] = useState(1);
  const isSelected = (id: number) => selected.indexOf(id) !== -1;
  const [isOpenImport, setIsOpenImport] = React.useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [parentDocumentId, setParentDocumentId] = useState(curdocumentId);
  const [emptyParentDocumentId, setEmptyParentDocumentId] = useState(0);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [GroupCollection, setGroupCollection] = useState([]);
  const [GroupAccessCollection, setGroupAccessCollection] = useState([]);
  const [tagCollection, setTagCollection] = useState([]);
  //for view file
  const [previewDoc, setPreviewDoc] = React.useState<any>([]);
  const [previewDocDialog, setPreviewDocDialog] = React.useState(false);
  const handleCloseFilePreviewDialogBox = (val: any) => {
    setPreviewDocDialog(false);
  }; const img = {
    width: '-webkit-fill-available',
  };
  const handleChangePageNew = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    if (Curr_page !== value) {
      setCurrPage(value);
      setPage_Index(value);
      setParam({ ...param, pageIndex: value });
      fetchDocumentList(null);
    }
  };
  async function fetchTagList() {
    let tagInfo = [];
    try {
      setLoading(true);
      let params = new URLSearchParams();
      params.append("pageIndex", page_Index.toString());
      params.append("pageSize", param.pageSize);
      params.append("sortColumn", param.sortColumn);
      params.append("sortDirection", param.sortDirection);
      params.append("searchValue", param.searchValue);
      const result: any = await GetTagList(params);
      if (result && result.data.data) {
        tagInfo = result.data.data.tagCollection.map(tag => ({
          value: tag.value,
          label: tag.label,
          tagIds: tag.tagId
        }));
        setTagCollection(tagInfo);
      } else {
        console.log("Error");
      }
      setLoading(false);
      return tagInfo;
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e : e?.message,
        alertType: AlertType.Error,
      });
    }
  }
  async function fetchGroupList() {
    let groupInfo = [];
    try {
      setLoading(true);
      let params = new URLSearchParams();
      params.append("documentId", accessDocumentId.toString());
      params.append("pageIndex", page_Index.toString());
      params.append("pageSize", param.pageSize);
      params.append("sortColumn", param.sortColumn);
      params.append("sortDirection", param.sortDirection);
      params.append("searchValue", param.searchValue);
      const result: any = await GetUserGroupDocumentList(params);
      if (result && result.data.data) {
        let groupAccessInfo = result.data.data.userGroupAccessCollection;
        groupInfo = result.data.data.userGroupWithoutAccessCollection.map(group => ({
          value: group.value,
          label: group.label,
        }));
        setGroupCollection(groupInfo);
        setGroupAccessCollection(groupAccessInfo);
      } else {
        console.log("Error");
      }
      setLoading(false);
      return groupInfo;
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e : e?.message,
        alertType: AlertType.Error,
      });
    }
  }
  //Searching
  function searchValue(val: any) {
    if (val === "") {
      setValue("");
    }
    setPage_Index(1);
    setParam({ ...param, searchValue: val.toString(), pageIndex: 1 });
  }

  async function SubmitDocument(values: any, resetForm: any) {
    setLoading(true);

    let formData = new FormData();
    let documentData = values;
    let lParentDocumentId = parentDocumentId;

    if (lParentDocumentId === 0) {
      lParentDocumentId = emptyParentDocumentId;
    }

    formData.append("documentId", documentData?.documentId || 0);
    formData.append("documentName", documentData?.documentName || '');
    formData.append("parentId", lParentDocumentId?.toString() || '0');
    formData.append("documentTypeCode", "folder");
    formData.append("dataManagedBy", userContext.userId.toString());
    try {
      if (values?.documentId > 0) {
        // Update document logic
        const resultDocumentUpdate: any = await UpdateDocument(formData);
        if (resultDocumentUpdate && resultDocumentUpdate.data === true) {
          setSubmitting(false);
          updateUserContext({
            ...userContext,
            isAlert: true,
            alertMessage: resultDocumentUpdate.message,
            alertType: AlertType.Success,
          });
          setOpen(false);
          setRefreshData(refreshData === 0 ? 1 : 0)
          resetForm();

        }
      } else {
        // Create document logic
        const resultDocumentSave: any = await CreateDocument(formData);
        if (resultDocumentSave && resultDocumentSave.data === true) {
          setSubmitting(false);
          updateUserContext({
            ...userContext,
            isAlert: true,
            alertMessage: resultDocumentSave.message,
            alertType: AlertType.Success,
          });
          setOpen(false);
          setRefreshData(refreshData === 0 ? 1 : 0)
          resetForm();

        }
      }
    } catch (e: any) {

      setSubmitting(false);
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e : e?.message,
        alertType: AlertType.Error,
      });
    } finally {
      setLoading(false);
    }
  }




  async function handleShareDocument(values: any) {
    setLoading(true);
    const arr1 = values.userGroupId;
    let groupRecords = arr1.filter((e: any) => e.user_group_id >= 0);
    let formData = new FormData();
    let documentData = values;
    let nameAndEmail = userContext.fullName + " (" + userContext.email + ") "
    formData.append("documentId", parentDocumentId.toString());
    formData.append("documentName", DocumentName.toString());
    formData.append("userNameEmail", nameAndEmail);
    formData.append("userName", userContext.fullName);
    formData.append('shareDocument', groupRecords.length > 0 ? JSON.stringify(groupRecords) : "");
    formData.append("documentTypeCode", "folder");
    try {
      {
        // Create document logic
        const resultDocumentSave: any = await ShareDocument(formData);
        if (resultDocumentSave && resultDocumentSave.data === true) {
          setSubmitting(false);
          updateUserContext({
            ...userContext,
            isAlert: true,
            alertMessage: resultDocumentSave.message,
            alertType: AlertType.Success,
          });
          setOpen(false);
          navigate(
            0
          )
        }
      }
    } catch (e: any) {
      // Handle errors for both update and create
      setSubmitting(false);
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e : e?.message,
        alertType: AlertType.Error,
      });
    } finally {
      setLoading(false);
    }
  }
  const handleOpenDeleteDocumentModal = (e: any, documentId: any) => {
    setopenDeleteModal(true);
    setDocumentId(documentId)
  };

  const handleOpenDeleteAccessModal = (e: any, groupId: any) => {
    setAnchorElMenu(null);
    setopenAccessModal(true);
    setGroupId(groupId);
  };

  const handleCloseDeleteDocumentModal = () => {
    setopenDeleteModal(false);
    setAnchorEl3(null);
  };

  const handleCloseDeleteAccessModal = () => {
    setopenAccessModal(false);
    setAnchorEl3(null);
  };

  //Async Function
  async function fetchDocumentList(values: any) {
    try {
      if (values != null) {
        setValue("Advance Fillter")
      }
      setLoading(true);
      let params = new URLSearchParams();
      params.append("userId", userContext.userId.toString());
      params.append("pageIndex", page_Index.toString());
      params.append("pageSize", param.pageSize);
      params.append("sortColumn", param.sortColumn);
      params.append("sortDirection", param.sortDirection);
      params.append("searchValue", param.searchValue);
      params.append("documentId", parentDocumentId.toString());
      if (values != null) {
        const arr1 = values.tagId;
        let tagRecords = arr1.filter((e: any) => e.tag_id >= 0);
        let tagIds = tagRecords.map((e: any) => e.tag_id).join(',');
        params.append('tagIdList', tagIds);
        if (values.subjectSearch) params.append("subjectSearch", values.subjectSearch);
        if (values.documentNameSearch) params.append("documentNameSearch", values.documentNameSearch);
        if (values.registrationNumberSearch) params.append("registrationNumberSearch", values.registrationNumberSearch);

        params.append("startDateOfLetterSearch", values.startDateOfLetterSearch);
        params.append("endDateOfLetterSearch", values.endDateOfLetterSearch);
        // params.append("startRegistrationDateSearch", values.startRegistrationDateSearch);
        // params.append("endRegistrationDateSearch", values.endRegistrationDateSearch);
        // params.append("startExpirationDateSearch", values.startExpirationDateSearch);
        // params.append("endExpirationDateSearch", values.endExpirationDateSearch);
      }
      const result: any = await GetDocumentList(params);
      if (result && result.data.data) {
        let dataCount = result.data.data.totalCount;
        let hasEditAccess = result.data.data.hasEditAccess;
        let documentInfo = result.data.data;
        let showDataCount = Math.ceil(dataCount / PAGE_SIZE);

        setResultData(documentInfo);
        setCounData(showDataCount);

        if (parentDocumentId === 0) {

          if (documentInfo != null && documentInfo.documentCollection != null && documentInfo.documentCollection.length > 0) {
            setEmptyParentDocumentId(documentInfo.documentCollection[0].parentId);
          }
        }

        let startIndex = (page_Index - 1) * param.pageSize + 1;
        let endIndex = (page_Index - 1) * param.pageSize + (result.data.data.documentCollection ? result.data.data.documentCollection.length : 0);

        setPageStartIndex(startIndex);
        setPageEndIndex(endIndex);
        setTotalCount(dataCount);
        setHasEditAccess(hasEditAccess);
        handleCloseAdvanceSearch(false);
      } else {
        console.log("Error");
      }

      setLoading(false);
    } catch (e: any) {
      // Handle errors
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === "string" ? (e as string) : e?.message as string,
        alertType: AlertType.Error,
      });
    }
  }
  async function fetchDocumentBreadcrumbList() {
    try {
      //new http for API
      setLoading(true);
      let params = new URLSearchParams();
      params.append("documentId", parentDocumentId.toString());
      params.append("userId", userContext.userId.toString());
      const result: any = await GetDocumentBreadcrumbList(params);
      if (result && result.data.data) {
        let documentInfo = result.data.data.documentCollection;
        setBreadcrumbs(documentInfo)
      } else {
        console.log("Error");
      }
      setLoading(false);
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }
  }
  const handleAddDocument = (e: any) => {
    let encDocumentId: any = edService.encrypt(0);
    e.preventDefault();
    navigate({
      pathname: "/home/home-save",
      search: `?q=${encodeURIComponent(encDocumentId)}`,
    }, {
      state: {
        parenetId: parentDocumentId
      },
    });
  };
  async function handleEditDocument(documentId: any) {
    try {
      let encDocumentId: any = edService.encrypt(documentId);
      navigate({
        pathname: "/home/home-save",
        search: `?q=${encodeURIComponent(encDocumentId)}`,
      }, {
        state: { title: " Edit Document", button: "Update", documentId: documentId, isAddPopUp: "false" },
      });
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }
  }
  async function handleDeleteDocument(documentId: any) {
    try {
      let DeleteData = {
        ids: documentId.toString(),
        isDeleted: true,
        dataManagedBy: userContext.userId,
      };
      const result: any = await DeleteDocument(DeleteData);
      if (result) {
        fetchDocumentList(null);
        setAnchorEl3(null);
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: result.message,
          alertType: AlertType.Success,
        });
      } else {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: result.message,
          alertType: AlertType.Error,
        });
      }
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }
    setopenDeleteModal(false);
  }
  async function handleDeleteAccess(documentId: any, groupId: any) {
    try {
      let DeleteData = {
        documentId: documentId.toString(),
        groupId: groupId.toString(),
        isDeleted: true,
        dataManagedBy: userContext.userId,
      };
      const result: any = await RemoveAccess(DeleteData);
      if (result) {
        fetchGroupList();
        setopenAccessModal(false);
        setAnchorEl3(null);
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: result.message,
          alertType: AlertType.Success,
        });
      } else {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: result.message,
          alertType: AlertType.Error,
        });
      }
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }
    setopenDeleteModal(false);
  }


  async function handleUpdateAccess(groupId: any, accessType: string) {
    try {
      let lUpdateData = {
        documentId: accessDocumentId.toString(),
        groupId: accessGroupId.toString(),
        isDeleted: false,
        accessType: accessType.toString().toUpperCase(),
        dataManagedBy: userContext.userId,
      };

      const result: any = await UpdateAccess(lUpdateData);
      if (result) {
        fetchGroupList();
        setAnchorElMenu(null);
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: result.message,
          alertType: AlertType.Success,
        });
      } else {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: result.message,
          alertType: AlertType.Error,
        });
      }
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }
    setAnchorElMenu(null);
  }

  //handlePerentClick
  const handlePerentClick = (documentId: any) => {
    setParentDocumentId(documentId);
    const url = `/home?d=${encodeURIComponent(documentId)}`;
    // Navigate to the new URL
    navigate(url);
  }
  //Useeffect
  useEffect(() => {
    fetchDocumentList(null);
    fetchDocumentBreadcrumbList();
    setSelected([]);
    //fetchGroupList();
    //fetchTagList();
    let e: any = document.getElementById("root");
    e.scrollIntoView({
      block: "start",
      behavior: "smooth",
      inline: "start",
    });
  }, [param, isOpenImport, refreshData, parentDocumentId]);
  useEffect(() => {
    fetchTagList();
  }, []);

  useEffect(() => {
    fetchGroupList();
  }, [OpenAccess, parentDocumentId]);

  return (
    <Formik
      initialValues={folderData}
      validationSchema={validateSchema}
      enableReinitialize
      initialTouched={
        {
          documentName: true,
        }
      }
      onSubmit={(values: any, { resetForm }) => {
        SubmitDocument(values, resetForm);
      }}
    >
      {(props: any) => {
        const avatarStyle = { backgroundColor: "#1bbd7e" };
        return (
          <>
            {isLoading === true ?
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: "100px" }}>
                <CircularProgress />
              </Box> :
              <Box className='main-container' sx={{ paddingTop: '35px' }}>
                <Grid container className="list-container" sx={{ padding: '16px', paddingTop: '25px' }}>
                  <Grid item xs={12} container className='page-header' sx={{ paddingTop: 0 }}>
                    <Grid xs={12} sm={12} item className="page-breadcrumbs">
                      <CustomBreadcrumbsHome
                        breadcrumbs={breadcrumbs}
                        setParentDocumentId={setParentDocumentId}
                        parentDocumentId={parentDocumentId}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6} md={5} lg={5} className='search-bar' sx={{ gap: 2 }}>
                    <CustomTextField
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          searchValue(value);
                        }
                      }}
                      placeholder="Search..."
                      type="text"
                      variant="outlined"
                      size="small"
                      fullWidth
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton onClick={() => searchValue(value)}>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <>
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="clear search"
                                onClick={(e) => {
                                  handleClickOpenAdvanceSearch();
                                }}
                              >
                                <ManageSearchIcon />
                              </IconButton>
                            </InputAdornment>
                            {value && (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="clear search"
                                  onClick={() => CloseSearch()}
                                >
                                  <ClearIcon />
                                </IconButton>
                              </InputAdornment>
                            )}
                          </>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={7} lg={7} mt={matches && 2} className='page-header-icon' sx={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      sx={{ marginRight: 2 }}

                      className="button-border-remove"
                      color="primary"
                      variant="contained"
                      onClick={handleAddDocument}
                      size="medium"
                      startIcon={<InsertDriveFileIcon />}
                      disabled={!hasEditAccess}
                    >
                      Add File
                    </Button>
                    <Button
                      className="button-border-remove"
                      color="primary"
                      variant="contained"
                      onClick={(e) => {
                        handleClickOpen(0);
                      }}
                      size="medium"
                      startIcon={<FolderIcon />}
                      disabled={!hasEditAccess}
                    >
                      Add Folder
                    </Button>
                  </Grid>
                  <TableContainer className='table'>
                    <Table
                      aria-labelledby="tableTitle"
                      size={dense ? "small" : "medium"}>
                      <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        rowCount={resultData.documentCollection.length}
                      />
                      <TableBody>
                        {stableSort(
                          resultData.documentCollection,
                          getComparator(order, orderBy)
                        )
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((item: any, index: any) => {
                            const isItemSelected = isSelected(item.documentId);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                              <>
                                {/* Mobile Screen List */}
                                {matches && (
                                  <>
                                    {/* Icon File */}
                                    <TableRow key={index + 1000}>
                                      <TableCell
                                        onClick={() => {
                                          if (item.documentTypeCode === 'folder') {
                                            handlePerentClick(item.documentId)
                                          }
                                        }}
                                        sx={{ border: 'none !important' }}
                                      >
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                          <Tooltip title={item?.fileType} placement="top">
                                            {item.documentTypeCode === 'folder' ? (
                                              <FolderIcon style={{ color: '#F8D775' }} />
                                            ) : item.documentTypeCode === 'file' && item.fileType === 'PDF' ? (
                                              <PictureAsPdfIcon style={{ color: '#D32F2F' }} />
                                            ) : item.documentTypeCode === 'file' && item.fileType === 'DOC' ? (
                                              <DescriptionIcon style={{ color: '#2196F3' }} />
                                            ) : item.documentTypeCode === 'file' && item.fileType === 'DOCX' ? (
                                              <DescriptionIcon style={{ color: '#2196F3' }} />
                                            ) : item.documentTypeCode === 'file' && item.fileType === 'XLS' ? (
                                              <GridOnIcon style={{ color: '#388E3C' }} />
                                            ) : item.documentTypeCode === 'file' && item.fileType === 'XLSX' ? (
                                              <GridOnIcon style={{ color: '#388E3C' }} />
                                            ) : item.documentTypeCode === 'file' && item.fileType === 'XML' ? (
                                              <GridOnIcon style={{ color: '#388E3C' }} />
                                            ) : item.documentTypeCode === 'file' && item.fileType === 'PPTX' ? (
                                              <SlideshowIcon style={{ color: '#FF9800' }} />
                                            ) : item.documentTypeCode === 'file' && item.fileType === 'PPT' ? (
                                              <SlideshowIcon style={{ color: '#FF9800' }} />
                                            ) : item.documentTypeCode === 'file' && item.fileType === 'JSON' ? (
                                              <CodeIcon style={{ color: '#F57C00' }} />
                                            ) : item.documentTypeCode === 'file' && item.fileType === 'CSV' ? (
                                              <ListAltIcon style={{ color: '#FFB300' }} />
                                            ) : item.documentTypeCode === 'file' && (item.fileType === 'JPEG' || item.fileType === 'PNG' || item.fileType === 'JPG' || item.fileType === 'GIF') ? (
                                              <ImageIcon style={{ color: '#FF7043' }} />
                                            ) : item.documentTypeCode === 'file' && item.fileType === 'TXT' ? (
                                              <TextSnippetIcon style={{ color: '#616161' }} />
                                            ) : item.documentTypeCode === 'file' && item.fileType === 'ZIP' ? (  // Added ZIP file check
                                              <FolderZipIcon style={{ color: '#8E24AA' }} />  // Use an appropriate icon for ZIP files
                                            ) : (
                                              <InsertDriveFileIcon />
                                            )}
                                          </Tooltip>

                                          <Button
                                            disableRipple
                                            color="inherit"
                                            onClick={() => {
                                              if (item.documentTypeCode === 'folder') {
                                                handlePerentClick(item.documentId)
                                              }
                                            }}
                                            className={item.documentTypeCode === 'folder' ? "manuButton-link" : ""}
                                            style={{ backgroundColor: "transparent", textTransform: 'none', textAlign: 'left' }}
                                          >
                                            <span style={{ marginLeft: 8, fontWeight: "550" }}
                                            >
                                              {item.documentName}
                                            </span>
                                          </Button>
                                        </div>
                                      </TableCell>
                                    </TableRow>

                                    {/* Modified Date */}
                                    {item.fileSize && (
                                      <TableRow key={index + 1001}>
                                        <TableCell sx={{ textAlign: "left", border: 'none !important' }}>
                                          {item.documentTypeCode === 'file' ?
                                            ((item.fileSize) === null ? "-" :
                                              'File size: ' + getFormattedFileSize(item.fileSize))
                                            : "-"
                                          }
                                        </TableCell>
                                      </TableRow>
                                    )}

                                    <TableRow key={index + 1002}>
                                      <TableCell sx={{ textAlign: "left", border: 'none !important' }}>
                                        {item.modifiedDate
                                          ? `Modified Date: ${format(new Date(item.modifiedDate), 'MMM dd, yyyy hh:mm a')}`
                                          : `Created Date: ${format(new Date(item.createdDate), 'MMM dd, yyyy hh:mm a')}`}
                                      </TableCell>
                                    </TableRow>

                                    {/* registrationNumber */}
                                    {item.registrationNumber && (
                                      <TableRow key={index + 1003}>
                                        <TableCell sx={{ textAlign: "left", border: 'none !important' }}>
                                          {`Reg No: ${item.registrationNumber}`}
                                        </TableCell>
                                      </TableRow>
                                    )}

                                    {/* dateOfLetter */}
                                    {item.dateOfLetter && (
                                      <TableRow key={index + 1004}>
                                        <TableCell sx={{ textAlign: "left", border: 'none !important' }}>
                                          {`Date of Letter: ${format(new Date(item.dateOfLetter), 'MMM dd, yyyy')}`}
                                        </TableCell>
                                      </TableRow>
                                    )}

                                    {/* tagName */}
                                    {item.tagName && (
                                      <TableRow key={index + 1005}>
                                        <TableCell sx={{ textAlign: "left", border: 'none !important' }}>
                                          {`Tag Name: ${item.tagName}`}
                                        </TableCell>
                                      </TableRow>
                                    )}
                                    {/* subject */}
                                    {item.subject && (
                                      <TableRow key={index + 1006}>
                                        <TableCell sx={{ textAlign: "left", border: 'none !important' }}>
                                          {`Subject: ${item.subject}`}
                                        </TableCell>
                                      </TableRow>
                                    )}

                                    {/* Created By / Me */}
                                    <TableRow key={index + 1007}>
                                      <TableCell sx={{ textAlign: "left", border: 'none !important' }}>
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                          <Avatar {...stringAvatar(item.fullUserName)} />
                                          <Typography sx={{ marginLeft: 1 }}>
                                            {item.createdBy === userContext.userId ? 'Me' : item.fullUserName}
                                          </Typography>
                                        </Box>
                                      </TableCell>
                                    </TableRow>
                                  </>
                                )}

                                {/* Laptop Screen List */}
                                <TableRow key={index + 1008}>
                                  <TableCell
                                    sx={{
                                      display: {
                                        xs: "none",
                                        sm: "table-cell",
                                        md: "table-cell",
                                        lg: "table-cell",
                                      }
                                    }}
                                    onClick={() => {
                                      if (item.documentTypeCode === 'folder') {
                                        handlePerentClick(item.documentId)
                                      }
                                    }
                                    }>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                      <Tooltip title={item?.fileType} placement="top">
                                        {item.documentTypeCode === 'folder' ? (
                                          <FolderIcon style={{ color: '#F8D775' }} />
                                        ) : item.documentTypeCode === 'file' && item.fileType === 'PDF' ? (
                                          <PictureAsPdfIcon style={{ color: '#D32F2F' }} />
                                        ) : item.documentTypeCode === 'file' && item.fileType === 'DOC' ? (
                                          <DescriptionIcon style={{ color: '#2196F3' }} />
                                        ) : item.documentTypeCode === 'file' && item.fileType === 'DOCX' ? (
                                          <DescriptionIcon style={{ color: '#2196F3' }} />
                                        ) : item.documentTypeCode === 'file' && item.fileType === 'XLS' ? (
                                          <GridOnIcon style={{ color: '#388E3C' }} />
                                        ) : item.documentTypeCode === 'file' && item.fileType === 'XLSX' ? (
                                          <GridOnIcon style={{ color: '#388E3C' }} />
                                        ) : item.documentTypeCode === 'file' && item.fileType === 'XML' ? (
                                          <GridOnIcon style={{ color: '#388E3C' }} />
                                        ) : item.documentTypeCode === 'file' && item.fileType === 'PPTX' ? (
                                          <SlideshowIcon style={{ color: '#FF9800' }} />
                                        ) : item.documentTypeCode === 'file' && item.fileType === 'PPT' ? (
                                          <SlideshowIcon style={{ color: '#FF9800' }} />
                                        ) : item.documentTypeCode === 'file' && item.fileType === 'JSON' ? (
                                          <CodeIcon style={{ color: '#F57C00' }} />
                                        ) : item.documentTypeCode === 'file' && item.fileType === 'CSV' ? (
                                          <ListAltIcon style={{ color: '#FFB300' }} />
                                        ) : item.documentTypeCode === 'file' && item.fileType === 'JPEG' ? (
                                          <ImageIcon style={{ color: '#FF7043' }} />
                                        ) : item.documentTypeCode === 'file' && item.fileType === 'PNG' ? (
                                          <ImageIcon style={{ color: '#FF7043' }} />
                                        ) : item.documentTypeCode === 'file' && item.fileType === 'JPG' ? (
                                          <ImageIcon style={{ color: '#FF7043' }} />
                                        ) : item.documentTypeCode === 'file' && item.fileType === 'GIF' ? (
                                          <ImageIcon style={{ color: '#FF7043' }} />
                                        ) : item.documentTypeCode === 'file' && item.fileType === 'TXT' ? (
                                          <TextSnippetIcon style={{ color: '#616161' }} />
                                        ) : item.documentTypeCode === 'file' && item.fileType === 'ZIP' ? (  // Added ZIP file check
                                          <FolderZipIcon style={{ color: '#ac8445' }} />  // Use an appropriate icon for ZIP files
                                        ) : (
                                          <InsertDriveFileIcon />
                                        )}
                                      </Tooltip>
                                      <Button
                                        disableRipple
                                        color="inherit"
                                        onClick={() => {
                                          if (item.documentTypeCode === 'folder') {
                                            handlePerentClick(item.documentId)
                                          }
                                        }}
                                        className={item.documentTypeCode === 'folder' ? "manuButton-link" : ""}
                                        style={{ backgroundColor: "transparent", textTransform: 'none', textAlign: 'left' }}
                                      >
                                        <span style={{ marginLeft: 8, fontWeight: "550" }}
                                        >
                                          {item.documentName}
                                        </span>
                                      </Button>
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      display: {
                                        xs: "none",
                                        sm: "table-cell",
                                        md: "table-cell",
                                        lg: "table-cell",
                                      },
                                      textAlign: "left",
                                      verticalAlign: "middle", // Aligns content vertically in the middle
                                    }}
                                  >
                                    {item.registrationNumber}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      display: {
                                        xs: "none",
                                        sm: "table-cell",
                                        md: "table-cell",
                                        lg: "table-cell",
                                      },
                                      textAlign: "center",
                                      verticalAlign: "middle", // Aligns content vertically in the middle
                                    }}
                                  >
                                    {item.dateOfLetter ?
                                      format(new Date(item.dateOfLetter), 'MMM dd, yyyy')
                                      : null
                                    }
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      display: {
                                        xs: "none",
                                        sm: "table-cell",
                                        md: "table-cell",
                                        lg: "table-cell",
                                      },
                                      textAlign: "left",
                                      verticalAlign: "middle", // Aligns content vertically in the middle
                                    }}
                                  >
                                    {item.tagName}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      display: {
                                        xs: "none",
                                        sm: "table-cell",
                                        md: "table-cell",
                                        lg: "table-cell",
                                      },
                                      textAlign: "left",
                                      verticalAlign: "middle", // Aligns content vertically in the middle
                                    }}
                                  >
                                    {item.subject}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      display: {
                                        xs: "none",
                                        sm: "table-cell",
                                        md: "table-cell",
                                        lg: "table-cell",
                                      },
                                      textAlign: "left",
                                      verticalAlign: "middle", // Aligns content vertically in the middle
                                    }}
                                  >
                                    {item.createdBy === userContext.userId ? (
                                      <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Avatar {...stringAvatar(item.fullUserName)} />
                                        <Typography sx={{ marginLeft: 1 }}>Me</Typography>
                                      </Box>
                                    ) : (
                                      <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Avatar {...stringAvatar(item.fullUserName)} />
                                        <Typography sx={{ marginLeft: 1 }}>{item.fullUserName}</Typography>
                                      </Box>
                                    )}
                                  </TableCell>

                                  <TableCell
                                    sx={{
                                      display: {
                                        xs: "none",
                                        sm: "table-cell",
                                        md: "table-cell",
                                        lg: "table-cell",
                                      },
                                      textAlign: "left"
                                    }}
                                  >
                                    {item.documentTypeCode === 'file' ?
                                      ((item.fileSize) === null ? "-" :
                                        getFormattedFileSize(item.fileSize))
                                      : "-"
                                    }
                                  </TableCell>
                                  <Tooltip title={item.modifiedDate
                                    ? `Modified • ${format(new Date(item.modifiedDate), 'MMM dd, yyyy hh:mm a')}`
                                    : `Created • ${format(new Date(item.createdDate), 'MMM dd, yyyy hh:mm a')}`
                                  } placement="top">
                                    <TableCell
                                      sx={{
                                        display: {
                                          xs: "none",
                                          sm: "table-cell",
                                          md: "table-cell",
                                          lg: "table-cell",
                                        },
                                        textAlign: "center"
                                      }}
                                    //sx={{ textAlign: "center" }}
                                    >
                                      {item.modifiedDate
                                        ? format(new Date(item.modifiedDate), 'MMM dd, yyyy hh:mm a')
                                        : format(new Date(item.createdDate), 'MMM dd, yyyy hh:mm a')
                                      }
                                    </TableCell>
                                  </Tooltip>
                                  <TableCell align={(mobileScreen) ? 'left' : "right"}>
                                    {item.documentTypeCode === 'file' ?
                                      <>
                                        {item.fileType && ['PNG', 'JPG', 'JPEG', 'GIF', 'BMP', 'TIFF'].includes(item.fileType.toUpperCase()) ? (
                                          <Tooltip title="View" placement="top">
                                            <IconButton
                                              className='download-container'
                                              onClick={() => handleClickView(item.documentId)}
                                            >
                                              <RemoveRedEyeIcon />
                                            </IconButton>
                                          </Tooltip>
                                        ) : null}
                                        <Tooltip title="Download" placement="top">
                                          <IconButton
                                            className='download-container'
                                            onClick={() => handleClickDownload(item.documentId)}
                                          >
                                            <DownloadIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </>
                                      : null
                                    }

                                    {item.accessType === 'EDITOR' &&
                                      <>
                                        <Tooltip title="Share access" placement="top">
                                          <IconButton
                                            className='download-container'
                                            onClick={(e) => {
                                              handleClickOpenAccess(item.documentName, item.documentId);
                                            }}
                                          >
                                            <PersonAddRoundedIcon />
                                          </IconButton>
                                        </Tooltip>
                                        {item.documentTypeCode === 'file' ?
                                          <Tooltip title="Edit" placement="top">
                                            <IconButton className='edit-container'
                                              onClick={() => { handleEditDocument(item.documentId); }}
                                            >
                                              <EditIcon />
                                            </IconButton>
                                          </Tooltip>
                                          :
                                          <Tooltip title="Rename" placement="top">
                                            <IconButton className='edit-container'
                                              onClick={() => { handleClickOpen(item.documentId); }}
                                            >
                                              <DriveFileRenameOutlineRoundedIcon />
                                            </IconButton>
                                          </Tooltip>
                                        }


                                        <Tooltip title="Delete" placement="top">
                                          <IconButton className='delete-container'
                                            onClick={(e: any) =>
                                              handleOpenDeleteDocumentModal(e, item.documentId)
                                            }
                                          // onClick={handleOpenDeleteDocumentModal}
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </>
                                    }
                                  </TableCell>
                                </TableRow>
                              </>
                            )
                          })}
                        {resultData.documentCollection.length <= 0 ? (
                          <TableRow hover tabIndex={-1} >
                            <TableCell colSpan={12} align="center">
                              No record(s) found
                            </TableCell>
                          </TableRow>
                        ) : (
                          <></>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {resultData.documentCollection.length > 0 && (
                    <Grid
                      container
                      paddingTop={1}
                      alignItems={"center"}
                      justifyContent={"center"}
                      className='pagination'
                    >
                      <Grid item xs={12} sm={6}
                        className="pagination-label"
                      >
                        {pageStartIndex} - {pageEndIndex} of {totalCount}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Stack
                          component="div"
                          alignItems={mobileScreen ? " center" : "end"}
                          justifyContent={"center"}
                        >
                          <Pagination
                            color="primary"
                            count={counData}
                            page={page_Index}
                            onChange={handleChangePageNew}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Box>
            }
            {/* For View File */}
            {previewDoc?.length > 0 &&
              <Dialog
                open={previewDocDialog}
                onClose={handleCloseFilePreviewDialogBox}
                scroll={"paper"}
                sx={{ '& .MuiPaper-root': { maxWidth: "revert", width: 1000 } }}
                maxWidth='md'
                fullScreen={fullScreen}
              >
                <DialogTitle className="pdf-viewr-simple">
                  <Grid sx={{ display: 'flew', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h4>
                      <b> {previewDoc[0]?.name}</b>
                    </h4>
                    <Tooltip title={"Close"} placement="top">
                      <IconButton
                        aria-label="close"
                        onClick={handleCloseFilePreviewDialogBox}
                        style={{ marginTop: "-10px !important" }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </DialogTitle>
                <DialogContent sx={{ marginTop: '5px', padding: (previewDoc?.length > 0 && previewDoc[0]?.type === 'application/pdf') ? '0px !important' : '' }} dividers>
                  <Box>
                    {/* {previewDoc?.length > 0 && ['application/vnd.ms-excel', 'application/pdf'].includes(previewDoc[0]?.type) &&
                                <SimpelPdfViwer
                                    title={previewDoc[0]?.name}
                                    source={previewDoc[0]}
                                />
                            } */}
                    {previewDoc?.length > 0 && ['image/jpeg', 'image/png'].includes(previewDoc[0]?.type) &&
                      <img src={previewDoc[0]?.preview} style={img} alt="Img" />}
                  </Box>
                </DialogContent>
              </Dialog>
            }
            {/* for Create Folder */}
            <Dialog
              sx={{
                "& .MuiDialog-paper": { width: "80%", maxHeight: 535, borderRadius: 0 },
                borderRadius: 0
              }}
              maxWidth="xs"
              open={Open}
              onClose={(event, reason) => {
                if (reason && reason === "backdropClick") {
                  return;
                } else {
                  handleClose(false);
                }
              }}
              className="invite-modal">
              <Box className="invite-member-main-box"
              >
                <Grid container sx={{ padding: !matches ? 5 : 2 }}>
                  <Grid item container xs={12} sx={{ padding: "0px !important" }}>
                    <Grid item xs={9} sx={{ fontSize: "20px", fontWeight: "bold", padding: "0px !important" }}>
                      {props.values?.documentId <= 0 ? "Create Folder" : "Rename Folder"}
                    </Grid>
                    <Grid item xs={3} sx={{ display: "flex", justifyContent: "end" }}>
                      <Tooltip title={"Close"} placement="top">
                        <IconButton aria-label="close" onClick={handleClose}>
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <DialogContent className="email-form-content">
                    <Form onSubmit={(e) => {
                      e.preventDefault();
                      props.handleSubmit();
                    }}>
                      <Grid item xs={12} sx={{ paddingTop: 2 }}>
                        <FormControl fullWidth>
                          <TextField
                            id="documentName"
                            label="Folder Name*"
                            inputProps={{ maxLength: 100 }}
                            size="small"
                            fullWidth
                            margin="dense"
                            value={props.values?.documentName}
                            onChange={(e) => {
                              props.setFieldValue(
                                `documentName`,
                                e.target.value !== null ? e.target.value : null,
                                true
                              );
                            }}
                            onBlur={props.handleBlur}
                            error={
                              props.errors.documentName && props.touched.documentName
                                ? true
                                : false
                            }
                          />
                          {props.errors.documentName && props.touched.documentName && (
                            <Typography
                              className={
                                props.errors.documentName && props.touched.documentName
                                  ? "validation-message text-input error"
                                  : "validation-message text-input"
                              }>
                              {props.errors.documentName}
                            </Typography>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sx={{ paddingTop: 2 }}
                        textAlign="right"
                        className={!matches ? "button-main-box" : ""}>
                        <LoadingButton
                          className="button-border-remove"
                          color="primary"
                          //loading={sendEmail}
                          loadingPosition="start"
                          fullWidth
                          startIcon={<></>}
                          variant="contained"
                          type="submit">
                          {props.values?.documentId <= 0 ? "Create" : "Update"}
                        </LoadingButton>
                      </Grid>
                    </Form>
                  </DialogContent>
                </Grid>
              </Box>
            </Dialog >
            {/* for Access */}
            <Dialog
              sx={{
                "& .MuiDialog-paper": { borderRadius: 0, maxWidth: mobileScreen ? "auto" : "500px" },
                borderRadius: 0,
                //maxWidth: "500px"
              }}
              maxWidth="xs"
              open={OpenAccess}
              fullScreen={mobileScreen}
              onClose={(event, reason) => {
                if (reason && reason === "backdropClick") {
                  return;
                } else {
                  handleCloseAccess(false);
                }
              }}
              className="invite-modal"
            //sx={{ maxWidth: "500px" }}
            >
              <Box className="invite-member-main-box"
              //sx={{maxWidth:"500px"}}
              >
                <Grid container sx={{ padding: !matches ? 2 : 2 }}>
                  <Grid item container xs={12}>
                    <Grid item xs={9} sx={{ fontSize: "20px", fontWeight: "bold", padding: "0px !important" }}>
                      Share ‘{DocumentName}’
                    </Grid>
                    <Grid item xs={3} sx={{ display: "flex", justifyContent: "end" }}>
                      <Tooltip title={"Close"} placement="top">
                        <IconButton aria-label="close" onClick={handleCloseAccess}>
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <DialogContent className="email-form-content" sx={{ paddingTop: "20px !important" }}>
                    <Form>
                      {GroupAccessCollection && GroupAccessCollection.length > 0 ? (
                        <>
                          <Typography sx={{ paddingBottom: 2, fontWeight: 'bold' }}>Group with access</Typography>
                          {GroupAccessCollection.map((groupAccess: any, index) => (
                            <Grid key={index.toString()} item container classes='share-access'>
                              <Grid item xs={9} sm={9} >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "left",
                                    marginBottom: 1,
                                    padding: 1,
                                  }}
                                >
                                  <Avatar {...stringAvatar(groupAccess.name)} />
                                  <Typography sx={{ marginLeft: 1, fontWeight: '500', fontSize: '1rem' }}>{groupAccess.name}</Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={3} sm={3} sx={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                <Box>
                                  <Button
                                    id="basic-button"
                                    aria-controls={openMenu ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openMenu ? 'true' : undefined}
                                    onClick={(e: any) => handleMenuClick(e, groupAccess.userGroupId, groupAccess.accessType)}
                                    endIcon={<ArrowDropDownRoundedIcon />}
                                  >
                                    {groupAccess.accessType}
                                  </Button>
                                  <Menu
                                    anchorEl={anchorElMenu}
                                    open={openMenu}
                                    onClose={handleMenuClose}
                                    MenuListProps={{
                                      'aria-labelledby': 'basic-button',
                                    }}
                                    //className='access-menu'
                                  >
                                    <MenuItem onClick={() => handleUpdateAccess(groupAccess.userGroupId, 'EDITOR')}>
                                      <ListItemIcon>
                                        {accessTypeGroupShare === 'EDITOR' ? <CheckRoundedIcon fontSize="small" style={{ color: 'blue' }} /> : <></>}
                                      </ListItemIcon>
                                      <ListItemText>Editor</ListItemText>
                                    </MenuItem>
                                    <MenuItem onClick={() => handleUpdateAccess(groupAccess.userGroupId, 'VIEWER')}>
                                      <ListItemIcon>
                                        {accessTypeGroupShare === 'VIEWER' ? <CheckRoundedIcon fontSize="small" style={{ color: 'blue' }} /> : <></>}
                                      </ListItemIcon>
                                      <ListItemText>Viewer</ListItemText></MenuItem>
                                    <Divider />
                                    <MenuItem onClick={(e: any) =>
                                      handleOpenDeleteAccessModal(e, groupAccess.userGroupId)
                                    }><ListItemIcon>
                                        <DeleteIcon fontSize="small" />
                                      </ListItemIcon>
                                      <ListItemText >Remove</ListItemText></MenuItem>
                                  </Menu>
                                </Box>

                              </Grid>
                            </Grid>
                          ))}
                        </>
                      ) : null}
                      {GroupCollection && GroupCollection.length > 0 ? (
                        <>
                          <Typography sx={{ paddingTop: 1, paddingBottom: 2, fontWeight: 'bold' }}>
                            Share access
                          </Typography>
                          <Grid item container className='share-access'>
                            <Grid item xs={8} sm={8}>
                              <FormControl fullWidth style={{ margin: 0, padding: 0 }}>
                                <MultiSelect
                                  id={"userGroupId"}
                                  FieldName={"userGroupId"}
                                  menuPortalTarget={document.querySelector("body")}
                                  placeholder="Group *"
                                  onCreateOption={false}
                                  openMenuOnFocus
                                  isClearable={false}
                                  options={GroupCollection}
                                  handleChange={(selectedOptions) => {
                                    const group = selectedOptions.map((option) => ({
                                      document_id: accessDocumentId,
                                      access_type: accessTypeShare.toString(),
                                      user_group_id: option.value,
                                      group_name: option.label,
                                      is_deleted: false,
                                    }));
                                    props.setFieldValue("userGroupId", group, true);
                                    console.log("accessTypeShare", accessTypeShare);
                                  }}
                                  Value={GroupCollection.filter((option) =>
                                    Array.isArray(props.values.userGroupId) &&
                                    props.values.userGroupId.map(x => x.user_group_id)?.includes(option.value)
                                  )}
                                />
                                {props.errors.userGroupId && props.touched.userGroupId && (
                                  <Typography
                                    variant="inherit"
                                    className={
                                      props.errors.userGroupId && props.touched.userGroupId
                                        ? "validation-message text-input error"
                                        : "validation-message text-input"
                                    }
                                  >
                                    {props.errors.userGroupId}
                                  </Typography>
                                )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={1} sm={1}></Grid>
                            <Grid item xs={3} sm={3}
                              sx={{ display: "flex", justifyContent: "center", alignItems: "center", border: "1px solid #cccccc", borderRadius: "5px" }}
                            >
                              <Box>
                                <Button
                                  id="basic-button"
                                  aria-controls={openMenu ? 'basic-menu' : undefined}
                                  aria-haspopup="true"
                                  aria-expanded={openMenu ? 'true' : undefined}
                                  onClick={handleMenuGroupClick}
                                  endIcon={<ArrowDropDownRoundedIcon />}
                                  disableRipple
                                  sx={{
                                    backgroundColor: 'transparent',
                                    "&:hover": { backgroundColor: 'transparent' },
                                    "&:active": { backgroundColor: 'transparent' },
                                    "&:focus": { backgroundColor: 'transparent' }
                                  }}
                                >
                                  {accessTypeShare}
                                </Button>
                                <Menu
                                  anchorEl={anchorElMenuGroup}
                                  open={openMenuGroup}
                                  onClose={handleMenuGroupClose}
                                  MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                                  className='access-menu'
                                >
                                  <MenuItem onClick={() => handleShareAccessType(props, 'EDITOR')}>
                                    <ListItemIcon>
                                      {accessTypeShare === 'EDITOR' ? <CheckRoundedIcon fontSize="small" style={{ color: 'blue' }} /> : <></>}
                                    </ListItemIcon>
                                    <ListItemText>Editor</ListItemText>
                                  </MenuItem>
                                  <MenuItem onClick={() => handleShareAccessType(props, 'VIEWER')}>
                                    <ListItemIcon>
                                      {accessTypeShare === 'VIEWER' ? <CheckRoundedIcon fontSize="small" style={{ color: 'blue' }} /> : <></>}
                                    </ListItemIcon>
                                    <ListItemText>Viewer</ListItemText>
                                  </MenuItem>
                                </Menu>
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sx={{ paddingTop: 3 }} textAlign="right" className={!matches ? "button-main-box" : ""}>
                            <LoadingButton
                              className="button-border-remove"
                              color="primary"
                              loading={isLoading}
                              loadingPosition="start"
                              fullWidth
                              startIcon={<></>}
                              variant="contained"
                              onClick={() => handleShareDocument(props.values)}
                            >
                              Submit
                            </LoadingButton>
                          </Grid>
                        </>
                      ) : null}
                    </Form>
                  </DialogContent>
                </Grid>
              </Box>
            </Dialog >
            {/* advance search */}
            <Dialog
              sx={{
                "& .MuiDialog-paper": { width: "80%", maxHeight: 600, maxWidth: 600, borderRadius: 0 },
                borderRadius: 0
              }}
              maxWidth="xs"
              open={OpenAdvanceSearch}
              onClose={(event, reason) => {
                if (reason && reason === "backdropClick") {
                  return;
                } else {
                  handleCloseAdvanceSearch(false);
                }
              }}
              className="invite-modal">
              <Box className="invite-member-main-box"
              >
                <Grid container sx={{ padding: !matches ? 5 : 2 }}>
                  <Grid item container xs={12}>
                    <Grid item xs={9} sx={{ fontSize: "20px", fontWeight: "bold", padding: "0px !important" }}>
                      Advance Search
                    </Grid>
                    <Grid item xs={3} sx={{ display: "flex", justifyContent: "end" }}>
                      <Tooltip title={"Close"} placement="top">
                        <IconButton aria-label="close" onClick={handleCloseAdvanceSearch}>
                          <CloseIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                  <DialogContent className="email-form-content" sx={{ paddingTop: "20px !important" }}>
                    <Form onSubmit={(e) => { e.preventDefault(); props.handleSubmit(); }} noValidate>
                      <Grid container item spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                          <FormControl fullWidth>
                            <TextField
                              id="documentNameSearch"
                              label="Doccument Name"
                              fullWidth
                              size="small"
                              inputProps={{ maxLength: 100 }}
                              onChange={(e) => {
                                props.handleChange(e);
                              }}
                              value={props.values.documentNameSearch}
                              onBlur={props.handleBlur}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <FormControl fullWidth>
                            <TextField
                              id="registrationNumberSearch"
                              label="Reg/Ref No"
                              fullWidth
                              size="small"
                              inputProps={{ maxLength: 100 }}
                              onChange={(e) => {
                                props.handleChange(e);
                              }}
                              value={props.values.registrationNumberSearch}
                              onBlur={props.handleBlur}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <FormControl sx={{ width: "100% !important" }}>
                            <DatePickerComponent
                              label="Start Date of Letter"
                              format="DD-MM-YYYY"
                              mode="date"
                              slotProps={{ textField: { size: "small", name: "startDateOfLetterSearch" } }}
                              value={props.values?.startDateOfLetterSearch
                                ? dayjs(props.values.startDateOfLetterSearch)
                                : null}
                              onChange={(date) => {
                                if (date && date.isValid()) {
                                  const formattedDate = date.format('YYYY-MM-DD');
                                  props.setFieldValue('startDateOfLetterSearch', formattedDate, true)
                                } else {
                                  props.setFieldValue('startDateOfLetterSearch', null, true)
                                }
                              }}
                              isClassName={undefined}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <FormControl sx={{ width: "100% !important" }}>
                            <DatePickerComponent
                              label="End Date of Letter"
                              format="DD-MM-YYYY"
                              mode="date"
                              slotProps={{ textField: { size: "small", name: "endDateOfLetterSearch" } }}
                              value={props.values?.endDateOfLetterSearch
                                ? dayjs(props.values.endDateOfLetterSearch)
                                : null}
                              onChange={(date) => {
                                if (date && date.isValid()) {
                                  const formattedDate = date.format('YYYY-MM-DD');
                                  props.setFieldValue('endDateOfLetterSearch', formattedDate, true)
                                } else {
                                  props.setFieldValue('endDateOfLetterSearch', null, true)
                                }
                              }}
                              isClassName={undefined}
                            />
                          </FormControl>
                          {/* {props.errors.endDateOfLetterSearch &&
                            props.touched.endDateOfLetterSearch && (
                              <Typography
                                className={
                                  "text-input error validation-message"
                                }
                              >
                                {props.errors.endDateOfLetterSearch}
                              </Typography>
                            )} */}
                        </Grid>
                        {/* <Grid item xs={12} sm={12} md={6}>
                          <FormControl sx={{ width: "100% !important" }}>
                            <DatePickerComponent
                              label="Start Registration Date"
                              format="DD-MM-YYYY"
                              mode="date"
                              slotProps={{ textField: { size: "small", name: "startRegistrationDateSearch" } }}
                              value={props.values?.startRegistrationDateSearch
                                ? dayjs(props.values.startRegistrationDateSearch)
                                : null}
                              onChange={(date) => {
                                if (date && date.isValid()) {
                                  const formattedDate = date.format('YYYY-MM-DD');
                                  props.setFieldValue('startRegistrationDateSearch', formattedDate, true)
                                } else {
                                  props.setFieldValue('startRegistrationDateSearch', null, true)
                                }
                              }}
                              isClassName={undefined}
                            />
                          </FormControl>

                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <FormControl sx={{ width: "100% !important" }}>
                            <DatePickerComponent
                              label="End Registration Date"
                              format="DD-MM-YYYY"
                              mode="date"
                              slotProps={{ textField: { size: "small", name: "endRegistrationDateSearch" } }}
                              value={props.values?.endRegistrationDateSearch
                                ? dayjs(props.values.endRegistrationDateSearch)
                                : null}
                              onChange={(date) => {
                                if (date && date.isValid()) {
                                  const formattedDate = date.format('YYYY-MM-DD');
                                  props.setFieldValue('endRegistrationDateSearch', formattedDate, true)
                                } else {
                                  props.setFieldValue('endRegistrationDateSearch', null, true)
                                }
                              }}
                              isClassName={undefined}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <FormControl sx={{ width: "100% !important" }}>
                            <DatePickerComponent
                              label="Start Expiration Date"
                              format="DD-MM-YYYY"
                              mode="date"
                              slotProps={{ textField: { size: "small", name: "startExpirationDateSearch" } }}
                              value={props.values?.startExpirationDateSearch
                                ? dayjs(props.values.startExpirationDateSearch)
                                : null}
                              onChange={(date) => {
                                if (date && date.isValid()) {
                                  const formattedDate = date.format('YYYY-MM-DD');
                                  props.setFieldValue('startExpirationDateSearch', formattedDate, true)
                                } else {
                                  props.setFieldValue('startExpirationDateSearch', null, true)
                                }
                              }}
                              isClassName={undefined}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                          <FormControl sx={{ width: "100% !important" }}>
                            <DatePickerComponent
                              label="End Expiration Date"
                              format="DD-MM-YYYY"
                              mode="date"
                              slotProps={{ textField: { size: "small", name: "endExpirationDateSearch" } }}
                              value={props.values?.endExpirationDateSearch
                                ? dayjs(props.values.endExpirationDateSearch)
                                : null}
                              onChange={(date) => {
                                if (date && date.isValid()) {
                                  const formattedDate = date.format('YYYY-MM-DD');
                                  props.setFieldValue('endExpirationDateSearch', formattedDate, true)
                                } else {
                                  props.setFieldValue('endExpirationDateSearch', null, true)
                                }
                              }}
                              isClassName={undefined}
                            />
                          </FormControl>
                        </Grid> */}
                        <Grid item xs={12} sm={12} md={12}>
                          <FormControl fullWidth>
                            <MultiSelect
                              id={"tagId"}
                              FieldName={"tagId"}
                              menuPortalTarget={document.querySelector("body")}
                              placeholder="Tag"
                              onCreateOption={false}
                              openMenuOnFocus
                              isClearable={false}
                              options={tagCollection}
                              handleChange={(selectedOptions) => {
                                const tags = selectedOptions.map((option) => ({
                                  documentId: (documentId),
                                  tag_id: option.tagIds, // set tag value
                                  tag_name: option.label, // set tag label
                                  is_deleted: false, // by default false
                                }));
                                props.setFieldValue("tagId", tags, true);
                              }}
                              Value={tagCollection.filter((option: any) =>
                                Array.isArray(props.values.tagId) &&
                                props.values.tagId.some((tag) => tag.tag_id === option.tagIds)
                              )}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                          <FormControl fullWidth>
                            <TextField
                              id="subjectSearch"
                              label="Subject / RE"
                              fullWidth
                              size="small"
                              inputProps={{ maxLength: 100 }}
                              onChange={(e) => {
                                props.handleChange(e);
                              }}
                              value={props.values.subjectSearch}
                              onBlur={props.handleBlur}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sx={{ paddingTop: 2 }}
                        textAlign="right"
                        className={!matches ? "button-main-box" : ""}>
                        <LoadingButton
                          className="button-border-remove"
                          color="primary"
                          loading={isLoading}
                          loadingPosition="start"
                          fullWidth
                          startIcon={<></>}
                          variant="contained"
                          onClick={() => fetchDocumentList(props.values)}
                        >
                          Apply
                        </LoadingButton>

                      </Grid>
                    </Form>
                  </DialogContent>
                </Grid>
              </Box>
            </Dialog >
            {/* Delete document modal popup - ends */}
            <Dialog
              open={openDeleteModal}
              onClose={handleCloseDeleteDocumentModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{ color: "black" }}>
                  Are you sure you want to delete?
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{
                display: "flex",
                justifyContent: "center"
              }}>
                <Button
                  onClick={() => handleDeleteDocument(documentId)}
                  sx={{
                    backgroundColor: "#d44d4a",
                    color: "white",
                    '&:hover': {
                      backgroundColor: "#e55b5f", // Change this to the desired hover color
                    },
                  }}
                >
                  Yes
                </Button>
                <Button
                  sx={{
                    backgroundColor: "#9e7b4f",
                    color: "white",
                    '&:hover': {
                      backgroundColor: "#b5935d", // Change this to the desired hover color
                    },
                  }}
                  onClick={handleCloseDeleteDocumentModal}
                  autoFocus
                >
                  No
                </Button>
              </DialogActions>
            </Dialog>
            {/* Delete access modal popup - ends */}
            <Dialog
              open={openAccessModal}
              onClose={handleCloseDeleteAccessModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{ color: "black" }}>
                  Are you sure you want to remove access?
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{
                display: "flex",
                justifyContent: "center"
              }}>
                <Button
                  onClick={() => handleDeleteAccess(accessDocumentId, groupId)}
                  sx={{
                    backgroundColor: "#d44d4a",
                    color: "white",
                    '&:hover': {
                      backgroundColor: "#e55b5f", // Change this to the desired hover color
                    },
                  }}
                >
                  Yes
                </Button>
                <Button
                  onClick={handleCloseDeleteAccessModal}
                  autoFocus
                >
                  No
                </Button>
              </DialogActions>
            </Dialog>
          </>
        );
      }}
    </Formik >
  );
}
