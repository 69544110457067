import { Box } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

type TLogo={
  className:string,
  size:number
}

export default function TiyaLogo( props: TLogo = { className: 'logo', size: 56 }) {

const navigate = useNavigate()

const  homeClick =()=>{
  navigate('/')
}

return (
    <>
      <Box className={props.className} onClick={homeClick}>
        <img src="/logo.png" style={{ height: `${props.size}px`, width: 'auto' }} alt="Tiya Logo"></img>
      </Box>
    </>
  )
}
