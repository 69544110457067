import React, { useContext, useEffect, useState } from 'react'
import {
  Box, Grid, IconButton, InputAdornment, TextField, Button, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Pagination, Stack, useMediaQuery, useTheme, Dialog, DialogActions, ListItemButton, Typography, Checkbox, List, DialogContent, DialogContentText,
  Fade
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import CustomBreadcrumbs from '../../../components/BreadcrumbsComponent/CustomBreadcrumbs';
import { EncryptDecryptService } from '../../../services/common/EncryptionService';
import { PAGE_SIZE } from '../../../services/common/const';
import { PreferencesContext } from '../../../PreferenceContext';
import { AlertType } from '../../../services/models/AlertType';
import { DeleteUserGroup, GetUserGroupList, GetUserGroupMemberList, TransferMembersToAnotherUserGroup } from '../../../services/UserGroupService';
import { Form, Formik } from "formik";
import * as Yup from "yup";
import AsyncSelect from "react-select/async";

const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: '#fff', // Set the desired background color
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.grey[300],
      borderWidth: '1px',
      BorderStyle: 'solid'
    },
    '&:hover fieldset': {
      borderColor: theme.palette.grey[500],
      borderWidth: '1px',
      BorderStyle: 'solid'
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: "2px",
    },
  },
}));

interface Data {
  id: number;
  code: string;
  Name: string;
  none: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc" | "";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
interface EnhancedTableProps {
  numSelected: number;
  //onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  //onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

//Table Header
function EnhancedTableHead(props: EnhancedTableProps) {
  return (
    <TableHead className='table-header-color'>
      <TableRow>
        <TableCell
          sx={{
            display: {
              xs: "table-cell",
              sm: "table-cell",
              md: "table-cell",
              lg: "table-cell",
            },
            fontWeight: "bold",
            width: "60%"
          }}
          align="left"
        >
          Name
        </TableCell>

        <TableCell
          sx={{
            display: {
              xs: "table-cell",
              sm: "table-cell",
              md: "table-cell",
              lg: "table-cell",
            },
            fontWeight: "bold",
            width: "40%"
          }}
          align="left"
        >
          {''}
        </TableCell>

      </TableRow>
    </TableHead>
  );
}

const UserGroup: any = {
  userGroupCollection: [],
};
const edService = new EncryptDecryptService();

const parameters: any = {
  pageIndex: 1,
  pageSize: PAGE_SIZE,
  sortColumn: "",
  sortDirection: "",
  searchValue: "",
};
interface IUpdatedGroup {
  label: '',
  userGroupId: number,
  value: number
}

// import ClearIcon from "@mui/icons-material/Clear";
export default function UserGroupList() {
  const updatedGroupColl: IUpdatedGroup = {
    label: '',
    userGroupId: 0,
    value: 0
  };
  interface SubmitDetail {
    oldUserGroupId: number;
    initUserGroupId: number;
    initUserGroupName: string;
    updatedGroupData: [IUpdatedGroup];
  }

  const formInitialValues: SubmitDetail = {
    oldUserGroupId: 0,
    initUserGroupId: 0,
    initUserGroupName: '',
    updatedGroupData: [updatedGroupColl]
  };

  const UserGroupdata = {
    title: "Add UserGroup",
    button: "Save",
    userGroupId: 0,
    isAddPopUp: false,
  };
  const navigate = useNavigate()
  const theme = useTheme()
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [anchorEl, setAnchorEl3] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const [state, setState] = useState(UserGroupdata);
  const [param, setParam] = useState(parameters);
  const [selected, setSelected] = useState<readonly number[]>(
    UserGroup.userGroupCollection);
  const [userGroupId, setUserGroupId] = useState(null);
  const [userGroupName, setUserGroupName] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [pageStartIndex, setPageStartIndex] = useState(1);
  const [pageEndIndex, setPageEndIndex] = useState(1);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [value, setValue] = useState("");
  const [openDeleteModal, setopenDeleteModal] = useState(false);
  const [order, setOrder] = useState<Order>("");
  const [orderBy, setOrderBy] = useState<keyof Data>("none");
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE);
  const [resultData, setResultData] = useState(UserGroup);
  const [page_Index, setPage_Index] = useState(1);
  const [counData, setCounData] = useState(0);
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  const [isLoading, setLoading] = useState(false);
  const [isAllowAdd, setAllowAdd] = useState(true);
  const [Curr_page, setCurrPage] = useState(1);
  const isSelected = (id: number) => selected.indexOf(id) !== -1;
  const [isOpenImport, setIsOpenImport] = useState(false);
  const [userGroupMemberList, setUserGroupMemberList] = useState([]);
  const [openListOfDeletePersonModal, setOpenListOfDeletePersonModal] = useState(false);
  const [submitData, setSubmitData] = useState(formInitialValues);
  const [selectAll, setSelectAll] = useState(false);
  const [checkedItems, setCheckedItems] = useState([...userGroupMemberList]);
  const [curDeleteData, setCurDeleteData] = useState(null);

  const validateSchema = Yup.object().shape({
    initUserGroupId: Yup.string().required('Please select a group.')
  });

  // for Edit & Delete pop 
  const handleOpenMore = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: any
  ) => {
    setAnchorEl3(event.currentTarget);
    setUserGroupId(id);
  };
  const handleCloseMore = () => {
    setAnchorEl3(null);
  };
  const handleChangePageNew = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    if (Curr_page !== value) {
      setCurrPage(value);
      setPage_Index(value);
      setParam({ ...param, pageIndex: value });
      fetchUserGroupList();
    }
  };
  // const handleCheckSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.checked) {
  //     const newSelecteds = resultData.unitCollection.map((n: any) => n.unitId);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  //Searching
  function searchValue(val: any) {
    if (val === "") {
      setValue("");
    }
    setPage_Index(1);
    setParam({ ...param, searchValue: val.toString(), pageIndex: 1 });
  }

  //Async Function 
  async function fetchUserGroupList() {
    try {
      //new http for API
      setLoading(true);
      let params = new URLSearchParams();
      params.append("pageIndex", page_Index.toString());
      params.append("pageSize", param.pageSize);
      params.append("sortColumn", param.sortColumn);
      params.append("sortDirection", param.sortDirection);
      params.append("searchValue", param.searchValue);
      const result: any = await GetUserGroupList(params);
      if (result && result.data.data) {
        let dataCount = result.data.data.totalCount;
        let userGroupInfo = result.data.data;
        let showDataCount = Math.ceil(dataCount / PAGE_SIZE);

        setResultData(userGroupInfo);
        setCounData(showDataCount);
        let startIndex = (page_Index - 1) * param.pageSize + 1;
        let endIndex =
          (page_Index - 1) * param.pageSize +
          (result.data.data.userGroupCollection
            ? result.data.data.userGroupCollection.length
            : 0);

        setPageStartIndex(startIndex);
        setPageEndIndex(endIndex);
        setTotalCount(dataCount);
      } else {
        console.log("Error");
      }
      setLoading(false);
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }
  }
  const handleAddUserGroup = (e: any) => {
    let encUserGroupId: any = edService.encrypt(0);
    e.preventDefault();
    navigate({
      pathname: "/groups/group-save",
      search: `?q=${encodeURIComponent(encUserGroupId)}`,
    }, {
      state,
    });
  };
  async function handleEditUserGroup(userGroupId: any) {
    try {
      let encUserGroupId: any = edService.encrypt(userGroupId);
      navigate({
        pathname: "/groups/group-save",
        search: `?q=${encodeURIComponent(encUserGroupId)}`,
      }, {
        state: { title: " Edit UserGroup", button: "Update", userGroupId: userGroupId, isAddPopUp: "false" },
      });
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }
  }
  async function handleDeleteUserGroup(userGroupId: any) {
    try {
      let DeleteData = {
        ids: userGroupId.toString(),
        isDeleted: true,
        dataManagedBy: userContext.userId,
      };
      const result: any = await DeleteUserGroup(DeleteData);
      if (result) {
        fetchUserGroupList();
        setAnchorEl3(null);
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: result.message,
          alertType: AlertType.Success,
        });
      } else {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: result.message,
          alertType: AlertType.Error,
        });
      }
      setCurDeleteData(null);
      setopenDeleteModal(false);
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }
    setopenDeleteModal(false);
  }

  async function openUserGroupList(userGroupid: any, userGroupName: any) {
    setUserGroupName(userGroupName);
    setUserGroupId(userGroupid);
    try {
      // setLoading(true);
      let params = new URLSearchParams();
      params.append("userGroupId", userGroupid.toString());
      const result: any = await GetUserGroupMemberList(params);
      setUserGroupMemberList(result.data.data);
      setAnchorEl3(null);
      setLoading(false);
      if (result.data.data.length > 0) {
        setOpenListOfDeletePersonModal(true);
      } else {
        setopenDeleteModal(true);
        setCurDeleteData(userGroupid);
      }
      // 
    } catch (error: any) {

    }
  }

  const handleCloseDeleteUserGroupModal = () => {
    setOpenListOfDeletePersonModal(false);
    setopenDeleteModal(false);
    fetchUserGroupList();
    setAnchorEl3(null);
  };

  const handleSelectAll = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);

    // Update all checkboxes to match the "All" checkbox
    const updatedItems: any = userGroupMemberList.map((item: any) => ({
      ...item,
      isDeleted: false,
    }));

    setCheckedItems(updatedItems);
    setUserGroupMemberList(updatedItems);
  };

  const handleCheckboxChange = (index) => {
    const updatedItems: any = userGroupMemberList.map((item: any, idx) =>
      idx === index ? { ...item, isDeleted: false } : item
    );

    setCheckedItems(updatedItems);
    setUserGroupMemberList(updatedItems);

    // Check if all checkboxes are now checked
    const allChecked: any = updatedItems.every(item => item.isDeleted);
    setSelectAll(allChecked);
  };

  const handleDropdownChange = (selectedOption: any, props: any) => {

    // checkbox checked
    const isChecked = true;
    setSelectAll(isChecked);

    // Update all checkboxes to match the "All" checkbox
    const updatedItems: any = userGroupMemberList.map((item: any) => ({
      ...item,
      isDeleted: isChecked,
    }));
    setCheckedItems(updatedItems);
    setUserGroupMemberList(updatedItems);

    props.setFieldValue('initUserGroupId', selectedOption.value);
    props.setFieldValue('initUserGroupName', selectedOption.label);
    props.setFieldValue('oldUserGroupId', userGroupId);

    // Update all the user group dropdowns in the list
    userGroupMemberList.forEach((item: any, index: number) => {
      props.setFieldValue(`updatedGroupData[${index}].userGroupId`, selectedOption.value);
      props.setFieldValue(`updatedGroupData[${index}].label`, selectedOption.label);
      props.setFieldValue(`updatedGroupData[${index}].value`, item.invitationId);
    });
  };

  const handleCloseDeleteModal = () => {
    setopenDeleteModal(false);
    setCurDeleteData(null)
  }

  async function submitForm(values) {
    const oldDetail = values;
    const userGroupUpdatedData = values.updatedGroupData;

    let data = {
      oldUserGroupId: oldDetail.oldUserGroupId,
      dataManagedBy: userContext.userId,
      transferMembersData: JSON.stringify(userGroupUpdatedData)
    }
    try {
      const resultOfTransferMembers: any = await TransferMembersToAnotherUserGroup(data);
      setOpenListOfDeletePersonModal(false);
      setSelectAll(false);
      fetchUserGroupList();
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: resultOfTransferMembers.message,
        alertType: AlertType.Success,
      });
    }
    catch (error: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: error as string,
        alertType: AlertType.Error,
      });
    }
  };

  //Useeffect
  useEffect(() => {
    fetchUserGroupList();
    setSelected([]);
    let e: any = document.getElementById("root");
    e.scrollIntoView({
      block: "start",
      behavior: "smooth",
      inline: "start",
    });
  }, [param, isOpenImport]);

  return (
    <Fade in={true} timeout={{ enter: 500 }}>
      <Box className='main-container'>
        <Grid xs={12} container className='page-header'>
          <Grid xs={6} sm={6} item className="page-breadcrumbs">
            <CustomBreadcrumbs />
          </Grid>
          <Grid xs={6} sm={6} item className='page-header-icon'>
            <Button
              className="button-border-remove"
              color="primary"
              variant="contained"
              onClick={handleAddUserGroup}
              size="medium"
              startIcon={<AddIcon />}
            >
              Add
            </Button>
          </Grid>
        </Grid>

        {isLoading === true ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </Box> :
          <Grid container className="list-container" sx={{ padding: '16px' }}>
            <Grid item xs={12} sm={4} md={3} lg={2} className='search-bar' sx={{ gap: 2 }} >
              <CustomTextField
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    searchValue(value);
                  }
                }}
                placeholder="Search..."
                type="text"
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => setValue(e.target.value)}
                value={value}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton onClick={() => searchValue("")}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),

                  endAdornment: value && (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => searchValue("")}
                    >
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
              />
            </Grid>

            <TableContainer className='table'>
              <Table
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}>
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  rowCount={resultData.userGroupCollection.length}
                />
                <TableBody>
                  {stableSort(
                    resultData.userGroupCollection,
                    getComparator(order, orderBy)
                  )
                    .slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((item: any, index: any) => {
                      const isItemSelected = isSelected(item.userGroupId);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow>
                          <TableCell>
                            <span className="bold-text">
                              {item.name}
                            </span>
                          </TableCell>
                          <TableCell align="right">
                            <Tooltip title="Edit" placement="top">
                              <IconButton className='edit-container' onClick={() => handleEditUserGroup(item.userGroupId)}>
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete" placement="top">
                              <IconButton className='delete-container'
                                // onClick={() => handleDeleteUserGroup(item.userGroupId)}
                                onClick={() => openUserGroupList(item.userGroupId, item.label)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  {resultData.userGroupCollection.length <= 0 ? (
                    <TableRow hover tabIndex={-1} key={"0"}>
                      <TableCell colSpan={6} align="center">
                        No record(s) found
                      </TableCell>
                    </TableRow>
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Dialog
              open={openDeleteModal}
              onClose={handleCloseDeleteModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{ color: "black" }}>
                  Are you sure you want to delete this group?
                </DialogContentText>
              </DialogContent>
              <DialogActions
                sx={{
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <Button
                  type="submit"
                  variant='contained'
                  onClick={() => handleDeleteUserGroup(curDeleteData)}
                  className="button-delete"
                  sx={{
                    backgroundColor: "#d44d4a",
                    color: "white",
                    '&:hover': {
                      backgroundColor: "#e55b5f", // Change this to the desired hover color
                    },
                  }}
                >
                  Yes
                </Button>
                <Button
                  onClick={handleCloseDeleteModal}
                  autoFocus
                  sx={{
                    backgroundColor: "#9e7b4f",
                    color: "white",
                    '&:hover': {
                      backgroundColor: "#b5935d", // Change this to the desired hover color
                    },
                  }}
                >
                  No
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={openListOfDeletePersonModal}
              onClose={handleCloseDeleteUserGroupModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="user-group-member-dialog-root">
              <Formik
                initialValues={submitData}
                validationSchema={validateSchema}
                enableReinitialize
                onSubmit={(values) => {
                  submitForm(values); // Call submitForm with all values
                }}>
                {(props) => (
                  <Form>
                    <DialogContent className="user-group-member-dialogcontent">
                      <DialogContentText id="alert-dialog-description">
                        WARNING! The Group <b>{userGroupName}</b> consists of the following members. If you want to delete, you need to move to another group.


                        <Grid container rowGap={1} pt={2} className="search-space">
                          <List component="div" disablePadding sx={{ width: "100%" }}>
                            <Grid container pb={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>

                              <Grid item sm={5} md={5}>
                                <ListItemButton
                                  sx={{
                                    borderTop: "1px solid #e9e9ef",
                                    "&:hover": { backgroundColor: "#f1f6fe" },
                                    height: "40px",
                                  }}>
                                  <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <Checkbox
                                      id="selectAll"
                                      checked={selectAll}
                                      onChange={handleSelectAll} />
                                    <Typography sx={{ fontWeight: 700 }}>Move all users to </Typography>
                                  </Box>
                                </ListItemButton>
                              </Grid>
                              <Grid item sm={4} md={4}>
                                <AsyncSelect
                                  styles={customStyles}
                                  placeholder={"User Group"}
                                  defaultOptions={resultData.userGroupCollection
                                    .filter((val) => val.consumerId !== 0 && val.userGroupId !== userGroupId)
                                    .map((val) => ({ ...val, value: val.userGroupId, label: val.label }))}
                                  theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                      ...theme.colors,
                                      primary: "#AC8445",
                                      primary25: "#ecebef",
                                      primary50: "#e1dfea",
                                    },
                                  })}
                                  onChange={(e) => handleDropdownChange(e, props)} />

                                <Typography
                                  className={props.values.initUserGroupId === undefined ||
                                    props.values.initUserGroupId === null ||
                                    props.values.initUserGroupId < 1 ? 'validation-message' : 'input'}
                                >{props.values.initUserGroupId === undefined ||
                                  props.values.initUserGroupId === null ||
                                  props.values.initUserGroupId < 1 && 'Please select a group.'}</Typography>
                              </Grid>

                            </Grid>

                            {userGroupMemberList !== undefined &&
                              userGroupMemberList.map((item: any, mainIndex) => (
                                <Grid container key={mainIndex} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                  <Grid item sm={5} md={5}>
                                    <ListItemButton
                                      sx={{
                                        borderTop: "1px solid #e9e9ef",
                                        "&:hover": { backgroundColor: "#f1f6fe" },
                                        height: "40px",
                                      }}>
                                      <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Checkbox
                                          id={item.invitedEmailId}
                                          checked={selectAll}
                                          onChange={() => handleCheckboxChange(mainIndex)}
                                        />
                                        <Typography sx={{ fontWeight: 700 }}>
                                          {item.invitedEmailId}
                                        </Typography>
                                      </Box>
                                    </ListItemButton>
                                  </Grid>
                                  <Grid item sm={4} md={4}>
                                    <AsyncSelect
                                      id="userGroupId"
                                      styles={customStyles}
                                      placeholder={"User Group"}
                                      defaultOptions={resultData.userGroupCollection
                                        .filter((val) => val.consumerId !== 0 && val.userGroupId !== userGroupId)
                                        .map((val) => ({ ...val, value: val.userGroupId, label: val.label }))}
                                      theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                          ...theme.colors,
                                          primary: "#AC8445",
                                          primary25: "#ecebef",
                                          primary50: "#e1dfea",
                                        },
                                      })}
                                      value={
                                        props.values.updatedGroupData[mainIndex]?.userGroupId
                                          ? { value: props.values.updatedGroupData[mainIndex].userGroupId, label: props.values.updatedGroupData[mainIndex].label }
                                          : null
                                      }
                                      onChange={(selectedOption: any) => {
                                        props.setFieldValue(`updatedGroupData[${mainIndex}].userGroupId`, selectedOption.value);
                                        props.setFieldValue(`updatedGroupData[${mainIndex}].label`, selectedOption.label);
                                        props.setFieldValue(`updatedGroupData[${mainIndex}].value`, item.invitationId);
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              ))}
                          </List>
                        </Grid>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                      <Button
                        type="submit" // This triggers Formik's handleSubmit
                        className="button-delete">
                        Yes
                      </Button>
                      <Button onClick={handleCloseDeleteUserGroupModal} autoFocus>
                        No
                      </Button>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </Dialog>

            {/* <Grid
            container
            paddingTop={1}
            className='pagination'
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Grid item xs={12} sm={6}
              className="pagination-label"
            >
              1 of 1 
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack
                component="div"
                alignItems={"end"}
                justifyContent={"center"}
              >
                <Pagination
                  color="primary"
               
                />
              </Stack>
            </Grid>
          </Grid> */}
            {resultData.userGroupCollection.length > 0 && (
              <Grid
                container
                paddingTop={1}
                alignItems={"center"}
                justifyContent={"center"}
                className='pagination'
              >
                <Grid item xs={12} sm={6}
                  className="pagination-label"
                >
                  {pageStartIndex} - {pageEndIndex} of {totalCount}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Stack
                    component="div"
                    alignItems={mobileScreen ? " center" : "end"}
                    justifyContent={"center"}
                  >
                    <Pagination
                      color="primary"
                      count={counData}
                      page={page_Index}
                      onChange={handleChangePageNew}
                    />
                  </Stack>
                </Grid>
              </Grid>
            )}

          </Grid>}
      </Box>
    </Fade>
  )
}

const customStyles = {
  control: (base: any, state: any) => ({
    ...base
  }),
  menu: (base: any) => ({
    ...base
  }),
  menuList: (base: any) => ({
    ...base,
    minHeight: "200px",
    maxHeight: "200px" // your desired height
  })
};