import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  TextField,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Title from "../../components/Title";
import * as Yup from "yup";
//import { CustomSelect } from "../../../components/CustomFields/CustomSelect";
import { Form, Formik } from "formik";
import PhoneInput from 'react-phone-number-input'
import '../../theme/style.scss';
import BreadcrumbsComponent from '../../components/BreadcrumbsComponent/BreadcrumbsComponent';
import CustomBreadcrumbs from '../../components/BreadcrumbsComponent/CustomBreadcrumbs';
import { AlertType } from "../../services/models/AlertType";
import { PreferencesContext } from "../../PreferenceContext";
import CustomAutoFocus from "../../components/Common/CustomAutoFocus";
import { EncryptDecryptService } from "../../services/common/EncryptionService";
import { CreateTag, GetTagDetailById, UpdateTag } from "../../services/TagService";
import { isElementVisible } from "../../services/common/CommonFuctions";

const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#fff", // Set the desired background color
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));


export default function TagSave(props: any) {
  const { code, isForPopup, handleTagChange, handleCloseorOpenpopup, handleClosePopup } = props;
  let formInitialValues;
  if (isForPopup && isForPopup !== undefined) {
    formInitialValues = { tagId: 0, code: "", name: code };
  } else {
    formInitialValues = { tagId: 0, code: "", name: "" };
  }
  let location = useLocation();
  // const { title, button, tagId, isAddPopUp } =
  //   (props.location && props.location.state) || props.state || {};
  const { isAddPopUp } =
    (location && location.state) || props.state || {};

  const Productdata = {
    title: "Add Product",
    button: "Save",
    productId: 0,
    tagIdForPopUp: true,
    taxIdForPopUp: false,
  };
  const matches = useMediaQuery("(max-width:599px)");
  const [isSubmitting, setSubmitting] = useState(false);
  const [taginitial, setTagInitial] = useState(formInitialValues);
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  const [isLoading, setLoading] = useState(false);
  const [state, setState] = useState(Productdata);
  const [open, setOpen] = useState(true);
  const { setInputRef } = CustomAutoFocus(isForPopup, true);
  const edService = new EncryptDecryptService();

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let navigate = useNavigate();
  const handleCancelTag = () => {
    if (isForPopup === true) {
      handleClosePopup();
    } else {
      navigate(-1);
    }
  };

  let urlParams = useQuery();
  let curRawTagId = urlParams.get("q");
  let curTagId = 0;
  if (!isForPopup) {
    if (curRawTagId !== null && curRawTagId !== undefined) {
      let decId = edService.decrypt(curRawTagId);
      if (decId !== "" && decId !== null && decId !== undefined) {
        curTagId = +decId;
      } else {
        navigate("/not-found");
      }
    } else {
      navigate("/not-found");
    }
  }

  const [tagId, setTagId] = useState(curTagId);

  const handleCancelTagOfDialog = () => {
    navigate({
      pathname: "/tags",
    }, {
      state: {
        title: "Add Tag",
        button: "Save",
        productId: 0,
        tagIdForPopUp: false,
        taxIdForPopUp: false,
      },
    });
  };

  //Get Data
  async function fetchData() {

    try {
      //setLoading(true);
      let params = new URLSearchParams();
      params.append("tagId", tagId.toString());
      const result: any = await GetTagDetailById(params);
      let editDetails = result.data.data;

      let updatedValue = {
        tagId: editDetails.tagId,
        name: editDetails.name,
      };
      //setLoading(false);
      setTagInitial(updatedValue);
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }


  }

  //Submit Form (Tag)
  async function SubmitTag(value: any) {
    setSubmitting(true);
    let tagData = value;
    let SaveUpdateTag = {
      tagId: tagData.tagId,
      Name: tagData.name,
      dataManagedBy: userContext.userId,
    };
    if (!isForPopup && tagData.tagId > 0) {
      try {
        const resultTagUpdate: any = await UpdateTag(SaveUpdateTag);
        if (resultTagUpdate && resultTagUpdate.data === true) {
          setSubmitting(false);
          updateUserContext({
            ...userContext,
            isAlert: true,
            alertMessage: resultTagUpdate.message,
            alertType: AlertType.Success,
          });
          if (isForPopup === true) {
            handleCloseorOpenpopup(code);
          } else {
            navigate(-1);
          }
        }
      } catch (e: any) {
        setSubmitting(false);
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: typeof e === 'string' ? e as string : e?.message as string,
          alertType: AlertType.Error,
        });
      }
    } else {
      try {
        const resultTagSave: any = await CreateTag(SaveUpdateTag);
        if (resultTagSave && resultTagSave.data === true) {
          setSubmitting(false);
          updateUserContext({
            ...userContext,
            isAlert: true,
            alertMessage: resultTagSave.message,
            alertType: AlertType.Success,
          });
          if (isForPopup === true) {
            handleCloseorOpenpopup(code);
          } else {
              navigate(-1);
          }
        }
      } catch (e: any) {
        setSubmitting(false);
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: typeof e === 'string' ? e as string : e?.message as string,
          alertType: AlertType.Error,
        });
      }
    }
  }

  const handleCloseDialog = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };

  // const handleOpendialog = () => {
  //   setOpen(true);
  // }

  useEffect(() => {
    if (tagId > 0) {
      fetchData();
    }
  }, []);

  const handleFocusOnKeyDown = (event: any) => {
    if (event.keyCode === 13 && event.target.nodeName === "INPUT") {
      let form = event.target.form;
      let index = Array.prototype.indexOf.call(form, event.target);
      let Elements = form.elements;

      let nextIndex = index + 2;
      while ((nextIndex < Elements.length && !isElementVisible(Elements[nextIndex])) || (Elements[nextIndex]?.disabled === true)) {
        nextIndex++;
      }
      if (nextIndex < Elements.length) {
        Elements[nextIndex].focus();
      }
      event.preventDefault();
    }
  }

  React.useEffect(() => {
    document.addEventListener("keydown", handleFocusOnKeyDown);
    return () => {
      document.removeEventListener("keydown", handleFocusOnKeyDown);
    }
  }, []);

  return (
    <>
      <Title pageTitle="Tag" />
      <Formik
        enableReinitialize
        initialValues={taginitial}
        initialTouched={{
          name: true,
        }}
        validateOnMount={true}
        onSubmit={(values) => {
          SubmitTag(values);
        }}
        validationSchema={
          Yup.object().shape({
            // code: Yup.string().required("Code is required."),
            name: Yup.string().required("Name is required."),
          })}
      >
        {(props) => {
          return (

            <Box className={!isForPopup ? "main-container" : ""}>
              {!isForPopup &&
                <Grid item xs={12} container className="page-header-save">
                  <Grid xs={6} sm={6} item className="page-breadcrumbs">
                    <CustomBreadcrumbs />
                  </Grid>
                </Grid>
              }
              <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  email: '',
                }}
                onSubmit={async (values) => {
                  console.log("values", values)
                }}
              >
                <Form onSubmit={props.handleSubmit} noValidate>
                  <Box className={!isForPopup ? "main-container" : ""}>
                    <Grid container>
                      <Grid item xs={12} mb={!isForPopup ? 3 : 0} className="group-container">
                        <Grid container item spacing={2}>
                          <Grid container item spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="h6">{tagId > 0 ? "Update Tag" : "Add Tag"}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <TextField
                                  required
                                  id="name"
                                  label="Name"
                                  fullWidth
                                  size="small"
                                  inputProps={{ maxLength: 100 }}
                                  onChange={(e) => {
                                    props.handleChange(e);
                                    if (isForPopup) {
                                      handleTagChange(e.target.value);
                                    }
                                  }}
                                  value={props.values.name}
                                  onBlur={props.handleBlur}
                                  error={
                                    props.errors.name && props.touched.name
                                      ? true
                                      : false
                                  }
                                />
                                {props.errors.name && props.touched.name && (
                                  <Typography
                                    className={
                                      props.errors.name && props.touched.name
                                        ? "validation-message text-input error"
                                        : "validation-message text-input"
                                    }
                                  >
                                    {props.errors.name}
                                  </Typography>
                                )}
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} >
                            <Box sx={{ display: "flex", justifyContent: "center", gap: 2, paddingTop: "28px !important" }}>
                              <LoadingButton
                                color="primary"
                                tabIndex={3}
                                loadingPosition="start"
                                startIcon={<></>}
                                variant="contained"
                                type="submit"
                                className="primary-button"
                                //onClick={() => navigate("/users")}
                                name="submit"
                              >
                                {tagId > 0 ? "Update" : "Save"}
                              </LoadingButton>
                              <LoadingButton
                                tabIndex={3}
                                loadingPosition="start"
                                startIcon={<></>}
                                variant="outlined"
                                name="submit"
                                className="secondary-button"
                                color="secondary"
                                onClick={handleCancelTag}

                              >
                                Cancel
                              </LoadingButton>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              </Formik>

            </Box>
          );
        }}
      </Formik>
    </>
  );
}
