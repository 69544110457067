import React from "react";
import { Route, Routes } from "react-router-dom";
import SignIn from "../pages/SignIn/SignIn";
import NavBar from "./_components/Navbar/NavBar";
import ForgotPassword from "../pages/Forgotpassword/Forgotpassword";
import NotFound from "../pages/NotFound/NotFound";
import UsersSave from "../pages/Team/Users/UsersSave";
import UsersList from "../pages/Team/Users/UsersList";
import Profile from "../pages/Profile/Profile";
import ResetPasswordPage from "../pages/ResetPassword/ResetPassword";
import TagList from "../pages/Tag/TagList";
import TagSave from "../pages/Tag/TagSave";
import GroupSave from "../pages/Team/Group/GroupSave";
import GroupList from "../pages/Team/Group/GroupList";
import ManageUser from "../pages/Team/Users/ManageUser";
import App from "../App";
import HomeSave from "../pages/Home/HomeSave";
import Home from "../pages/Home/Home";

export default function RootNavigation({ colorScheme }: { colorScheme: any }) {
  return <RootNavigator colorScheme={colorScheme} />;
}

function RootNavigator({ colorScheme }: { colorScheme: any }) {
  return (
    <Routes>
      <Route element={<App />} path="/" >
        <Route element={<SignIn />} path="/" />
        <Route element={<ForgotPassword />} path="/forgot-password" />
        <Route element={<ResetPasswordPage />} path="/reset-password" />
        <Route element={<ManageUser />} path="/manage-user" />
        <Route
          element={
            <NavBar>
              <Home />
            </NavBar>
          }
          path="/home"
        />
        <Route
          element={
            <NavBar>
              <UsersList />
            </NavBar>
          }
          path="users"
        />
        {/* <Route
        element={
          <NavBar>
            <UsersSave />
          </NavBar>
        }
        path="/users/users-save"
      /> */}
        <Route
          element={
            <NavBar>
              <Profile />
            </NavBar>
          }
          path="my-profile"
        />
        <Route
          element={
            <NavBar>
              <UsersList />
            </NavBar>
          }
          path="users"
        />
        <Route
          element={
            <NavBar>
              <UsersSave />
            </NavBar>
          }
          path="users/users-save"
        />
        <Route
          element={
            <NavBar>
              <GroupList />
            </NavBar>
          }
          path="groups"
        />
        <Route
          element={
            <NavBar>
              <GroupSave />
            </NavBar>
          }
          path="groups/group-save"
        />
        <Route
          element={
            <NavBar>
              <TagList />
            </NavBar>
          }
          path="tags"
        />
        <Route
          element={
            <NavBar>
              <TagSave />
            </NavBar>
          }
          path="tags/tag-save"
        />
         <Route
          element={
            <NavBar>
              <HomeSave />
            </NavBar>
          }
          path="home/home-save"
        />
        {/* It's a not found path */}
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}
