import React, { useEffect } from 'react';
import { SITE_TITLE } from '../services/common/const';
import { Grid } from '@mui/material';



// export default function Title(props: {pageTitle: string,}) {
export default function Title(props: any) {

  function getSiteTitle() {
    let fullSiteName = SITE_TITLE;

    if (props.pageTitle && props.pageTitle.length > 0) {
      fullSiteName = props.pageTitle + " - " + fullSiteName;
    }
    return fullSiteName;
  }
  useEffect(() => {
    const prevTitle = document.title;
    document.title = getSiteTitle();
    return () => {
      document.title = prevTitle;
    };
  }, []);


  function pageTitleName(page: any) {
    let pageName = "";

    switch (page) {
      case "users": {
        break;
      }
      case "users-save": {
        break;
      }
      case "groups": {
        break;
      }
      case "group-save": {
        break;
      }
      case "tags": {
        break;
      }
      case "tag-save": {
        break;
      }
      case "home": {
        break;
      }
      case "home-save": {
        break;
      }
    }
    return pageName;
  }
  return <>
    <h4>{pageTitleName(props.pageTitle)}</h4>
  </>;
}