import React, { useEffect, useState, Suspense } from "react";
import './App.css';
import "./theme/style.scss";
import "./theme/app.scss";
//import "./theme/main.scss";
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useNavigate, useLocation, Outlet } from "react-router-dom";
// import { lightTheme } from './Theme/Palette/light-palette';
// import Alerts from "./Components/Alerts";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import { getStorage, setStorage } from "./services/common/storage";
import { ContextModel } from "./services/models/Context";
import { Info } from "./services/AuthService";
import { PreferencesContext } from "./PreferenceContext";
import RootNavigation from "./navigations/RootNavigation";
import Alerts from "./components/Common/Alerts";
import { lightTheme } from "./theme/Palette/light-palette";
import UnauthorizeDialog from "./components/UnauthorizeDialog";
// import UnauthorizeDialog from "./Components/UnauthorizeDialog";
// import SplashComponent from "./Navigation/splash";
// import ScrollTop from "../src/Components/ScrollTop";
// import offlineimg from './Assets/offline.png';
// import { Info } from "./Services/AuthService";
// import Nav from "./Navigation/navbar";
// import { useNetworkCheck } from "../src/context/NetworkContext";

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#2c2ce4',
    },
    secondary: {
      main: '#2c2ce4'
    }, background: {
      default: '#303030'
    }

  },
});

function App() {

  const [mode, setMode] = useState<"light" | "dark" | undefined>(
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? "light" : "light"
  );
  // const { isOnline } = useNetworkCheck();
  const [userContext, setUserContext] = React.useState<ContextModel>(getStorage());
  const [isLoading, setLoading] = useState(true);
  let navigate = useNavigate();
  let location = useLocation();

  const updateUserContext = React.useCallback((context) => {
    setStorage(context);
    return (setUserContext(context));
  }, [userContext]);

  const preferences = React.useMemo(
    () => ({
      updateUserContext, userContext,
    }), [userContext]
  );




  const changeMode = (newMode: any) => {
    setMode(newMode);
  }


  let publicPages = ['/', 'manage-user', 'reset-password', 'set-password', 'forgot-password', 'my-profile', 'users', 'users-save', 'tags', 'tag-save', 'groups', 'group-save'];


  const checkIfLoggedIn = () => {
    const modeMe = (e: any) => {
      setMode(e.matches ? "dark" : "light");
    }
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', modeMe);

    let value = getStorage();
    let page = window.location.href.split(window.location.host)[1];


    let allPages = ['reset-password', 'manage-user', 'set-password', 'forgot-password', 'home', 'my-profile', 'users', 'users-save', 'tags', 'tag-save', 'groups', 'group-save'];


    if (value && value.token && value.token.length > 0) {
      if (value.userId > 0) {


        if (page && page != null && page !== undefined) {
          let curPage = page.split('/')[1];

          //for Query params
          let isParams = curPage.includes("?");
          if (isParams === true) {
            curPage = curPage.split('?')[0];
          }

          if (curPage !== "") {
            let isPage = allPages.findIndex((x) => x === curPage);
            if (isPage >= 0) {
            } else {
              navigate("/not-found");
            }
          } else {
            navigate("/home");
          }
        } else {
          navigate("/");
        }

      }
      else {

        if (page && page != null && page !== undefined) {
          let curPage = page.split('/')[1];

          //for Query params
          let isParams = curPage.includes("?");
          if (isParams === true) {
            curPage = curPage.split('?')[0];
          }

          if (curPage !== "") {
            let isPage = publicPages.findIndex((x) => x === curPage);
            if (isPage >= 0) {
              // navigate(page);
            } else {
              navigate("/");
            }
          }
          else {
            navigate("/");
          }

        } else {
          navigate("/");
        }
      }
    }
    else {
      if (page && page != null && page !== undefined) {
        let curPage = page.split('/')[1];

        //for Query params
        let isParams = curPage.includes("?");
        if (isParams === true) {
          curPage = curPage.split('?')[0];
        }

        if (curPage !== "") {
          let isPage = publicPages.findIndex((x) => x === curPage);
          if (isPage >= 0) {
            // navigate(page);
          } else {
            //history.push("/not-found");
            navigate("/");
          }
        }
        else {
          navigate("/");
        }
      } else {
        navigate("/");
      }
    }
    setLoading(false);
  }
  async function init() {
    try {
      const data: any = await Info(null);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
     checkIfLoggedIn();
  }, [location.pathname]);


  useEffect(() => {
    init();
    // setTimeout(() => setLoading(false), 3000);
  }, []);


  useEffect(()=>{
if(userContext.isUnauthorized === true){
  let contextModel = new ContextModel();
    updateUserContext(contextModel);
    navigate("/");
}
  },[userContext.isUnauthorized])

  return (
    <>

      <ThemeProvider theme={mode === 'dark' ? lightTheme : lightTheme}>
        <CssBaseline />
        <PreferencesContext.Provider value={preferences}>
          {userContext &&
            <>
              <Alerts isOpen={userContext.isAlert} displayMessage={userContext.alertMessage} type={userContext.alertType}></Alerts>
            </>
          }
          {/* {!isLoading && */}
          <Outlet />
          {/* } */}
          <UnauthorizeDialog isOpen={userContext.isUnauthorized}/>
        </PreferencesContext.Provider>
      </ThemeProvider>
    </>
  );
}
export default App;