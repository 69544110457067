import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const CustomBreadcrumbsHome = ({ breadcrumbs, setParentDocumentId, parentDocumentId }) => {
  const navigate = useNavigate();

  // const handleBreadcrumbClick = (documentId) => {
  //   setParentDocumentId(documentId);
  //   // Navigate to the appropriate path with query parameters if needed
  //   const encodedId = encodeURIComponent(documentId.toString());
  //   navigate(`/home?d=${encodedId}`);
  // };
  const handleBreadcrumbClick = (documentId) => {
    if (documentId !== parentDocumentId) {
      setParentDocumentId(documentId);
      // Navigate to the appropriate path with query parameters if needed
      const encodedId = encodeURIComponent(documentId.toString());
      navigate(`/home?d=${encodedId}`);
    }
  };

  const reversedBreadcrumbs = [...breadcrumbs].reverse();
  const breadcrumbLinks = [
    { documentId: 0, documentName: 'Home' }, // Assuming ID 0 is the root or starting point
    ...reversedBreadcrumbs,
  ];

  let url = '';

  const breadcrumbElements = breadcrumbLinks.map((breadcrumb, index) => {
    url += `/${breadcrumb.documentId}`; // Construct the URL path
    const isLast = index === breadcrumbLinks.length - 1;
    const displayName = breadcrumb.documentName;

    return (
      <React.Fragment key={breadcrumb.documentId}>
        {breadcrumb.documentId === 0 ? (
          <Link
            to="/"
            style={{
              fontSize: '14px',
              color: isLast ? 'black' : '#2e67a3',
              textDecoration: isLast ? 'none' : 'underline',
              cursor: isLast ? 'default' : 'pointer',
            }}
          >
            <span>{displayName}</span>
          </Link>
        ) : (
          <span
            onClick={() => handleBreadcrumbClick(breadcrumb.documentId)}
            style={{
              fontSize: '14px',
              textDecoration: isLast ? 'none' : 'underline',
              color: isLast ? 'black' : '#2e67a3',
              cursor: isLast ? 'default' : 'pointer',
            }}
          >
            {displayName}
          </span>
        )}
        {!isLast && <span> / </span>}
      </React.Fragment>
    );
  });

  return <div>{breadcrumbElements}</div>;
};

export default CustomBreadcrumbsHome;
