import React, { useContext, useEffect, useState } from 'react'
import {
  Box, Grid, IconButton, InputAdornment, TextField, Button, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Pagination, Stack, useMediaQuery, useTheme, Dialog, DialogContent, DialogContentText, DialogActions
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from 'react-router-dom';
import '../../theme/style.scss';
import CustomBreadcrumbs from '../../components/BreadcrumbsComponent/CustomBreadcrumbs';
import { EncryptDecryptService } from '../../services/common/EncryptionService';
import { PAGE_SIZE } from '../../services/common/const';
import { PreferencesContext } from '../../PreferenceContext';
import { AlertType } from '../../services/models/AlertType';
import { DeleteTag, GetTagList } from '../../services/TagService';
import CircularProgress from '@mui/material/CircularProgress';

const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: '#fff', // Set the desired background color
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.grey[300],
      borderWidth: '1px',
      BorderStyle: 'solid'
    },
    '&:hover fieldset': {
      borderColor: theme.palette.grey[500],
      borderWidth: '1px',
      BorderStyle: 'solid'
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: "2px",
    },
  },
}));
interface Data {
  id: number;
  code: string;
  Name: string;
  none: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc" | "";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
interface EnhancedTableProps {
  numSelected: number;
  //onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  //onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

//Table Header
function EnhancedTableHead(props: EnhancedTableProps) {
  return (
    <TableHead className='table-header-color'>
      <TableRow>
        <TableCell
          sx={{
            display: {
              xs: "table-cell",
              sm: "table-cell",
              md: "table-cell",
              lg: "table-cell",
            },
            fontWeight: "bold",
            width: "60%"
          }}
          align="left"
        >
          Name
        </TableCell>

        <TableCell
          sx={{
            display: {
              xs: "table-cell",
              sm: "table-cell",
              md: "table-cell",
              lg: "table-cell",
            },
            fontWeight: "bold",
            width: "40%"
          }}
          align="left"
        >
          {''}
        </TableCell>

      </TableRow>
    </TableHead>
  );
}

const Tag: any = {
  tagCollection: [],
};
const edService = new EncryptDecryptService();

const parameters: any = {
  pageIndex: 1,
  pageSize: PAGE_SIZE,
  sortColumn: "",
  sortDirection: "",
  searchValue: "",
};
// import ClearIcon from "@mui/icons-material/Clear";
export default function TagList() {
  const Tagdata = {
    title: "Add Tag",
    button: "Save",
    tagId: 0,
    isAddPopUp: false,
  };
  const navigate = useNavigate()
  const theme = useTheme()
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [anchorEl, setAnchorEl3] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const [state, setState] = useState(Tagdata);
  const [param, setParam] = useState(parameters);
  const [selected, setSelected] = useState<readonly number[]>(Tag.tagCollection);
  const [totalCount, setTotalCount] = useState(0);
  const [pageStartIndex, setPageStartIndex] = useState(1);
  const [pageEndIndex, setPageEndIndex] = useState(1);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [value, setValue] = useState("");
  const [order, setOrder] = useState<Order>("");
  const [orderBy, setOrderBy] = useState<keyof Data>("none");
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE);
  const [resultData, setResultData] = useState(Tag);
  const [page_Index, setPage_Index] = useState(1);
  const [counData, setCounData] = useState(0);
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  const [isLoading, setLoading] = useState(false);
  const [Curr_page, setCurrPage] = useState(1);
  const isSelected = (id: number) => selected.indexOf(id) !== -1;
  const [isOpenImport, setIsOpenImport] = useState(false);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [curDeleteData, setCurDeleteData] = useState(null);

  const handleChangePageNew = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    if (Curr_page !== value) {
      setCurrPage(value);
      setPage_Index(value);
      setParam({ ...param, pageIndex: value });
      fetchTagList();
    }
  };

  //Searching
  function searchValue(val: any) {
    if (val === "") {
      setValue("");
    }
    setPage_Index(1);
    setParam({ ...param, searchValue: val.toString(), pageIndex: 1 });
  }

  //Async Function 
  async function fetchTagList() {
    try {
      //new http for API
      setLoading(true);
      let params = new URLSearchParams();
      params.append("pageIndex", page_Index.toString());
      params.append("pageSize", param.pageSize);
      params.append("sortColumn", param.sortColumn);
      params.append("sortDirection", param.sortDirection);
      params.append("searchValue", param.searchValue);
      const result: any = await GetTagList(params);
      if (result && result.data.data) {
        let dataCount = result.data.data.totalCount;
        let tagInfo = result.data.data;
        let showDataCount = Math.ceil(dataCount / PAGE_SIZE);
        setResultData(tagInfo);
        setCounData(showDataCount);
        let startIndex = (page_Index - 1) * param.pageSize + 1;
        let endIndex =
          (page_Index - 1) * param.pageSize +
          (result.data.data.tagCollection
            ? result.data.data.tagCollection.length
            : 0);

        setPageStartIndex(startIndex);
        setPageEndIndex(endIndex);
        setTotalCount(dataCount);
      } else {
        console.log("Error");
      }
      setLoading(false);
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }
  }

  const handleAddTag = (e: any) => {
    let encTagId: any = edService.encrypt(0);
    e.preventDefault();
    navigate({
      pathname: "/tags/tag-save",
      search: `?q=${encodeURIComponent(encTagId)}`,
    }, {
      state,
    });
  };

  async function handleEditTag(tagId: any) {
    try {
      let encTagId: any = edService.encrypt(tagId);
      navigate({
        pathname: "/tags/tag-save",
        search: `?q=${encodeURIComponent(encTagId)}`,
      }, {
        state: { title: " Edit Tag", button: "Update", tagId: tagId, isAddPopUp: "false" },
      });
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }
  }

  const handleDeleteTagModal = (tagId: any) => {
    setCurDeleteData(tagId);
    setOpenDeleteModal(true);
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setCurDeleteData(null);
  }

  async function handleDeleteTag(tagId: any) {
    try {
      let DeleteData = {
        ids: tagId.toString(),
        isDeleted: true,
        dataManagedBy: userContext.userId,
      };
      const result: any = await DeleteTag(DeleteData);
      if (result) {
        setOpenDeleteModal(false);
        setCurDeleteData(null);
        fetchTagList();
        setAnchorEl3(null);
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: result.message,
          alertType: AlertType.Success,
        });
      } else {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: result.message,
          alertType: AlertType.Error,
        });
      }
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }
  }

  //Useeffect
  useEffect(() => {
    fetchTagList();
    setSelected([]);
    let e: any = document.getElementById("root");
    e.scrollIntoView({
      block: "start",
      behavior: "smooth",
      inline: "start",
    });
  }, [param, isOpenImport]);

  return (
    <>
      <Box className='main-container'>
        <Grid xs={12} container className='page-header'>
          <Grid xs={6} sm={6} item className="page-breadcrumbs">
            <CustomBreadcrumbs />
          </Grid>
          <Grid xs={6} sm={6} item className='page-header-icon'>
            <Button
              className="button-border-remove"
              color="primary"
              variant="contained"
              onClick={handleAddTag}
              size="medium"
              startIcon={<AddIcon />}
            >
              Add
            </Button>
          </Grid>
        </Grid>

        {isLoading === true ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </Box> :
          <Grid container className="list-container" sx={{ padding: '16px' }}>
            <Grid item xs={12} sm={4} md={3} lg={2} className='search-bar' sx={{ gap: 2 }} >
              <CustomTextField
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    searchValue(value);
                  }
                }}
                placeholder="Search..."
                type="text"
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => setValue(e.target.value)}
                value={value}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton onClick={() => searchValue("")}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),

                  endAdornment: value && (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => searchValue("")}
                    >
                      <ClearIcon />
                    </IconButton>
                  ),
                }}
              />
            </Grid>

            <TableContainer className='table'>
              <Table
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}>
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  rowCount={resultData.tagCollection.length}
                />
                <TableBody>
                  {stableSort(
                    resultData.tagCollection,
                    getComparator(order, orderBy)
                  )
                    .slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((item: any, index: any) => {
                      const isItemSelected = isSelected(item.tagId);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow>
                          <TableCell>
                            <span className="bold-text">
                              {item.name}
                            </span>

                          </TableCell>
                          <TableCell align="right">
                            <Tooltip title="Edit" placement="top">
                              <IconButton className='edit-container' onClick={() => handleEditTag(item.tagId)}>
                                <EditIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Delete" placement="top">
                              <IconButton className='delete-container' onClick={() => handleDeleteTagModal(item.tagId)}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  {resultData.tagCollection.length <= 0 ? (
                    <TableRow hover tabIndex={-1} key={"0"}>
                      <TableCell colSpan={6} align="center">
                        No record(s) found
                      </TableCell>
                    </TableRow>
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {resultData.tagCollection.length > 0 && (
              <Grid
                container
                paddingTop={1}
                alignItems={"center"}
                justifyContent={"center"}
                className='pagination'
              >
                <Grid item xs={12} sm={6}
                  className="pagination-label"
                >
                  {pageStartIndex} - {pageEndIndex} of {totalCount}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Stack
                    component="div"
                    alignItems={mobileScreen ? " center" : "end"}
                    justifyContent={"center"}
                  >
                    <Pagination
                      color="primary"
                      count={counData}
                      page={page_Index}
                      onChange={handleChangePageNew}
                    />
                  </Stack>
                </Grid>
              </Grid>
            )}

          </Grid>}

        
        <Dialog
              open={isOpenDeleteModal}
              onClose={handleCloseDeleteModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{ color: "black" }}>
                  Are you sure you want to delete?
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{
                display: "flex",
                justifyContent: "center"
              }}>
                <Button
                  onClick={() => handleDeleteTag(curDeleteData)}
                  sx={{
                    backgroundColor: "#d44d4a",
                    color: "white",
                    '&:hover': {
                      backgroundColor: "#e55b5f", // Change this to the desired hover color
                    },
                  }}
                >
                  Yes
                </Button>
                <Button
                  sx={{
                    backgroundColor: "#9e7b4f",
                    color: "white",
                    '&:hover': {
                      backgroundColor: "#b5935d", // Change this to the desired hover color
                    },
                  }}
                  onClick={handleCloseDeleteModal}
                  autoFocus
                >
                  No
                </Button>
              </DialogActions>
            </Dialog>
      </Box>
    </>
  )
}