import * as React from "react";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Grid,
  Collapse,
  Tooltip,
  Button,
  Typography,
  Avatar,
  Menu,
  useMediaQuery,
  SwipeableDrawer,
  Divider,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import { ContextModel } from "../../../services/models/Context";
import MenuIcon from "@mui/icons-material/Menu";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MenuOpenOutlinedIcon from "@mui/icons-material/MenuOpenOutlined";
import Zoom from "@mui/material/Zoom";
import TiyaLogo from "../../../components/CustomButton/TiyaLogo/TiyaLogo";
import { useLocation, useNavigate } from "react-router-dom";
import "./navbardrawer.scss";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import GroupsIcon from "@mui/icons-material/Groups";
import LogoutIcon from "@mui/icons-material/Logout";
import { useTheme } from "@mui/material/styles";
import { PreferencesContext } from "../../../PreferenceContext";

// =================Navbar Drawar============================
const drawerWidth = 210;
const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    // backgroundColor: theme.palette.common.black
    backgroundColor: "#ac8445",
  },
}));
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: "0.15s",
  }),
  overflowX: "hidden",
});
const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: "0.15s",
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));
function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 9)) & 0xff;
    color += `00${value.toString(12)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
// const PAGE_SIZE = 15
function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 35,
      height: 35,
      fontSize: 15,
    },
    children: `${name.split(" ")[0][0].toUpperCase()}${name
      .split(" ")[1][0]
      .toUpperCase()}`,
  };
}
function stringAvatarBig(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 80,
      height: 80,
      fontSize: 25,
    },
    children: `${name.split(" ")[0][0].toUpperCase()}${name
      .split(" ")[1][0]
      .toUpperCase()}`,
  };
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// =============END Drawar============================

// For trial account

export default function Nav(props: any) {
  //  const [openNew, setopenNew] = React.useState(false);
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const [openMasterNull, setOpenMasterNull] = React.useState(false);
  const [openFrontDesk, setOpenFrontDesk] = React.useState(false);
  const [openOPD, setOpenOPD] = React.useState(false);
  const [openPatient, setOpenPatient] = React.useState(false);
  const [openDoctor, setOpenDoctor] = React.useState(false);
  const [openAccounts, setOpenAccounts] = React.useState(false);
  const [openTeam, setOpenTeam] = React.useState(false);
  const [state, setState] = React.useState({ left: false });
  const theme = useTheme();
  const { userContext, updateUserContext } = React.useContext(PreferencesContext);
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const matches = useMediaQuery("(max-width:1024px)");
  const smallScreen = useMediaQuery("(max-width:767px)");
  const mobileScreen = useMediaQuery("(max-width:425px)");

  const handleClickHome = () => {
    navigate("/home");
    setNewOpen(true);
    setOpenTeam(false);
  };
  const handleClickTag = () => {
    navigate("/tags");
    setNewOpen(true);
    setOpenTeam(false);
  };


  type Anchor = "top" | "left" | "bottom" | "right"

  const toggleDrawer =
    (anchor: any, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event &&
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }

        let page = event !== undefined ? event.target.innerText : null;
        setNewOpen(true)

        switch (page) {
          case "Home": {
            navigate("/home");
            break;
          }
          case "Users": {
            navigate("/users");
            break;
          }
          case "Groups": {
            navigate("/groups");
            break;
          }

          default: {
          }
        }
        setState({ ...state, [anchor]: open });
      };

  const handleDrawerBtn = () => {
    if (openNew) {
      handleDrawerClose();
    } else {
      handleopenNew();
    }
    const handleClickMasterNull = () => {
      setOpenMasterNull(openMasterNull);
    };
    setOpenTeam(false);
  };

  const handleClickTeam = () => {
    setOpenTeam(!openTeam);
    setOpenAccounts(false);
    setOpenDoctor(false);
    setNewOpen(true)
    setOpenPatient(false);
    setOpenOPD(false);
    setOpenFrontDesk(false);
  };
  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const spanRef = React.useRef(null);
  const open4 = Boolean(anchorEl4);

  const profieClick = (any) => {
    setAnchorEl4(spanRef.current);
  };



  const profileClose = (page: string) => {
    //let page = event.target.innerText;
    switch (page) {
      case "My Profile": {
        setAnchorEl4(null);
        navigate("/my-profile");
        break;
      }
      case "Sign out": {
        setAnchorEl4(null);

        var context: ContextModel = new ContextModel();
        updateUserContext(context);
        navigate("/");
        break;
      }
      default: {
        setAnchorEl4(null);
      }
    }
    setAnchorEl4(null);
  };
  const [openNew, setNewOpen] = React.useState(false);

  const handleopenNew = () => {
    setNewOpen(true);
  };

  const handleDrawerClose = () => {
    setNewOpen(false);
  };

  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const hide = () => setAnchorEl2(null);

  const homeClick = () => {
    if (matches) {
      setState({ left: false });
    }
    navigate("/home");
    setNewOpen(true)
    setOpenTeam(false);
  };
  const handleClickMasterNull = () => {
    setOpenMasterNull(openMasterNull);
  };


  //Mobile  View Data

  const list = (anchor: Anchor) => (
    <List style={{ height: "50vh", width: "33vh" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          marginTop: "7px",
          marginLeft: "14px",
          marginBottom: "7px",
        }}
      >
        <span className="logo">Logo</span>
        {/* <BillingLogo className="logo" size={45} /> */}
      </Box>
      <Divider />
      <span
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100%",
          paddingTop: 23
        }}
      >
        <span>
          {/* //Divider Navbar for mobileScreen */}
          <ListItem
            key={"Home"}
            disablePadding
            className={
              "/home" === pathname
                ? "active-master-class"
                : "nav-ListItemButton"
            }
            sx={{ display: "block" }}
            onClick={homeClick}
          >
            <ListItemButton
              sx={{
                minHeight: 24,
                justifyContent: openNew ? "initial" : "center",
                px: 1.3,
                "&:hover": {
                  backgroundColor: "#B4905D",
                  color: "#ffffff",
                },
              }}
              onClick={homeClick}
            >
              <ListItemIcon
                className="nav-ListItemButton"
                sx={{
                  minWidth: 0,
                  mr: openNew ? 3 : "auto",
                  justifyContent: "center",
                }}
                onClick={homeClick}
              >
                {openNew ? (
                  <span className="menu-icon-span">
                    <HomeOutlinedIcon className="menu-icon" />
                  </span>
                ) : (
                  <BootstrapTooltip
                    TransitionComponent={Zoom}
                    title={
                      <React.Fragment>
                        <span style={{ fontSize: "12px" }}>
                          Home
                        </span>
                      </React.Fragment>
                    }
                    placement="right"
                  >
                    <span className="menu-icon-span">
                      <HomeOutlinedIcon
                        className="menu-icon"
                      // onClick={() => {
                      //   Handlemenu(1);
                      // }}
                      />
                    </span>
                  </BootstrapTooltip>
                )}
              </ListItemIcon>
              <ListItemText
                primary={"Home"}
                onClick={homeClick}
                className="menu-item-text"
                sx={{ opacity: openNew ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem
            key={"Tag"}
            disablePadding
            sx={{ display: "block" }}
            onClick={handleClickTag}
          >
            <ListItemButton
              sx={{
                minHeight: 24,
                justifyContent: openNew ? "initial" : "center",
                px: 1.3,
                "&:hover": {
                  backgroundColor: "#B4905D",
                  color: "#ffffff",
                },
              }}
              onClick={homeClick}
            >
              <ListItemIcon
                className="nav-ListItemButton"
                sx={{
                  minWidth: 0,
                  mr: openNew ? 3 : "auto",
                  justifyContent: "center",
                }}
                onClick={homeClick}
              >
                {openNew ? (
                  <span className="menu-icon-span">
                    <LocalOfferOutlinedIcon className="menu-icon" />
                  </span>
                ) : (
                  <BootstrapTooltip
                    TransitionComponent={Zoom}
                    title={
                      <React.Fragment>
                        <span style={{ fontSize: "12px" }}>
                          Tag
                        </span>
                      </React.Fragment>
                    }
                    placement="right"
                  >
                  </BootstrapTooltip>
                )}
              </ListItemIcon>
              <ListItemText
                primary={"Tag"}
                onClick={handleClickTag}
                className="menu-item-text"
                sx={{ opacity: openNew ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          {userContext.userRole === 'admin' ? (
            <ListItem
              key={"Team"}
              disablePadding
              className="nav-ListItemButton"
              sx={{ display: "block" }}
            >
              <ListItemButton
                onClick={
                  openNew
                    ? handleClickTeam
                    : handleClickMasterNull
                }
                sx={{
                  minHeight: 24,
                  justifyContent: openNew ? "initial" : "center",
                  px: 1.3,
                  "&:hover": {
                    backgroundColor: "#B4905D",
                    color: "#ffffff",
                  },
                }}
              >
                <ListItemIcon
                  className="nav-ListItemButton"
                  onClick={handleClickTeam}
                  sx={{
                    minWidth: 0,
                    mr: openNew ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {openNew ? (
                    <span className="menu-icon-span">
                      <GroupsIcon
                        className={
                          "/staff" === pathname ||
                            "/users" === pathname
                            ? "active-master-class-icon menu-icon"
                            : "menu-icon"
                        }
                      />
                    </span>
                  ) : (
                    <BootstrapTooltip
                      TransitionComponent={Zoom}
                      title={
                        <React.Fragment>
                          <span style={{ fontSize: "12px" }}>
                            Team
                          </span>
                        </React.Fragment>
                      }
                      placement="right"
                    >
                      <span className="menu-icon-span">
                        <GroupsIcon
                          className={
                            "/staff" === pathname ||
                              "/users" === pathname
                              ? "active-master-class-icon menu-icon"
                              : "menu-icon"
                          }
                        />
                      </span>
                    </BootstrapTooltip>
                  )}
                </ListItemIcon>

                <ListItemText
                  primary="Team"
                  sx={{ opacity: openNew ? 1 : 0 }}
                  className={
                    "/staff" === pathname ||
                      "/users" === pathname
                      ? "active-master-class-icon menu-item-text"
                      : "menu-item-text"
                  }
                />
                {openPatient ? (
                  <ExpandLess
                    sx={{
                      "&:hover": {
                        backgroundColor: "#B4905D",
                        color: "#ffffff",
                      },
                    }}
                    className={
                      "/staff" === pathname ||
                        "/users" === pathname
                        ? "active-master-class-icon menu-item-icon-more"
                        : "menu-item-icon-more"
                    }
                  />
                ) : (
                  <ExpandMore
                    className={
                      "/staff" === pathname ||
                        "/users" === pathname
                        ? "active-master-class-icon menu-item-icon-more"
                        : "menu-item-icon-more"
                    }
                  />
                )}
              </ListItemButton>
              <Collapse in={openTeam} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className="">

                  <ListItemButton
                    className={
                      "/users" === pathname
                        ? "active-master-class sub-menu"
                        : "sub-menu"
                    }
                    sx={{
                      pl: 4,
                      "&:hover": {
                        backgroundColor: "#B4905D",
                        color: "#ffffff",
                      },
                    }}
                    onClick={toggleDrawer("left", false)}
                  >
                    <ListItemText primary="Users" />
                  </ListItemButton>
                  <ListItemButton
                    className={
                      "/groups" === pathname
                        ? "active-master-class sub-menu"
                        : "sub-menu"
                    }
                    sx={{
                      pl: 4,
                      "&:hover": {
                        backgroundColor: "#B4905D",
                        color: "#ffffff",
                      },
                    }}
                    onClick={toggleDrawer("left", false)}
                  >
                    <ListItemText primary="Groups" />
                  </ListItemButton>
                </List>
              </Collapse>
            </ListItem>
          ) :
            null
          }
        </span>
      </span>
    </List>
  );

  return (
    <Box>
      <AppBar position="static" enableColorOnDark className="scroll">
        <Toolbar
          sx={{
            padding: !matches ? "0px !important" : "",
            paddingLeft: "0px",
            paddingRight: "0px"
          }} >
          {matches ? (
            <>
              <AppBar
                style={{
                  paddingBottom: "0px !important",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.04)",
                }}
                position="fixed"
                open={openNew}
              >
                <CssBaseline />
                <Box>
                  <Toolbar className="new"
                    sx={{
                      backgroundColor: "#ffffff",
                      boxShadow:
                        "rgba(20, 46, 110, 0.1) 0px 1px 8px !important",
                    }}
                  >
                    <IconButton
                      color="primary"
                      aria-label="open drawer"
                      onClick={
                        toggleDrawer("left", !state.left)}
                      edge="start"
                    >
                      <MenuIcon />
                    </IconButton>
                    <Box
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{ marginRight: "50px", marginLeft: "10px" }}
                      >
                        <TiyaLogo className="logo nav-logo" size={56} />
                      </div>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                        }}
                      >
                        <Box>
                          <IconButton size="small" onClick={profieClick}>
                            <Tooltip title="Profile">
                              <Avatar
                                {...stringAvatar(
                                  userContext.firstName +
                                  " " +
                                  userContext.lastName
                                )}
                              />
                            </Tooltip>
                          </IconButton>
                        </Box>
                        <Box component="span" ref={spanRef}>
                          <Menu
                            className="main-profie-container"
                            anchorEl={anchorEl4}
                            open={open4}
                            sx={{ maxWidth: "329px !important" }}
                            onClose={() => profileClose("")}
                            MenuListProps={{
                              "aria-labelledby": "basic-button3",
                            }}
                          >
                            <Grid>
                              <>
                                <Grid className="profile-user-detail-component">
                                  <Grid className="profile-component-grid">
                                    <Grid className="avatar-upload">
                                      <Grid className="avatar-edit"></Grid>
                                      <Grid className="avatar-preview">
                                        <Typography
                                          className="avatar"
                                        />
                                        <Avatar
                                          {...stringAvatarBig(
                                            userContext.firstName +
                                            " " +
                                            userContext.lastName
                                          )}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid className="profile-name-grid">
                                    <h4 className="text-size-medium" style={{ marginBottom: "5px" }}>
                                      {userContext.firstName + " " + userContext.lastName}
                                    </h4>
                                    <Grid style={{ marginTop: "-5px", marginBottom: "10px" }}>
                                      <Typography className="text-size-small">
                                        {userContext.email}
                                      </Typography>
                                    </Grid>

                                  </Grid>
                                </Grid>
                                <Grid className="sign-out-grid">
                                  <Grid sx={{ display: "flex", justifyContent: "center" }} gap={2}>
                                    <Button
                                      className="button-border-remove"
                                      variant="outlined"
                                      size="medium"
                                      onClick={() =>
                                        profileClose("My Profile")
                                      }
                                    >
                                      My Profile
                                    </Button>
                                    <Button
                                      className="button-border-remove"
                                      variant="contained"
                                      size="medium"
                                      onClick={() => profileClose("Sign out")}
                                      startIcon={<LogoutIcon />}
                                    >
                                      Sign out
                                    </Button>
                                  </Grid>
                                </Grid>
                              </>
                            </Grid>
                          </Menu>
                        </Box>
                      </Box>
                    </Box>
                  </Toolbar>
                </Box>
              </AppBar>
              <SwipeableDrawer
                anchor="left"
                open={state["left"]}
                onClose={toggleDrawer("left", false)}
                onOpen={toggleDrawer("left", true)}
              >
                {list("left")}
              </SwipeableDrawer>
              <DrawerHeader id="drwerheader" sx={{ display: "none" }} />
              <Box sx={{ marginTop: "50px", paddingTop: "5px", paddingBottom: "5px" }}>{props.children}</Box>
            </>
          ) : (
            <>
              <Grid sx={{ width: "100%", padding: "0px !important" }}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    // minWidth: "96vw",
                    padding: "0px !important",
                    // backgroundColor: "#f1f5f8"
                  }}
                >
                  <AppBar
                    style={{
                      paddingBottom: "0px !important",
                      borderBottom: "1px solid rgba(0, 0, 0, 0.04)",
                    }}
                    position="fixed"
                    open={openNew}
                  >
                    <CssBaseline />
                    <Box>
                      <Toolbar
                        sx={{
                          backgroundColor: "#ffffff",
                          boxShadow:
                            "rgba(20, 46, 110, 0.1) 0px 1px 8px !important",
                        }}
                      >
                        <IconButton
                          className="icon-color"
                          aria-label="open drawer"
                          onClick={handleDrawerBtn}
                          edge="start"
                        >
                          {openNew ? <MenuOpenOutlinedIcon /> : <MenuIcon />}
                        </IconButton>
                        <Box
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{ marginRight: "50px", marginLeft: "10px" }}
                          >

                            <TiyaLogo className="logo nav-logo" size={56} />
                          </div>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                            }}
                          >
                            <Box>
                              <Grid onClick={profieClick} >
                                <IconButton size="small" onClick={profieClick}>
                                  <Tooltip title="Profile">
                                    <Avatar
                                      {...stringAvatar(
                                        userContext.firstName +
                                        " " +
                                        userContext.lastName
                                      )}
                                    />
                                  </Tooltip>
                                </IconButton>
                              </Grid>
                            </Box>
                            <Box component="span" ref={spanRef}>
                              <Menu
                                className="main-profie-container"
                                anchorEl={anchorEl4}
                                open={open4}
                                sx={{ maxWidth: "329px !important" }}
                                onClose={() => profileClose("")}
                                MenuListProps={{
                                  "aria-labelledby": "basic-button3",
                                }}
                              >
                                <Grid>
                                  <>
                                    <Grid className="profile-user-detail-component">
                                      <Grid className="profile-component-grid">

                                        <Grid className="avatar-upload">
                                          <Grid className="avatar-edit"></Grid>
                                          <Grid className="avatar-preview">
                                            <Typography
                                              className="avatar"
                                            />
                                            <Avatar
                                              {...stringAvatarBig(
                                                userContext.firstName +
                                                " " +
                                                userContext.lastName
                                              )}
                                            />
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid className="profile-name-grid">
                                        <h4 className="text-size-medium" style={{ marginBottom: "5px" }}>
                                          {userContext.firstName + " " + userContext.lastName}
                                        </h4>
                                        <Grid style={{ marginTop: "-5px", marginBottom: "10px" }}>
                                          <Typography className="text-size-small">
                                            {userContext.email}
                                          </Typography>
                                        </Grid>

                                      </Grid>
                                    </Grid>

                                    <Grid className="sign-out-grid">
                                      <Grid sx={{ display: "flex", justifyContent: "center" }} gap={2}>
                                        <Button
                                          className="button-border-remove"
                                          variant="outlined"
                                          size="medium"
                                          onClick={() =>
                                            profileClose("My Profile")
                                          }
                                        >
                                          My Profile
                                        </Button>
                                        <Button
                                          className="button-border-remove"
                                          variant="contained"
                                          size="medium"
                                          onClick={() => profileClose("Sign out")}
                                          startIcon={<LogoutIcon />}
                                        >
                                          Sign out
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </>
                                </Grid>
                              </Menu>
                            </Box>
                          </Box>
                        </Box>
                      </Toolbar>
                    </Box>
                  </AppBar>
                  <Drawer
                    variant="permanent"
                    open={openNew}
                    style={{ transitionDuration: "0.15s" }}>
                    <DrawerHeader />
                    <List
                      className="drawer-container"
                      sx={{
                        boxShadow:
                          "rgba(20, 46, 110, 0.1) 0px 1px 8px !important",
                        height: "100vh",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "column",
                        }}
                      >
                        {/* //Divider Navbar for Largescreen */}
                        <ListItem
                          key={"Home"}
                          disablePadding
                          className={
                            "/home" === pathname
                              ? "active-master-class"
                              : "nav-ListItemButton"
                          }
                          sx={{ display: "block" }}
                          onClick={handleClickHome}

                        >
                          <ListItemButton
                            sx={{
                              minHeight: 24,
                              justifyContent: openNew ? "initial" : "center",
                              px: 1.3,
                              "&:hover": {
                                backgroundColor: "#B4905D",
                                color: "#ffffff",
                              },
                            }}
                          >
                            <ListItemIcon
                              className="nav-ListItemButton"
                              sx={{
                                minWidth: 0,
                                mr: openNew ? 3 : "auto",
                                justifyContent: "center",
                              }}
                              onClick={handleClickHome}
                            >
                              {openNew ? (
                                <span className="menu-icon-span">
                                  <HomeOutlinedIcon className="menu-icon" />
                                </span>
                              ) : (
                                <BootstrapTooltip
                                  TransitionComponent={Zoom}
                                  title={
                                    <React.Fragment>
                                      <span style={{ fontSize: "12px" }}>
                                        Home
                                      </span>
                                    </React.Fragment>
                                  }
                                  placement="right"
                                >
                                  <span className="menu-icon-span">
                                    <HomeOutlinedIcon
                                      className="menu-icon"
                                    // onClick={() => {
                                    //   Handlemenu(1);
                                    // }}
                                    />
                                  </span>
                                </BootstrapTooltip>
                              )}
                            </ListItemIcon>
                            <ListItemText
                              primary={"Home"}
                              onClick={handleClickHome}
                              className="menu-item-text"
                              sx={{ opacity: openNew ? 1 : 0 }}
                            />
                          </ListItemButton>
                        </ListItem>

                        <ListItem
                          key={"Tag"}
                          disablePadding
                          className={
                            "/tags" === pathname
                              ? "active-master-class"
                              : "nav-ListItemButton"
                          }
                          sx={{ display: "block" }}
                          onClick={handleClickTag}

                        >
                          <ListItemButton
                            sx={{
                              minHeight: 24,
                              justifyContent: openNew ? "initial" : "center",
                              px: 1.3,
                              "&:hover": {
                                backgroundColor: "#B4905D",
                                color: "#ffffff",
                              },
                            }}
                          >
                            <ListItemIcon
                              className="nav-ListItemButton"
                              sx={{
                                minWidth: 0,
                                mr: openNew ? 3 : "auto",
                                justifyContent: "center",
                              }}
                              onClick={handleClickTag}
                            >
                              {openNew ? (
                                <span className="menu-icon-span">
                                  <LocalOfferOutlinedIcon className="menu-icon" />
                                </span>
                              ) : (
                                <BootstrapTooltip
                                  TransitionComponent={Zoom}
                                  title={
                                    <React.Fragment>
                                      <span style={{ fontSize: "12px" }}>
                                        Tag
                                      </span>
                                    </React.Fragment>
                                  }
                                  placement="right"
                                >
                                  <span className="menu-icon-span">
                                    <LocalOfferOutlinedIcon
                                      className="menu-icon"
                                    />
                                  </span>
                                </BootstrapTooltip>
                              )}
                            </ListItemIcon>
                            <ListItemText
                              primary={"Tag"}
                              onClick={handleClickTag}
                              className="menu-item-text"
                              sx={{ opacity: openNew ? 1 : 0 }}
                            />
                          </ListItemButton>
                        </ListItem>
                        {userContext.userRole === 'admin' ? (
                          <ListItem
                            key={"Team"}
                            disablePadding
                            className="nav-ListItemButton"
                            sx={{ display: "block" }}
                          >
                            <ListItemButton
                              onClick={
                                openNew
                                  ? handleClickTeam
                                  : handleClickMasterNull
                              }
                              sx={{
                                minHeight: 24,
                                justifyContent: openNew ? "initial" : "center",
                                px: 1.3,
                                "&:hover": {
                                  backgroundColor: "#B4905D",
                                  color: "#ffffff",
                                },
                              }}
                            >
                              <ListItemIcon
                                className="nav-ListItemButton"
                                onClick={handleClickTeam}
                                sx={{
                                  minWidth: 0,
                                  mr: openNew ? 3 : "auto",
                                  justifyContent: "center",
                                }}
                              >
                                {openNew ? (
                                  <span className="menu-icon-span">
                                    <GroupsIcon
                                      className={
                                        "/staff" === pathname ||
                                          "/users" === pathname
                                          ? "active-master-class-icon menu-icon"
                                          : "menu-icon"
                                      }
                                    />
                                  </span>
                                ) : (
                                  <BootstrapTooltip
                                    TransitionComponent={Zoom}
                                    title={
                                      <React.Fragment>
                                        <span style={{ fontSize: "12px" }}>
                                          Team
                                        </span>
                                      </React.Fragment>
                                    }
                                    placement="right"
                                  >
                                    <span className="menu-icon-span">
                                      <GroupsIcon
                                        className={
                                          "/staff" === pathname ||
                                            "/users" === pathname
                                            ? "active-master-class-icon menu-icon"
                                            : "menu-icon"
                                        }
                                      />
                                    </span>
                                  </BootstrapTooltip>
                                )}
                              </ListItemIcon>

                              <ListItemText
                                primary="Team"
                                sx={{ opacity: openNew ? 1 : 0 }}
                                className={
                                  "/staff" === pathname ||
                                    "/users" === pathname
                                    ? "active-master-class-icon menu-item-text"
                                    : "menu-item-text"
                                }
                              />
                              {openPatient ? (
                                <ExpandLess
                                  sx={{
                                    "&:hover": {
                                      backgroundColor: "#B4905D",
                                      color: "#ffffff",
                                    },
                                  }}
                                  className={
                                    "/staff" === pathname ||
                                      "/users" === pathname
                                      ? "active-master-class-icon menu-item-icon-more"
                                      : "menu-item-icon-more"
                                  }
                                />
                              ) : (
                                <ExpandMore
                                  className={
                                    "/staff" === pathname ||
                                      "/users" === pathname
                                      ? "active-master-class-icon menu-item-icon-more"
                                      : "menu-item-icon-more"
                                  }
                                />
                              )}
                            </ListItemButton>
                            <Collapse in={openTeam} timeout="auto" unmountOnExit>
                              <List component="div" disablePadding className="">

                                <ListItemButton
                                  className={
                                    "/users" === pathname
                                      ? "active-master-class sub-menu"
                                      : "sub-menu"
                                  }
                                  sx={{
                                    pl: 4,
                                    "&:hover": {
                                      backgroundColor: "#B4905D",
                                      color: "#ffffff",
                                    },
                                  }}
                                  onClick={toggleDrawer("left", false)}
                                >
                                  <ListItemText primary="Users" />

                                </ListItemButton>
                                <ListItemButton
                                  className={
                                    "/groups" === pathname
                                      ? "active-master-class sub-menu"
                                      : "sub-menu"
                                  }
                                  sx={{
                                    pl: 4,
                                    "&:hover": {
                                      backgroundColor: "#B4905D",
                                      color: "#ffffff",
                                    },
                                  }}
                                  onClick={toggleDrawer("left", false)}
                                >
                                  <ListItemText primary="Groups" />
                                </ListItemButton>
                              </List>
                            </Collapse>
                          </ListItem>
                        ) :
                          null
                        }
                      </span>
                    </List>

                  </Drawer>
                  <Box
                    component="main"
                    sx={{
                      flexGrow: 1,
                      minHeight: "99.8vh",
                      // paddingLeft: "40px",
                      // paddingRight: "40px",
                      position: "relative",
                    }}
                  >
                    <DrawerHeader id="drwerheader" />
                    <Box>{props.children}</Box>
                  </Box>
                </Box>
              </Grid>
            </>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
