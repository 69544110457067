import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  FormControl,
  Grid,
  TextField,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// import { format } from "date-fns";
import LoadingButton from "@mui/lab/LoadingButton";
import Title from "../../components/Title";
import * as Yup from "yup";
//import { CustomSelect } from "../../../components/CustomFields/CustomSelect";
import { Form, Formik } from "formik";
// import PhoneInput from 'react-phone-number-input'
import '../../theme/style.scss';
// import { isEmpty, reject } from "lodash";
// import BreadcrumbsComponent from '../../components/BreadcrumbsComponent/BreadcrumbsComponent';
import CustomBreadcrumbs from '../../components/BreadcrumbsComponent/CustomBreadcrumbs';
import { AlertType } from "../../services/models/AlertType";
import { PreferencesContext } from "../../PreferenceContext";
import CustomAutoFocus from "../../components/Common/CustomAutoFocus";
import { EncryptDecryptService } from "../../services/common/EncryptionService";
import { GetTagList, UpdateTag } from "../../services/TagService";
import { isElementVisible } from "../../services/common/CommonFuctions";
// import { DropZone } from "../../components/Common/DropZone";
import { CreateDocument, GetDocumentDetailById, UpdateDocument } from "../../services/FileService";
import DatePickerComponent from "../../components/Common/DatePicker";
import dayjs from "dayjs";
import { PAGE_SIZE } from "../../services/common/const";
// import { CustomSelect } from "../../components/Common/CustomSelect";
import { PopupDialog } from "../../components/Common/PopupDialog";
import TagSave from "../Tag/TagSave";
import { MultiUpload } from "../../components/Common/MultiUpload";
import { MultiSelect } from "../../components/Common/MultiSelect";
import { useContext, useEffect, useState } from "react";
import React from "react";

const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#fff", // Set the desired background color
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));
const parameters: any = {
  pageSize: PAGE_SIZE,
  sortColumn: "",
  sortDirection: "",
  totalCount: "",
  maxAllowedUsers: "",
  searchValue: "",
};

export default function HomeSave(props: any) {
  const { code, isForPopup, handleTagChange, handleCloseorOpenpopup } = props;
  let location = useLocation();

  const { isAddPopUp, parenetId } =
    (location && location.state) || props.state || {};
  let tagColl = {
    documentId: 0,
    tag_id: 0,
    tag_name: "",
    is_deleted: false
  }
  let formInitialValues = {
    documentId: 0,
    documentName: "",
    documentTypeCode: "file",
    parentId: parenetId || 0,
    registrationNumber: "",
    registrationDate: null,
    expirationDate: null,
    //tagId: null,
    refNo: "",
    fileType: "",
    fileOriginalName: "",
    fileServerName: "",
    fileView: "",
    filePath: "",
    fileSize: "",
    dateOfLetter: null,
    documentfile: [],
    subject: "",
    isDeleted: false,
    rowManagedBy: 0,
    mode: "",
    dataManagedBy: 0,
    tagId: []
  };
  // const { title, button, documentId, isAddPopUp } =
  //   (props.location && props.location.state) || props.state || {};


  // const Documentdata = {
  //   title: "Add File",
  //   button: "Save",
  //   productId: 0,
  //   documentIdForPopUp: true,
  //   taxIdForPopUp: false,
  // };
  const matches = useMediaQuery("(max-width:768px)");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isSubmitting, setSubmitting] = useState(false);
  const [documentinitial, setDocumentInitial] = useState(formInitialValues);
  // const [deletedFilesId, setDeletedFilesId] = useState<any>([]);
  // const [deletedFilesName, setDeletedFilesName] = useState<any>([]);
  const [documentsFiles, setDocumentsFile] = useState<any>([]);
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  const [isLoading, setLoading] = useState(false);
  // const [state, setState] = useState(Documentdata);
  const [open, setOpen] = useState(true);
  const { setInputRef } = CustomAutoFocus(isForPopup, true);
  const [param, setParam] = useState(parameters);
  const [page_Index, setPage_Index] = useState(1);
  const [tagCollection, setTagCollection] = useState([]);
  const [openCreateTag, setOpenCreateTag] = useState({
    value: false,
    event: {}
  });
  const [showDocument, setShowDocument] = useState("");
  const [tagcode, setTagcode] = useState("");

  const edService = new EncryptDecryptService();

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let navigate = useNavigate();
  let urlParams = useQuery();
  let curRawdocumentId = urlParams.get("q");
  let curDocumentId = 0;
  if (!isForPopup) {
    if (curRawdocumentId !== null && curRawdocumentId !== undefined) {
      let decId = edService.decrypt(curRawdocumentId);
      if (decId !== "" && decId !== null && decId !== undefined) {
        curDocumentId = +decId;
      } else {
        navigate("/not-found");
      }
    } else {
      navigate("/not-found");
    }
  }

  const [documentId, setDocumentId] = useState(curDocumentId);

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  //Get Data
  async function fetchData() {
    try {
      //setLoading(true);
      let params = new URLSearchParams();
      params.append("documentId", documentId.toString());
      const result: any = await GetDocumentDetailById(params);
      let editDetails = result.data.data;
      let updatedValue = {
        documentId: editDetails.documentId,
        documentName: editDetails.documentName,
        documentTypeCode: editDetails.documentTypeCode || "file",
        parentId: editDetails.parentId || 0,
        registrationNumber: editDetails.registrationNumber || "",
        registrationDate: editDetails.registrationDate || null,
        updateDocumentFiles: editDetails.fileView,
        documentFiles: editDetails.fileView,
        expirationDate: editDetails.expirationDate || null,
        tagId: editDetails.tagId || null,
        refNo: editDetails.refNo || "",
        fileType: editDetails.fileType || "",
        fileOriginalName: editDetails.fileOriginalName || "",
        fileServerName: editDetails.fileServerName || "",
        fileView: editDetails.fileView || "",
        filePath: editDetails.filePath || "",
        fileSize: editDetails.fileSize || "",
        dateOfLetter: editDetails.dateOfLetter || null,
        documentfile: [editDetails.fileView],
        subject: editDetails.subject || "",
        isDeleted: editDetails.isDeleted || false,
        rowManagedBy: editDetails.rowManagedBy || 0,
        mode: editDetails.mode || "",
        dataManagedBy: editDetails.dataManagedBy || 0
      };
      setDocumentInitial(updatedValue);
      if (updatedValue.fileView) {
        const fileName = updatedValue.fileOriginalName;
        const documentfile = dataURLtoFile(updatedValue.fileView, fileName);
        setShowDocument(updatedValue.fileView);
        setDocumentsFile([documentfile]);
      }
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }
  }
  const saveLogoFileSelected = (files: any) => {
    setDocumentsFile(files);
  };

  const dowloadDocumentFile = (files: { name: any; }) => {
    const selectedFile = documentsFiles.find(
      (doc: { name: any; }) => files?.name === doc?.name
    );

    if (selectedFile && selectedFile.preview) {
      const downloadLink = document.createElement("a");
      downloadLink.href = selectedFile.preview; // Changed from `documentFile` to `preview` based on your data structure
      downloadLink.download = selectedFile.name; // Changed from `fileOriginalName` to `name` based on your data structure
      downloadLink.click();
    }
  };

  const removeFile = (file: { name: any; }) => {
    const updatedFiles = documentsFiles.filter(
      (doc: { name: any; }) => doc.name !== file?.name
    );
    setDocumentsFile(updatedFiles);
    if (documentId > 0) {
      setDocumentInitial({
        ...documentinitial,
      });
    }
  };

  async function fetchTagList() {
    let tagInfo = [];
    try {
      setLoading(true);
      let params = new URLSearchParams();
      params.append("pageIndex", page_Index.toString());
      params.append("pageSize", param.pageSize);
      params.append("sortColumn", param.sortColumn);
      params.append("sortDirection", param.sortDirection);
      params.append("searchValue", param.searchValue);
      const result: any = await GetTagList(params);
      if (result && result.data.data) {
        tagInfo = result.data.data.tagCollection.map(tag => ({
          value: tag.value,
          label: tag.label,
          tagIds: tag.tagId
        }));
        setTagCollection(tagInfo);
      } else {
        console.log("Error");
      }
      setLoading(false);
      return tagInfo;
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e : e?.message,
        alertType: AlertType.Error,
      });
    }
  }
  async function SubmitDocument(value: any) {
    setSubmitting(true);
    let formData = new FormData();
    let documentData = value;
    const arr1 = value.tagId;

    let tagRecords = arr1.filter((e: any) => e.tag_id >= 0);
    formData.append("documentId", documentData?.documentId || 0);
    formData.append("documentName", documentData?.documentName || '');
    formData.append("documentTypeCode", documentData?.documentTypeCode || "file");
    formData.append("parentId", documentData?.parentId || 0);
    formData.append("registrationNumber", documentData.registrationNumber);
    formData.append("registrationDate", documentData.registrationDate || "");
    formData.append("expirationDate", documentData.expirationDate || "");
    formData.append("refNo", documentData.refNo);
    formData.append("dateOfLetter", documentData.dateOfLetter);
    formData.append("subject", documentData.subject);
    formData.append("dataManagedBy", userContext.userId.toString());
    formData.append('documentTags', tagRecords.length > 0 ? JSON.stringify(tagRecords) : "");
    console.table("documentsFiles", documentsFiles)
    documentsFiles.forEach((file) => {
      formData.append("documentfile", file);
    });

    if (documentData.documentId > 0) {
      try {
        const resultDocumentUpdate: any = await UpdateDocument(formData);
        if (resultDocumentUpdate && resultDocumentUpdate.data === true) {
          setSubmitting(false);
          updateUserContext({
            ...userContext,
            isAlert: true,
            alertMessage: resultDocumentUpdate.message,
            alertType: AlertType.Success,
          });
          if (isAddPopUp !== true) {
            navigate(-1);
          } else {
            navigate(
              0
            )
            const url = `/home?d=${encodeURIComponent(documentId)}`;
            navigate(url);
          }
        }
      } catch (e: any) {
        setSubmitting(false);
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: typeof e === "string" ? (e as string) : (e?.message as string),
          alertType: AlertType.Error,
        });
      }
    } else {
      try {
        const resultDocumentSave: any = await CreateDocument(formData);

        if (resultDocumentSave && resultDocumentSave.data === true) {
          setSubmitting(false);
          updateUserContext({
            ...userContext,
            isAlert: true,
            alertMessage: resultDocumentSave.message,
            alertType: AlertType.Success,
          });
          if (isForPopup === true) {
            handleCloseorOpenpopup(code);
          } else {
            if (isAddPopUp !== true) {
              navigate(-1);
            } else
              navigate(
                0
              )
          }
        }
      } catch (e: any) {
        setSubmitting(false);
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: typeof e === "string" ? (e as string) : (e?.message as string),
          alertType: AlertType.Error,
        });
      }
    }
  }

  const handleCreateTag = (InputValue: string) => {
    setTagcode(InputValue);
    setOpenCreateTag({ ...openCreateTag, value: true });
  };

  const handleCloseCreateTag = async (code, props: any) => {
    try {
      const result: any = await fetchTagList();
      setOpenCreateTag({ ...openCreateTag, value: false });
      if (result) {
        let tagData = result.find((s) => s.label === code);
        setTagcode(code);
        console.table("tagData", tagData)
        if (tagData) {
          const tags = props.values?.tagId || [];
          tags.push({
            documentId: (documentId),
            tag_id: tagData.tagIds, // set tag value
            tag_name: tagData.label, // set tag label
            is_deleted: false,
          })
          props?.setFieldValue(
            `tagId`, tags, true
          );
          // setDocumentInitial({ ...documentinitial, tagId: tagData.value });
          let event: any = openCreateTag.event;
          event.keyCode = 13;
          handleFocusOnKeyDown(event);
        }
      }
    } catch (e: any) {
      setOpenCreateTag({ ...openCreateTag, value: false });
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage:
          typeof e === "string" ? (e as string) : (e?.message as string),
        alertType: AlertType.Error,
      });
    }
  };

  useEffect(() => {
    if (documentId > 0) {
      fetchData();
    }
    fetchTagList();
  }, []);

  const handleFocusOnKeyDown = (event: any) => {
    if (event.keyCode === 13 && event.target.nodeName === "INPUT") {
      let form = event.target.form;
      let index = Array.prototype.indexOf.call(form, event.target);
      let Elements = form.elements;
      if (event.target.name === "registrationDate") {
        Elements[index + 3].focus();
        event.preventDefault();
      } else if (event.target.name === "expirationDate") {
        Elements[index + 3].focus();
        event.preventDefault();
      } else if (event.target.name === "DateOfLetter") {
        Elements[index + 3].focus();
        event.preventDefault();
      }
      else {
        let nextIndex = index + 1;
        while (
          (nextIndex < Elements.length &&
            !isElementVisible(Elements[nextIndex])) ||
          Elements[nextIndex]?.disabled === true ||
          Elements[nextIndex]?.tabIndex === -1
        ) {
          nextIndex++;
        }
        if (nextIndex < Elements.length) {
          Elements[nextIndex].focus();
        }
        event.preventDefault();
      }
    }
  }

  React.useEffect(() => {
    document.addEventListener("keydown", handleFocusOnKeyDown);
    return () => {
      document.removeEventListener("keydown", handleFocusOnKeyDown);
    }
  }, []);

  return (
    <>
      <Title pageTitle="Tag" />
      <Formik
        enableReinitialize
        initialValues={documentinitial}
        validateOnMount={true}
        onSubmit={(values: any) => {
          SubmitDocument(values);
        }}
        // initialTouched={{
        //   tagId: {
        //     tag_id: true
        //   }
        // }}
        validationSchema={
          Yup.object().shape({
            documentName: Yup.string().required("DocumentName is required."),
            registrationNumber: Yup.string().required("Registration Number is required."),
            //registrationDate: Yup.string().required("Registration Date is required."),
            //expirationDate: Yup.string().required("Expiration Date is required."),
            dateOfLetter: Yup.string().required("Date Of Letter is required."),
            //tagId: Yup.string().required("Tag is required."),
            // tagId: Yup.array().of(
            //   Yup.number().nullable().required("Tag is required."), 
            // ),
            documentfile: Yup.array().of(
              Yup.mixed().required("File is required"),
            ).test('length-check', 'File is required', (arr) => arr?.length > 0),
            // tagId: Yup.array().of(
            //   Yup.object().shape({
            //     //documentId: Yup.number().required("DocumentId is required"),
            //     tag_id: Yup.string().required("Tag is required"),
            //   })
            // ).test('length-check', 'Tag is required', (arr) => arr?.length > 0),
          })}
      >
        {(props) => {
          return (
            <Box className="main-container">
              <Grid item xs={12} container className="page-header-save">
                <Grid xs={12} sm={12} item className="page-breadcrumbs">
                  <CustomBreadcrumbs />
                </Grid>
              </Grid>
              <Form onSubmit={props.handleSubmit} noValidate>
                <Box className="main-container" sx={!matches ? { width: "700px !important" } : { width: "auto" }}>
                  <Grid container>
                    <Grid item xs={12} mb={3} className="group-container">
                      <Grid container item spacing={2}>
                        <Grid container item spacing={2}>
                          <Grid item xs={12}>
                            <Typography variant="h6">{documentId > 0 ? "Update File" : "Add File"}</Typography>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <TextField
                                required
                                id="documentName"
                                label="Doccument Name"
                                fullWidth
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                onChange={(e) => {
                                  props.handleChange(e);
                                }}
                                value={props.values.documentName}
                                onBlur={props.handleBlur}
                                error={
                                  props.errors.documentName && props.touched.documentName
                                    ? true
                                    : false
                                }
                              />
                              {props.errors.documentName && props.touched.documentName && (
                                <Typography
                                  className={
                                    props.errors.documentName && props.touched.documentName
                                      ? "validation-message text-input error"
                                      : "validation-message text-input"
                                  }
                                >
                                  {props.errors.documentName}
                                </Typography>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <FormControl fullWidth>
                              <TextField
                                required
                                id="registrationNumber"
                                label="Reg/Ref No"
                                fullWidth
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                onChange={(e) => {
                                  props.handleChange(e);
                                }}
                                value={props.values.registrationNumber}
                                onBlur={props.handleBlur}
                                error={
                                  props.errors.registrationNumber && props.touched.registrationNumber
                                    ? true
                                    : false
                                }
                              />
                              {props.errors.registrationNumber && props.touched.registrationNumber && (
                                <Typography
                                  className={
                                    props.errors.registrationNumber && props.touched.registrationNumber
                                      ? "validation-message text-input error"
                                      : "validation-message text-input"
                                  }
                                >
                                  {props.errors.registrationNumber}
                                </Typography>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <FormControl sx={{ width: "100% !important" }}>
                              <DatePickerComponent
                                label="Date of Letter *"
                                format="DD-MM-YYYY"
                                mode="date"
                                slotProps={{ textField: { size: "small", name: "dateOfLetter" } }}
                                value={props.values?.dateOfLetter
                                  ? dayjs(props.values.dateOfLetter)
                                  : null}
                                onChange={(date) => {
                                  if (date && date.isValid()) {
                                    const formattedDate = date.format('YYYY-MM-DD');
                                    props.setFieldValue('dateOfLetter', formattedDate, true)
                                  } else {
                                    props.setFieldValue('dateOfLetter', null, true)
                                  }
                                }}
                                isClassName={undefined}
                              />
                            </FormControl>

                            {props.errors.dateOfLetter &&
                              props.touched.dateOfLetter && (
                                <Typography
                                  className={
                                    "text-input error validation-message"
                                  }
                                >
                                  {props.errors.dateOfLetter}
                                </Typography>
                              )}
                          </Grid>
                          {/* <Grid item xs={12} sm={12} md={6}>
                            <FormControl sx={{ width: "100% !important" }}>
                              <DatePickerComponent
                                label="Registration Date *"
                                format="DD-MM-YYYY"
                                mode="date"
                                slotProps={{ textField: { size: "small", name: "registrationDate" } }}
                                value={props.values?.registrationDate
                                  ? dayjs(props.values.registrationDate)
                                  : null}
                                onChange={(date) => {
                                  if (date && date.isValid()) {
                                    const formattedDate = date.format('YYYY-MM-DD');
                                    props.setFieldValue('registrationDate', formattedDate, true)
                                  } else {
                                    props.setFieldValue('registrationDate', null, true)
                                  }
                                }}
                                isClassName={undefined}
                              />
                            </FormControl>

                            {props.errors.registrationDate &&
                              props.touched.registrationDate && (
                                <Typography
                                  className={
                                    "text-input error validation-message"
                                  }
                                >
                                  {props.errors.registrationDate}
                                </Typography>
                              )}
                          </Grid> */}
                          {/* <Grid item xs={12} sm={12} md={6}>
                            <FormControl sx={{ width: "100% !important" }}>
                              <DatePickerComponent
                                label="Expiration Date"
                                format="DD-MM-YYYY"
                                mode="date"
                                slotProps={{ textField: { size: "small", name: "expirationDate" } }}
                                value={props.values?.expirationDate
                                  ? dayjs(props.values.expirationDate)
                                  : null}
                                onChange={(date) => {
                                  if (date && date.isValid()) {
                                    const formattedDate = date.format('YYYY-MM-DD');
                                    props.setFieldValue('expirationDate', formattedDate, true)
                                  } else {
                                    props.setFieldValue('expirationDate', null, true)
                                  }
                                }}
                                isClassName={undefined}
                              />
                            </FormControl>
                          </Grid> */}
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <MultiSelect
                                id={"tagId"}
                                FieldName={"tagId"}
                                menuPortalTarget={document.querySelector("body")}
                                placeholder="Tag"
                                onCreateOption={handleCreateTag}
                                openMenuOnFocus
                                isClearable={false}
                                options={tagCollection}
                                onFocus={(e) => {
                                  setOpenCreateTag({ ...openCreateTag, event: e })
                                }}
                                // handleChange={(selectedOptions) => {
                                //   props.setFieldValue(
                                //     "tagId",
                                //     selectedOptions.map((option) => option.value) || [],
                                //     true
                                //   );
                                // }}
                                handleChange={(selectedOptions) => {
                                  const tags = selectedOptions?.map((option) => ({
                                    documentId: (documentId),
                                    tag_id: option.tagIds, // set tag value
                                    tag_name: option.label, // set tag label
                                    is_deleted: false, // by default false
                                  })) || [];
                                  props.setFieldValue("tagId", tags, true);
                                }}
                                // Value={tagCollection.filter((option: any) =>
                                //   Array.isArray(props.values.tagId) && props.values.tagId.includes(option.value)
                                // )}
                                Value={tagCollection.filter((option: any) =>
                                  Array.isArray(props.values.tagId) &&
                                  props.values.tagId.some((tag) => tag.tag_id === option.tagIds)
                                )}
                              />

                              {props.errors.tagId &&
                                props.touched.tagId && (
                                  <Typography
                                    variant="inherit"
                                    className={
                                      props.errors.tagId &&
                                        props.touched.tagId
                                        ? "validation-message text-input error"
                                        : "validation-message text-input"
                                    }
                                  >
                                    {props.errors.tagId}
                                  </Typography>
                                )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <TextField
                                id="subject"
                                label="Subject / RE"
                                fullWidth
                                size="small"
                                inputProps={{ maxLength: 100 }}
                                onChange={(e) => {
                                  props.handleChange(e);
                                }}
                                value={props.values.subject}
                                onBlur={props.handleBlur}
                                error={
                                  props.errors.subject && props.touched.subject
                                    ? true
                                    : false
                                }
                              />
                              {props.errors.subject && props.touched.subject && (
                                <Typography
                                  className={
                                    props.errors.subject && props.touched.subject
                                      ? "validation-message text-input error"
                                      : "validation-message text-input"
                                  }
                                >
                                  {props.errors.subject}
                                </Typography>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl fullWidth>
                              <Typography variant="h6">Document Upload</Typography>
                              <MultiUpload
                                onDropandSelect={(files) => {
                                  saveLogoFileSelected(files);
                                  props.setFieldValue('documentfile', files, true)
                                }}
                                editFile={documentsFiles}
                                MAX_COUNT={1}
                                onFileDownload={dowloadDocumentFile}
                                onFileRemove={(file) => {
                                  removeFile(file);
                                }}
                                removeIconRemove={(userContext.userRole === 'user' && documentId > 0) ? true : false}
                                />
                                {/* {console.log("Role",userContext.userRole + documentId)} */}
                              {props.errors.documentfile && props.touched.documentfile && (
                                <Box
                                  className={
                                    props.errors.documentfile && props.touched.documentfile
                                      ? "validation-message text-input error"
                                      : "validation-message text-input"
                                  }
                                >
                                  {props.errors.documentfile}
                                </Box>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} >
                          <Box sx={{ display: "flex", justifyContent: "center", gap: 2, paddingTop: "28px !important" }}>
                            <LoadingButton
                              color="primary"
                              tabIndex={3}
                              loadingPosition="start"
                              startIcon={<></>}
                              variant="contained"
                              disabled={isLoading}
                              type="submit"
                              className="primary-button"
                              //onClick={() => navigate("/users")}
                              name="submit"
                            >
                              {documentId > 0 ? "Update" : "Save"}
                            </LoadingButton>
                            <LoadingButton
                              tabIndex={3}
                              loadingPosition="start"
                              disabled={isLoading}
                              startIcon={<></>}
                              variant="outlined"
                              name="submit"
                              // type="submit"
                              className="secondary-button"
                              color="secondary"
                              onClick={() => navigate(-1)}
                            >
                              Cancel
                            </LoadingButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Form>
              {/* tax popup  */}
              <PopupDialog
                OnClose={() => {
                  setOpenCreateTag({ ...openCreateTag, value: false });
                }}
                Open={openCreateTag.value}
                fullScreen={fullScreen}
              >
                <TagSave
                  isForPopup={openCreateTag.value}
                  code={tagcode}
                  handleTaxChange={handleTagChange}
                  handleCloseorOpenpopup={(code) => { handleCloseCreateTag(code, props) }}
                  handleClosePopup={() => {setOpenCreateTag({ ...openCreateTag, value: false }) }}
                />
              </PopupDialog>
            </Box>
          );
        }}
      </Formik>
    </>
  );
}
