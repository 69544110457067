import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Fade, FormControl, Grid, TextField, Typography, styled, useMediaQuery, } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Title from "../../../components/Title";
import * as Yup from "yup";
import { Form, Formik } from "formik";
// import "../../../theme/style.scss";
import CustomBreadcrumbs from '../../../components/BreadcrumbsComponent/CustomBreadcrumbs';
import { AlertType } from "../../../services/models/AlertType";
import { PreferencesContext } from "../../../PreferenceContext";
import CustomAutoFocus from "../../../components/Common/CustomAutoFocus";
import { EncryptDecryptService } from "../../../services/common/EncryptionService";
import { CreateUserGroup, GetUserGroupDetailById, UpdateUserGroup } from "../../../services/UserGroupService";
import { isElementVisible } from "../../../services/common/CommonFuctions";

// const CustomTextField = styled(TextField)(({ theme }) => ({
//   backgroundColor: "#fff", // Set the desired background color
//   "& .MuiOutlinedInput-root": {
//     "& fieldset": {
//       borderColor: theme.palette.grey[300],
//       borderWidth: "1px",
//       BorderStyle: "solid",
//     },
//     "&:hover fieldset": {
//       borderColor: theme.palette.grey[500],
//       borderWidth: "1px",
//       BorderStyle: "solid",
//     },
//     "&.Mui-focused fieldset": {
//       borderWidth: "2px",
//       borderColor: theme.palette.primary.main,
//     },
//   },
// }));


export default function UserGroupSave(props: any) {
  const { code, isForPopup, handleUserGroupChange, handleCloseorOpenpopup } = props;
  let formInitialValues;
  formInitialValues = { userGroupId: 0, name: "" };

  let location = useLocation();
  // const { title, button, userGroupId, isAddPopUp } =
  //   (props.location && props.location.state) || props.state || {};
  const { isAddPopUp } =
    (location && location.state) || props.state || {};

  const Productdata = {
    title: "Add Product",
    button: "Save",
    productId: 0,
    userGroupIdForPopUp: true,
    taxIdForPopUp: false,
  };
  const matches = useMediaQuery("(max-width:599px)");
  const [isSubmitting, setSubmitting] = useState(false);
  const [userGroupinitial, setuserGroupInitial] = useState(formInitialValues);
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  const [isLoading, setLoading] = useState(false);
  const [state, setState] = useState(Productdata);
  const [open, setOpen] = useState(true);
  const { setInputRef } = CustomAutoFocus(isForPopup, true);
  const edService = new EncryptDecryptService();

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let navigate = useNavigate();
  const handleCancelUserGroup = () => {
    if (isForPopup === true) {
      handleCloseorOpenpopup(code);
    } else {
      navigate(-1);
    }
  };

  let urlParams = useQuery();
  let curRawUserGroupId = urlParams.get("q");
  let curUserGroupId = 0;
  if (!isForPopup) {
    if (curRawUserGroupId !== null && curRawUserGroupId !== undefined) {
      let decId = edService.decrypt(curRawUserGroupId);
      if (decId !== "" && decId !== null && decId !== undefined) {
        curUserGroupId = +decId;
      } else {
        navigate("/not-found");
      }
    } else {
      navigate("/not-found");
    }
  }

  const [userGroupId, setUserGroupId] = useState(curUserGroupId);

  const handleCancelUserGroupOfDialog = () => {
    navigate({
      pathname: "/groups",
    }, {
      state: {
        title: "Add UserGroup",
        button: "Save",
        productId: 0,
        userGroupIdForPopUp: false,
        taxIdForPopUp: false,
      },
    });
  };

  //Get Data
  async function fetchData() {
    try {
      //setLoading(true);
      let params = new URLSearchParams();
      params.append("userGroupId", userGroupId.toString());
      const result: any = await GetUserGroupDetailById(params);
      let editDetails = result.data.data;

      let updatedValue = {
        userGroupId: editDetails.userGroupId,
        name: editDetails.name,
      };
      //setLoading(false);
      setuserGroupInitial(updatedValue);
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }


  }

  //Submit Form (UserGroup)
  async function SubmitUserGroup(value: any) {
    setSubmitting(true);
    let userGroupData = value;
    let SaveUpdateUserGroup = {
      userGroupId: userGroupData.userGroupId,
      Name: userGroupData.name,
      dataManagedBy: userContext.userId,
    };
    if (!isForPopup && userGroupData.userGroupId > 0) {
      try {
        const resultUserGroupUpdate: any = await UpdateUserGroup(SaveUpdateUserGroup);
        if (resultUserGroupUpdate && resultUserGroupUpdate.data === true) {
          setSubmitting(false);
          updateUserContext({
            ...userContext,
            isAlert: true,
            alertMessage: resultUserGroupUpdate.message,
            alertType: AlertType.Success,
          });
          if (isAddPopUp !== true) {
            navigate(-1);
          } else {
            navigate({
              pathname: "/groups",
            }, {
              state,
            });
          }
        }
      } catch (e: any) {
        setSubmitting(false);
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: typeof e === 'string' ? e as string : e?.message as string,
          alertType: AlertType.Error,
        });
      }
    } else {
      try {
        const resultUserGroupSave: any = await CreateUserGroup(SaveUpdateUserGroup);

        if (resultUserGroupSave && resultUserGroupSave.data === true) {
          setSubmitting(false);
          updateUserContext({
            ...userContext,
            isAlert: true,
            alertMessage: resultUserGroupSave.message,
            alertType: AlertType.Success,
          });
          if (isForPopup === true) {
            handleCloseorOpenpopup(code);
          } else {
            if (isAddPopUp !== true) {
              navigate(-1);
            } else {
              navigate({
                pathname: "/groups",
              }, {
                state,
              });
            }
          }
        }
      } catch (e: any) {
        setSubmitting(false);
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: typeof e === 'string' ? e as string : e?.message as string,
          alertType: AlertType.Error,
        });
      }
    }
  }

  const handleCloseDialog = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };

  // const handleOpendialog = () => {
  //   setOpen(true);
  // }

  useEffect(() => {
    if (userGroupId > 0) {
      fetchData();
    }
  }, []);

  const handleFocusOnKeyDown = (event: any) => {
    if (event.keyCode === 13 && event.target.nodeName === "INPUT") {
      let form = event.target.form;
      let index = Array.prototype.indexOf.call(form, event.target);
      let Elements = form.elements;

      let nextIndex = index + 2;
      while ((nextIndex < Elements.length && !isElementVisible(Elements[nextIndex])) || (Elements[nextIndex]?.disabled === true)) {
        nextIndex++;
      }
      if (nextIndex < Elements.length) {
        Elements[nextIndex].focus();
      }
      event.preventDefault();
    }
  }

  React.useEffect(() => {
    document.addEventListener("keydown", handleFocusOnKeyDown);
    return () => {
      document.removeEventListener("keydown", handleFocusOnKeyDown);
    }
  }, []);

  return (
    <>
      <Title pageTitle="Unit" />
      <Formik
        enableReinitialize
        initialValues={userGroupinitial}
        initialTouched={{
          name: true,
        }}
        validateOnMount={true}
        onSubmit={(values) => {
          SubmitUserGroup(values);
        }}
        validationSchema={
          Yup.object().shape({
            // code: Yup.string().required("Code is required."),
            name: Yup.string().required("Name is required."),
          })}
      >
        {(props) => {
          return (
            <Box className="main-container">
              <Grid item xs={12} container className="page-header-save">
                <Grid xs={6} sm={6} item className="page-breadcrumbs">
                  <CustomBreadcrumbs />
                </Grid>
              </Grid>
              <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  email: '',
                }}
                // onSubmit={async (values) => {
                //   console.log("values", values)
                // }}
              >
                <Form onSubmit={props.handleSubmit} noValidate>
                  <Box className="main-container">
                    <Grid container >
                      <Grid item xs={12} mb={3} className="group-container">
                        <Grid container item spacing={2}>
                          <Grid container item spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant="h6">{userGroupId > 0 ? "Update Group" : "Add Group"}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                              <FormControl fullWidth>
                                <TextField
                                  required
                                  id="name"
                                  label="Name"
                                  fullWidth
                                  size="small"
                                  inputProps={{ maxLength: 100 }}
                                  onChange={(e) => {
                                    props.handleChange(e);
                                    if (isForPopup) {
                                      handleUserGroupChange(e.target.value);
                                    }
                                  }}
                                  value={props.values.name}
                                  onBlur={props.handleBlur}
                                  error={
                                    props.errors.name && props.touched.name
                                      ? true
                                      : false
                                  }
                                />
                                {props.errors.name && props.touched.name && (
                                  <Typography
                                    className={
                                      props.errors.name && props.touched.name
                                        ? "validation-message text-input error"
                                        : "validation-message text-input"
                                    }
                                  >
                                    {props.errors.name}
                                  </Typography>
                                )}
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} >
                          <Box sx={{ display: "flex", justifyContent: "center", gap: 2, paddingTop: "28px !important" }}>
                            <LoadingButton
                              color="primary"
                              tabIndex={3}
                              loadingPosition="start"
                              startIcon={<></>}
                              variant="contained"
                              type="submit"
                              className="primary-button"
                              //onClick={() => navigate("/users")}
                              name="submit"
                            >
                              {userGroupId > 0 ? "Update" : "Save"}
                            </LoadingButton>
                            <LoadingButton
                              tabIndex={3}
                              loadingPosition="start"
                              startIcon={<></>}
                              variant="outlined"
                              name="submit"
                              className="secondary-button"
                              color="secondary"
                              onClick={() => navigate("/groups")}
                            >
                              Cancel
                            </LoadingButton>
                          </Box>
                        </Grid>
                      </Grid>

                    </Grid>
                  </Box>
                </Form>
              </Formik>

            </Box>
          );
        }}
      </Formik>
    </>
  );
}
