import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Alert,
  useMediaQuery,
} from "@mui/material";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useEffect } from "react";
// import Title from "../../Components/Title";
import { PreferencesContext } from "../../PreferenceContext";
import { AlertType } from "../../services/models/AlertType";
import {
  isElementVisible,
} from "../../services/common/CommonFuctions";
import { CustomSelect } from "../../components/Common/CustomSelect";
import { ChangePassword, GetUserDetailById, UpdateUser } from "../../services/UserService";
import { Login } from "../../services/AuthService";
import { GetCountryRegionListForSelect } from "../../services/CountryRegionService";
import { GetCountryListForSelect } from "../../services/CountryService";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {/* <Typography>{children}</Typography> */}
          <>{children}</>
        </Box>
      )}
    </Grid>
  );
}

function TabPanelResponsive(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      role="TabPanelResponsive"
      hidden={value !== index}
      id={`simple-TabPanelResponsive-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Grid>
  );
}

function TabProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}
function TabPropsResponsive(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-TabPanelResponsive-${index}`,
  };
}
const formInitialValues = {
  userId: 0,
  aspNetUserID: "",
  // secondaryContactNumber: "",
  // branchId: 0,
  // firmId: 0,
  // consumerId: 0,
  // code: "",
  firstName: "",
  middleName: "",
  lastName: "",
  address1: "",
  address2: "",
  city: "",
  oldEmail: "",
  email: "",
  postalCode: "",
  // bankName: "",
  // bankAddress: "",
  // bankIFSCode: "",
  // accountHolderName: "",
  // accountNumber: "",
  // imageProfile: "",
  state: "",
  country: "60",
  mobileNumber: "",
  currentpassword: "",
  newpassword: "",
  confirmPassword: "",
};

export default function MyProfilePage() {
  const [isSubmitting, setSubmitting] = React.useState(false);
  const matches = useMediaQuery("(max-width:768px)");
  const [isSubmittingEmail, setSubmittingEmail] = React.useState(false);
  const [isSubmittingPassword, setSubmittingPassword] = React.useState(false);
  const [userData, setUserData] = React.useState(formInitialValues);
  const [isLoading, setLoading] = React.useState(false);
  // const [isAllowAdd, setAllowAdd] = React.useState(true);
  const [stateData, setCountryRegion] = React.useState([]);
  const [countryData, setCountry] = React.useState([]);
  const [selectcountry, setSelectcountry] = React.useState(0);
  const [securityValues, setSecurityValues] = React.useState({
    Newpassword: "",
    confirmPassword: "",
    currentpassword: "",
    showNewPassword: false,
    showConfirmPassword: false,
    showCurrentPassword: false,
  });
  const { userContext, updateUserContext } =
    React.useContext(PreferencesContext);
  const [showAlert, setAlertMessage] = React.useState({
    isSuccess: false,
    message: "",
  });
  let navigate = useNavigate();

  //--show & hide function for NewPassword--
  const handleClickShowNewPassword = () => {
    setSecurityValues({
      ...securityValues,
      showNewPassword: !securityValues.showNewPassword,
    });
  };

  //--show & hide function for CondormPassword--
  const handleClickShowConfirmPassword = () => {
    setSecurityValues({
      ...securityValues,
      showConfirmPassword: !securityValues.showConfirmPassword,
    });
  };

  const handleClickShowCurrentPassword = () => {
    setSecurityValues({
      ...securityValues,
      showCurrentPassword: !securityValues.showCurrentPassword,
    });
  };
  const [value, setValue] = React.useState(0);
  const [index, setindex] = React.useState(0);

  //Img Upload---DP
  //const [state, setstate] = React.useState("");

  //Validation

  const formValidation = Yup.object().shape({
    firstName: Yup.string().required("First Name is required."),
    lastName: Yup.string().required("Last Name is required."),
    address1: Yup.string().required("Address1 is required."),
    city: Yup.string().required("City is required."),
    state: Yup.string().required("State is required."),
    country: Yup.string().required("Country is required."),
  });

  const formValidationEmail = Yup.object().shape({
    email: Yup.string()
      .required("Email  is required.")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, {
        message: "Email is Invalid",
      }),
    mobileNumber: Yup.string().required("Primary Number is required."),
  });

  const formValidationPassword = Yup.object().shape({
    currentpassword: Yup.string()
      .required("Current Password is required.")
      .oneOf([Yup.ref("currentpassword")], "Current Password is not correct"),
    //newpassword: Yup.string().required("New Password is required."),

    newpassword: Yup.string()
      .required("New Password is required.")
      .matches(
        /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&+=]).*$/,
        "Password should contain at least 8 characters, including one capital, one small, one special letter and one number."
      ),
    confirmPassword: Yup.string()
      .required("Confirm Password is required.")
      .oneOf([Yup.ref("newpassword")], "Confirm Password doesn't match with the New Password..")
      .nullable(),
  });

  //Country Region
  async function fatchCountryRegion(countryId) {
    try {
      let params = new URLSearchParams();
      params.append("countryID", countryId);
      const countryRegionData: any = await GetCountryRegionListForSelect(
        params
      );
      if (countryRegionData) {
        let stateCollection = countryRegionData.data.data;
        setCountryRegion(stateCollection);
      }
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }
  }

  // //Fatch Country
  async function fatchCountry() {
    try {
      let params = new URLSearchParams();
      const countryData: any = await GetCountryListForSelect(params);
      if (countryData?.data?.data) {
        let countyCollection = countryData.data.data;
        setCountry(countyCollection);
      }
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }
  }

  async function fatchData() {
    try {
      let editDetails: any
      setLoading(true);
      let params = new URLSearchParams();
      params.append("userId", userContext.userId.toString());
      const result: any = await GetUserDetailById(params);
      if (result?.data?.data) {
        editDetails = result.data.data;
        let updatedValue = {
          userId: editDetails.userId,
          aspNetUserID: editDetails.aspNetUserID,
          firstName: editDetails.firstName.trim(),
          middleName: editDetails.middleName.trim(),
          lastName: editDetails.lastName.trim(),
          address1: editDetails.address1,
          address2: editDetails.address2,
          city: editDetails.city,
          postalCode: editDetails.postalCode,
          mobileNumber: editDetails.mobileNumber,
          email: editDetails.email,
          currentpassword: editDetails.currentpassword,
          oldEmail: editDetails.email,
          newpassword: editDetails.newpassword,
          confirmPassword:
            editDetails.confirmPassword === null
              ? ""
              : editDetails.confirmPassword,
          state: editDetails.stateId,
          country: editDetails.countryId,
        };
        setUserData(updatedValue);
        setLoading(false);
      } else {
        setLoading(false);
      }
      return editDetails;
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }
  }
  // TAB 1
  async function SubmitUser(value: any) {
    // setLoading(true);
    setSubmitting(true);
    let saveUpdateUser = {
      userId: userContext.userId,
      aspNetUserID: userData.aspNetUserID,
      firstName: value.firstName.trim(),
      middleName: value.middleName.trim(),
      lastName: value.lastName.trim(),
      address1: value.address1,
      address2: value.address2,
      city: value.city,
      postalCode: value.postalCode,
      email: userData.email,
      oldEmail: userData.oldEmail,
      mobileNumber: userData.mobileNumber,
      stateId: value.state,
      countryId: value.country,
    };
    if (userData.userId > 0) {
      try {
        const resultUserUpdate: any = await UpdateUser(saveUpdateUser);
        if (resultUserUpdate.data === true) {
          updateUserContext({
            ...userContext,
            firstName: value.firstName.trim(),
            lastName: value.lastName.trim(),
            isAlert: true,
            alertMessage: resultUserUpdate.message,
            alertType: AlertType.Success,
          });
          // setSubmitting(false);
          // navigate("/my-profile");
        } else {
          updateUserContext({
            ...userContext,
            firstName: value.firstName.trim(),
            lastName: value.lastName.trim(),
            isAlert: true,
            alertMessage: resultUserUpdate.message,
            alertType: AlertType.Error,
          });
        }
      } catch (e: any) {
        updateUserContext({
          ...userContext,
          firstName: value.firstName.trim(),
          lastName: value.lastName.trim(),
          isAlert: true,
          alertMessage: typeof e === 'string' ? e as string : e?.message as string,
          alertType: AlertType.Error,
        });
      }
    }
    setSubmitting(false);
    // setLoading(false);
  }

  // TAB 2

  async function SubmitUserEmail(value: any) {
    setLoading(true);
    setSubmittingEmail(true);
    let saveUpdateUser = {
      userId: userData.userId,
      aspNetUserID: userData.aspNetUserID,
      firstName: userData.firstName.trim(),
      middleName: userData.middleName.trim(),
      lastName: userData.lastName.trim(),
      address1: userData.address1,
      address2: userData.address2,
      city: userData.city,
      postalCode: userData.postalCode,
      oldEmail: userData.oldEmail,
      email: value.email,
      mobileNumber: value.mobileNumber,
      stateId: userData.state,
      countryId: userData.country,
    };

    if (userData.userId > 0) {
      const resultUserUpdate: any = await UpdateUser(saveUpdateUser);
      try {
        if (resultUserUpdate.data === true) {
          setSubmittingEmail(false);
          updateUserContext({
            ...userContext,
            isAlert: true,
            email: value.email,
            alertMessage: resultUserUpdate.message,
            alertType: AlertType.Success,
          });
          navigate("/my-profile");
        } else {
          updateUserContext({
            ...userContext,
            isAlert: true,
            email: value.email,
            alertMessage: resultUserUpdate.message,
            alertType: AlertType.Error,
          });
        }
      } catch (e: any) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          email: value.email,
          alertMessage: typeof e === 'string' ? e as string : e?.message as string,
          alertType: AlertType.Error,
        });
      }
    }
    setLoading(false);
  }

  //TAB 3

  async function SubmitUserPassword(value: any, resetForm: any) {
    setLoading(true);
    setSubmittingPassword(true);
    let saveUpdateUser = {
      userId: userContext.userId,
      aspNetUserID: userData.aspNetUserID,
      firstName: userData.firstName.trim(),
      middleName: userData.middleName.trim(),
      lastName: userData.lastName.trim(),
      address1: userData.address1,
      address2: userData.address2,
      city: userData.city,
      postalCode: userData.postalCode,
      oldEmail: userData.oldEmail,
      email: value.email,
      currentpassword: value.currentpassword,
      newpassword: value.newpassword,
      mobileNumber: userData.mobileNumber,
      stateId: userData.state,
      countryId: userData.country,
    };
    try {
      const user: any = await Login({
        Username: saveUpdateUser.email,
        Password: saveUpdateUser.currentpassword,
      });

      if (userData.userId > 0) {
        const resultUserUpdate: any = await ChangePassword(saveUpdateUser);
        try {
          if (resultUserUpdate.data === true) {
            updateUserContext({
              ...userContext,
              isAlert: true,
              alertMessage: resultUserUpdate.message,
              alertType: AlertType.Success,
            });
            setSubmittingPassword(false);
            navigate("/my-profile");
          } else {
            updateUserContext({
              ...userContext,
              isAlert: true,
              alertMessage: resultUserUpdate.message,
              alertType: AlertType.Success,
            });
            setSubmittingPassword(false);
            setUserData({
              ...userData,
              currentpassword: "",
              newpassword: "",
              confirmPassword: "",
            });
          }
        } catch (e: any) {
          updateUserContext({
            ...userContext,
            isAlert: true,
            alertMessage: typeof e === 'string' ? e as string : e?.message as string,
            alertType: AlertType.Error,
          });
        }
      }
      setLoading(false);
      resetForm();
    } catch (error) {
      setSubmittingPassword(false);
      setLoading(false);
      if (error) {
        setAlertMessage({
          isSuccess: true,
          message: "Current password is incorrect",
        });
      }
    }
    setTimeout(() => {
      setAlertMessage({
        isSuccess: false,
        message: "",
      });
    }, 5000);
  }

  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    setValue(newValue);
  };

  useEffect(() => {
    fatchData().then((data: any) => {
      if (data.countryId > 0 && value === 0) {
        fatchCountryRegion(data.countryId);
      }
    });
  }, [value]);

  useEffect(() => {
    fatchCountry();
  }, [])




  React.useEffect(() => {
    document.addEventListener("keydown", function (event: any) {
      if (event.keyCode === 13 && event.target.nodeName === "INPUT") {
        let form = event.target.form;
        let index = Array.prototype.indexOf.call(form, event.target);
        let Elements = form.elements;
        let nextIndex = index + 2;
        while (
          (nextIndex < Elements.length &&
            !isElementVisible(Elements[nextIndex])) ||
          Elements[nextIndex]?.disabled === true
        ) {
          nextIndex++;
        }
        if (nextIndex < Elements.length) {
          Elements[nextIndex].focus();
        }
        event.preventDefault();
      }
    });
  }, []);

  return (
    <>
      {/* <Title pageTitle="My profile" /> */}


      {matches ? (
        <Box>
          <Grid sx={{ borderBottom: 1, borderColor: "divider", paddingTop: "16px" }}>
            <Tabs value={value} onChange={handleChange} aria-label="Profile">
              <Tab label="Basic" {...TabPropsResponsive(0)} />
              <Tab label="Security" {...TabPropsResponsive(1)} />
              <Tab label="Change Password" {...TabPropsResponsive(2)} />
            </Tabs>
          </Grid>
          <Box
            className="boxContainer"
            sx={{
              "& > :not(style)": {
                width: "100%",
              },
              paddingTop: 1,
              margin: 2,
            }}
          >
            <Grid className="paper">
              <Paper elevation={1} className="responsive-tabs">
                <TabPanelResponsive id="tabBasicSettings" value={value} index={0} >
                  <h4 className="page-title" text-align="center">
                    Basic Settings
                  </h4>
                  <Formik
                    enableReinitialize
                    initialValues={userData}
                    onSubmit={(values) => {
                      SubmitUser(values);
                    }}
                    validationSchema={formValidation}
                  >
                    {(props) => {
                      return (
                        <Form onSubmit={props.handleSubmit} noValidate>
                          <Grid
                            container
                            sx={{ justifyContent: "space-around", marginTop: "10px" }}
                            spacing={3}
                          >
                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                <TextField
                                  size="small"
                                  required
                                  id="firstName"
                                  label="First Name"
                                  fullWidth
                                  inputProps={{ maxLength: 100 }}
                                  margin="dense"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  //onKeyPress="return AvoidSpace(firstName)"
                                  value={props.values.firstName.trim()}
                                  error={
                                    props.errors.firstName &&
                                      props.touched.firstName
                                      ? true
                                      : false
                                  }
                                  className={
                                    props.errors.firstName &&
                                      props.touched.firstName
                                      ? "text-input error"
                                      : "text-input"
                                  }
                                />

                                {props.errors.firstName &&
                                  props.touched.firstName && (
                                    <Typography
                                      className={
                                        props.errors.firstName &&
                                          props.touched.firstName
                                          ? "validation-message text-input error"
                                          : "validation-message text-input"
                                      }
                                    >
                                      {props.errors.firstName}
                                    </Typography>
                                  )}
                              </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                <TextField
                                  size="small"
                                  id="middleName"
                                  label="Middle Name"
                                  inputProps={{ maxLength: 100 }}
                                  variant="outlined"
                                  value={props.values.middleName.trim()}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                />
                              </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                <TextField
                                  size="small"
                                  required
                                  id="lastName"
                                  label="Last Name"
                                  fullWidth
                                  inputProps={{ maxLength: 100 }}
                                  margin="dense"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.lastName.trim()}
                                  error={
                                    props.errors.lastName &&
                                      props.touched.lastName
                                      ? true
                                      : false
                                  }
                                  className={
                                    props.errors.lastName &&
                                      props.touched.lastName
                                      ? "text-input error"
                                      : "text-input"
                                  }
                                />

                                {props.errors.lastName &&
                                  props.touched.lastName && (
                                    <Typography
                                      className={
                                        props.errors.lastName &&
                                          props.touched.lastName
                                          ? "validation-message text-input error"
                                          : "validation-message text-input"
                                      }
                                    >
                                      {props.errors.lastName}
                                    </Typography>
                                  )}
                              </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                <TextField
                                  size="small"
                                  required
                                  id="address1"
                                  label="Address1"
                                  fullWidth
                                  inputProps={{ maxLength: 100 }}
                                  margin="dense"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.address1}
                                  error={
                                    props.errors.address1 &&
                                      props.touched.address1
                                      ? true
                                      : false
                                  }
                                  className={
                                    props.errors.address1 &&
                                      props.touched.address1
                                      ? "text-input error"
                                      : "text-input"
                                  }
                                />
                              </FormControl>

                              {props.errors.address1 &&
                                props.touched.address1 && (
                                  <Typography
                                    className={
                                      props.errors.address1 &&
                                        props.touched.address1
                                        ? "validation-message text-input error"
                                        : "validation-message text-input"
                                    }
                                  >
                                    {props.errors.address1}
                                  </Typography>
                                )}
                            </Grid>

                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                <TextField
                                  size="small"
                                  id="address2"
                                  label="Address2"
                                  inputProps={{ maxLength: 100 }}
                                  variant="outlined"
                                  value={props.values.address2}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                />
                              </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                <TextField
                                  size="small"
                                  required
                                  id="city"
                                  label="City"
                                  fullWidth
                                  inputProps={{ maxLength: 100 }}
                                  margin="dense"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.city}
                                  error={
                                    props.errors.city && props.touched.city
                                      ? true
                                      : false
                                  }
                                  className={
                                    props.errors.city && props.touched.city
                                      ? "text-input error"
                                      : "text-input"
                                  }
                                />

                                {props.errors.city && props.touched.city && (
                                  <Typography
                                    className={
                                      props.errors.city && props.touched.city
                                        ? "validation-message text-input error"
                                        : "validation-message text-input"
                                    }
                                  >
                                    {props.errors.city}
                                  </Typography>
                                )}
                              </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                <TextField
                                  size="small"
                                  id="postalCode"
                                  label="Postal Code"
                                  variant="outlined"
                                  inputProps={{ maxLength: 100 }}
                                  value={props.values.postalCode}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                />
                              </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                <CustomSelect
                                  isNotCreatable={true}
                                  id={"country"}
                                  FieldName={"country"}
                                  options={countryData}
                                  menuPortalTarget={document.querySelector(
                                    "body"
                                  )}
                                  handleChange={(e) => {
                                    fatchCountryRegion(e?.value)
                                    props.setFieldValue(
                                      "country",
                                      e.value !== null ? e.value : "",
                                      false
                                    );
                                    props.setFieldValue(
                                      "state",
                                      "",
                                      true
                                    );
                                    props.setFieldValue(
                                      "stateId",
                                      "",
                                      false
                                    );
                                  }}
                                  Value={countryData?.find((x: any) => x?.value?.toString() === props.values.country?.toString())}
                                  isClearable
                                  isSearchable
                                  placeholder={"Country *"}
                                  className={
                                    props.values.country === "" &&
                                      props.errors.country &&
                                      props.touched.country
                                      ? "text-input error"
                                      : "text-input"
                                  }
                                />
                                {props.values.country === "" &&
                                  props.errors.country &&
                                  props.touched.country && (
                                    <Typography
                                      className={
                                        props.values.country === "" &&
                                          props.errors.country &&
                                          props.touched.country
                                          ? "validation-message text-input error"
                                          : "validation-message text-input"
                                      }
                                    >
                                      {props.errors.country}
                                    </Typography>
                                  )}
                              </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                <CustomSelect
                                  isNotCreatable={true}
                                  id={"state"}
                                  FieldName={"state"}
                                  options={stateData}
                                  isClearable
                                  menuPortalTarget={document.querySelector(
                                    "body"
                                  )}
                                  handleChange={(e) => {
                                    props.setFieldValue(
                                      "state",
                                      e.value !== null ? e.value : "",
                                      true
                                    );
                                    props.setFieldValue(
                                      "stateId",
                                      e.value !== null ? e.value : "",
                                      true
                                    );
                                  }}
                                  Value={stateData?.find((x: any) => x?.value?.toString() === props.values.state?.toString())}
                                  placeholder={"State *"}
                                  className={
                                    props.values.state === "" &&
                                      props.errors.state &&
                                      props.touched.state
                                      ? "text-input error"
                                      : "text-input"
                                  }
                                  isClearable
                                  isSearchable
                                />
                                {props.values.state === "" &&
                                  props.errors.state &&
                                  props.touched.state && (
                                    <Typography
                                      className={
                                        props.values.state === "" &&
                                          props.errors.state &&
                                          props.touched.state
                                          ? "validation-message text-input error"
                                          : "validation-message text-input"
                                      }
                                    >
                                      {props.errors.state}
                                    </Typography>
                                  )}
                              </FormControl>
                            </Grid>

                            <Grid
                              container
                              spacing={2}
                              marginTop={1}
                              marginLeft={3}
                              sx={{ justifyContent: "center" }}
                            >
                              <Grid sx={{ marginTop: "10px" }}>
                                <LoadingButton
                                  className="button-border-remove"
                                  color="primary"
                                  type="submit"
                                  //onClick={handleClick}
                                  loading={isSubmitting}
                                  // loadingPosition="start"
                                  // startIcon={<></>}
                                  variant="contained"
                                >
                                  Update
                                </LoadingButton>
                              </Grid>

                              {/* <Grid item xs={6}>
                            <Button variant="outlined">Cancel</Button>
                          </Grid> */}
                            </Grid>
                          </Grid>
                        </Form>
                      );
                    }}
                  </Formik>
                </TabPanelResponsive>

                <TabPanelResponsive id="tabSecuritySettings" value={value} index={1} >
                  <h4 className="page-title" text-align="center">
                    Security settings
                  </h4>

                  <Formik
                    enableReinitialize
                    initialValues={userData}
                    onSubmit={(values) => {
                      SubmitUserEmail(values);
                    }}
                    validationSchema={formValidationEmail}
                  >
                    {(props) => {
                      return (
                        <Form onSubmit={props.handleSubmit} noValidate>
                          <Grid
                            container
                            sx={{ justifyContent: "space-around", marginTop: "10px" }}
                            spacing={3}
                          >
                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                <TextField
                                  size="small"
                                  required
                                  id="email"
                                  label="Email"
                                  fullWidth
                                  inputProps={{ maxLength: 512 }}
                                  margin="dense"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.email}
                                  error={
                                    props.errors.email && props.touched.email
                                      ? true
                                      : false
                                  }
                                  className={
                                    props.errors.email && props.touched.email
                                      ? "text-input error"
                                      : "text-input"
                                  }
                                />
                                {props.errors.email && props.touched.email && (
                                  <Typography
                                    className={
                                      props.errors.email && props.touched.email
                                        ? "validation-message text-input error"
                                        : "validation-message text-input"
                                    }
                                  >
                                    {props.errors.email}
                                  </Typography>
                                )}
                              </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                <TextField
                                  size="small"
                                  required
                                  id="mobileNumber"
                                  label="Primary Number"
                                  fullWidth
                                  inputProps={{ maxLength: 10 }}
                                  margin="dense"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.mobileNumber}
                                  error={
                                    props.errors.mobileNumber &&
                                      props.touched.mobileNumber
                                      ? true
                                      : false
                                  }
                                  className={
                                    props.errors.mobileNumber &&
                                      props.touched.mobileNumber
                                      ? "text-input error"
                                      : "text-input"
                                  }
                                />

                                {props.errors.mobileNumber &&
                                  props.touched.mobileNumber && (
                                    <Typography
                                      className={
                                        props.errors.mobileNumber &&
                                          props.touched.mobileNumber
                                          ? "validation-message text-input error"
                                          : "validation-message text-input"
                                      }
                                    >
                                      {props.errors.mobileNumber}
                                    </Typography>
                                  )}
                              </FormControl>
                            </Grid>

                            <Grid
                              container
                              spacing={2}
                              marginTop={1}
                              marginLeft={3}
                              sx={{ justifyContent: "center" }}
                            >
                              <Grid sx={{ marginTop: "10px" }}>
                                <LoadingButton
                                  className="button-border-remove"
                                  color="primary"
                                  type="submit"
                                  loading={isSubmittingEmail}
                                  loadingPosition="start"
                                  startIcon={<></>}
                                  variant="contained"
                                >
                                  Update
                                </LoadingButton>
                              </Grid>

                            </Grid>
                          </Grid>
                        </Form>
                      );
                    }}
                  </Formik>
                </TabPanelResponsive>
                <TabPanelResponsive id="tabChangePassword" value={value} index={2}>
                  <h4 className="page-title" text-align="center">
                    Change Password
                  </h4>
                  <Formik
                    enableReinitialize
                    initialValues={userData}
                    onSubmit={(values: any, { resetForm }) => {
                      SubmitUserPassword(values, resetForm);
                    }}
                    validationSchema={formValidationPassword}
                  >
                    {(props) => {
                      return (
                        <Form onSubmit={props.handleSubmit} noValidate>
                          <Grid
                            container
                            sx={{ justifyContent: "space-around", marginTop: "10px" }}
                            spacing={3}
                          >
                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                {showAlert.isSuccess ? (
                                  <Grid marginBottom={2}>
                                    {/* <Alert>{ alertMessage: resultUserUpdate.message, alertType: AlertType.Error}</Alert> */}
                                    <Alert severity="error">
                                      {showAlert.message}
                                    </Alert>
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                <TextField
                                  size="small"
                                  id="currentpassword"
                                  label="Current Password*"
                                  name="currentpassword"
                                  fullWidth
                                  margin="dense"
                                  type={
                                    securityValues.showCurrentPassword
                                      ? "text"
                                      : "password"
                                  }
                                  value={props.values.currentpassword}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  error={
                                    props.errors.currentpassword &&
                                      props.touched.currentpassword
                                      ? true
                                      : false
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={
                                            handleClickShowCurrentPassword
                                          }
                                          edge="end"
                                        >
                                          {securityValues.showCurrentPassword ? (
                                            <VisibilityOff />
                                          ) : (
                                            <Visibility />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                {props.errors.currentpassword &&
                                  props.touched.currentpassword && (
                                    <Typography
                                      className={
                                        props.errors.currentpassword &&
                                          props.touched.currentpassword
                                          ? "validation-message text-input error"
                                          : "validation-message text-input"
                                      }
                                    >
                                      {props.errors.currentpassword}
                                    </Typography>
                                  )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                <TextField
                                  size="small"
                                  id="newpassword"
                                  label="New Password*"
                                  fullWidth
                                  name="newpassword"
                                  margin="dense"
                                  type={
                                    securityValues.showNewPassword
                                      ? "text"
                                      : "password"
                                  }
                                  value={props.values.newpassword}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  error={
                                    props.errors.newpassword &&
                                      props.touched.newpassword
                                      ? true
                                      : false
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowNewPassword}
                                          edge="end"
                                        >
                                          {securityValues.showNewPassword ? (
                                            <VisibilityOff />
                                          ) : (
                                            <Visibility />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />

                                {props.errors.newpassword &&
                                  props.touched.newpassword && (
                                    <Typography
                                      className={
                                        props.errors.newpassword &&
                                          props.touched.newpassword
                                          ? "validation-message text-input error"
                                          : "validation-message text-input"
                                      }
                                    >
                                      {props.errors.newpassword}
                                    </Typography>
                                  )}
                              </FormControl>
                            </Grid>

                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                <TextField
                                  size="small"
                                  id="confirmPassword"
                                  label="Confirm Password*"
                                  fullWidth
                                  name="confirmPassword"
                                  margin="dense"
                                  type={
                                    securityValues.showConfirmPassword
                                      ? "text"
                                      : "password"
                                  }
                                  value={props.values.confirmPassword}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  error={
                                    props.errors.confirmPassword &&
                                      props.touched.confirmPassword
                                      ? true
                                      : false
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={
                                            handleClickShowConfirmPassword
                                          }
                                          edge="end"
                                        >
                                          {securityValues.showConfirmPassword ? (
                                            <VisibilityOff />
                                          ) : (
                                            <Visibility />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                {props.errors.confirmPassword &&
                                  props.touched.confirmPassword && (
                                    <Typography
                                      className={
                                        props.errors.confirmPassword &&
                                          props.touched.confirmPassword
                                          ? "validation-message text-input error"
                                          : "validation-message text-input"
                                      }
                                    >
                                      {props.errors.confirmPassword}
                                    </Typography>
                                  )}
                              </FormControl>
                            </Grid>

                            <Grid
                              container
                              spacing={2}
                              marginTop={1}
                              marginLeft={3}
                              sx={{ justifyContent: "center" }}
                            >
                              <Grid sx={{ marginTop: "10px" }}>
                                <LoadingButton
                                  className="button-border-remove"
                                  color="primary"
                                  type="submit"
                                  loading={isSubmittingPassword}
                                  loadingPosition="start"
                                  startIcon={<></>}
                                  variant="contained"
                                >
                                  Change Password
                                </LoadingButton>
                              </Grid>
                              {/* <Grid item xs={6}>
                      <Button variant="outlined">Cancel</Button>
                    </Grid> */}
                            </Grid>
                          </Grid>
                        </Form>
                      );
                    }}
                  </Formik>
                </TabPanelResponsive>
              </Paper>
            </Grid>
          </Box>
        </Box>
      ) : (
        <Box
          className="boxContainer"
          sx={{
            "& > :not(style)": {
              width: "100%",
              height: '100vh'
            },
          }}
        >

          <Box
            sx={{
              flexGrow: 1,
              // bgcolor: "background.paper",
              display: "flex",
              width: "100%",
              //borderRadius: 5,
              backgroundColor: "#fff !important"
            }}
          >
            <Tabs
              orientation="vertical"
              // variant="scrollable"
              sx={{
                // height: "93vh",
                overflow: "hidden",
                position: "relative",
                borderRight: 1,
                borderColor: "divider",
                textTransform: "capitalize",
                alignItems: "flex-start",
              }}
              value={value}
              onChange={handleChange}
              className="vertical-tabs"
            >
              <Tab
                sx={{ textTransform: "capitalize", fontSize: 15, alignItems: "start" }}
                label="Basic Settings"
                {...TabProps(0)}
              />
              <Tab
                sx={{ textTransform: "capitalize", fontSize: 15, alignItems: "start" }}
                label="Security settings"
                {...TabProps(1)}
              />
              <Tab
                sx={{ textTransform: "capitalize", fontSize: 15, alignItems: "start" }}
                label="Change Password"
                {...TabProps(2)}
              />
            </Tabs>
            <Box className="paper" width={'100%'}>
              <TabPanel id="tabBasicSettings" value={value} index={0}>
                <Paper elevation={1} className="responsive-tabs " >
                  <h4 className="page-title" text-align="center">
                    Basic Settings
                  </h4>
                  <Formik
                    enableReinitialize
                    initialValues={userData}
                    onSubmit={(values) => {
                      SubmitUser(values);
                    }}
                    validationSchema={formValidation}
                  >
                    {(props) => {
                      return (
                        <Form onSubmit={props.handleSubmit} noValidate>
                          <Grid container spacing={3} marginTop={3}>
                            {/* <Grid item xs={12}>
                              <FormControl fullWidth>
                                <TextField
                                  size="small"
                                  id="code"
                                  label="Code"
                                  inputProps={{ maxLength: 50 }}
                                  variant="outlined"
                                  value={props.values.code}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                />
                              </FormControl>
                            </Grid> */}
                            <Grid item xs={12} sm={6} md={6}>
                              <FormControl fullWidth>
                                <TextField
                                  size="small"
                                  required
                                  id="firstName"
                                  label="First Name"
                                  fullWidth
                                  inputProps={{ maxLength: 100 }}
                                  margin="dense"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  //onKeyPress="return AvoidSpace(firstName)"
                                  value={props.values.firstName.trim()}
                                  error={
                                    props.errors.firstName &&
                                      props.touched.firstName
                                      ? true
                                      : false
                                  }
                                  className={
                                    props.errors.firstName &&
                                      props.touched.firstName
                                      ? "text-input error"
                                      : "text-input"
                                  }
                                />

                                {props.errors.firstName &&
                                  props.touched.firstName && (
                                    <Typography
                                      className={
                                        props.errors.firstName &&
                                          props.touched.firstName
                                          ? "validation-message text-input error"
                                          : "validation-message text-input"
                                      }
                                    >
                                      {props.errors.firstName}
                                    </Typography>
                                  )}
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                              <FormControl fullWidth>
                                <TextField
                                  size="small"
                                  id="middleName"
                                  label="Middle Name"
                                  margin="dense"
                                  inputProps={{ maxLength: 100 }}
                                  variant="outlined"
                                  value={props.values.middleName.trim()}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                />
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                              <FormControl fullWidth>
                                <TextField
                                  size="small"
                                  required
                                  id="lastName"
                                  label="Last Name"
                                  fullWidth
                                  inputProps={{ maxLength: 100 }}
                                  margin="dense"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.lastName.trim()}
                                  error={
                                    props.errors.lastName &&
                                      props.touched.lastName
                                      ? true
                                      : false
                                  }
                                  className={
                                    props.errors.lastName &&
                                      props.touched.lastName
                                      ? "text-input error"
                                      : "text-input"
                                  }
                                />

                                {props.errors.lastName &&
                                  props.touched.lastName && (
                                    <Typography
                                      className={
                                        props.errors.lastName &&
                                          props.touched.lastName
                                          ? "validation-message text-input error"
                                          : "validation-message text-input"
                                      }
                                    >
                                      {props.errors.lastName}
                                    </Typography>
                                  )}
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                              <FormControl fullWidth>
                                <TextField
                                  size="small"
                                  required
                                  id="address1"
                                  label="Address1"
                                  fullWidth
                                  inputProps={{ maxLength: 100 }}
                                  margin="dense"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.address1}
                                  error={
                                    props.errors.address1 &&
                                      props.touched.address1
                                      ? true
                                      : false
                                  }
                                  className={
                                    props.errors.address1 &&
                                      props.touched.address1
                                      ? "text-input error"
                                      : "text-input"
                                  }
                                />
                              </FormControl>

                              {props.errors.address1 &&
                                props.touched.address1 && (
                                  <Typography
                                    className={
                                      props.errors.address1 &&
                                        props.touched.address1
                                        ? "validation-message text-input error"
                                        : "validation-message text-input"
                                    }
                                  >
                                    {props.errors.address1}
                                  </Typography>
                                )}
                            </Grid>

                            <Grid item xs={12} sm={6} md={6}>
                              <FormControl fullWidth>
                                <TextField
                                  size="small"
                                  id="address2"
                                  label="Address2"
                                  inputProps={{ maxLength: 100 }}
                                  variant="outlined"
                                  margin="dense"
                                  value={props.values.address2}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                />
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <FormControl fullWidth>
                                <TextField
                                  size="small"
                                  required
                                  id="city"
                                  label="City"
                                  fullWidth
                                  inputProps={{ maxLength: 100 }}
                                  margin="dense"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.city}
                                  error={
                                    props.errors.city && props.touched.city
                                      ? true
                                      : false
                                  }
                                  className={
                                    props.errors.city && props.touched.city
                                      ? "text-input error"
                                      : "text-input"
                                  }
                                />

                                {props.errors.city && props.touched.city && (
                                  <Typography
                                    className={
                                      props.errors.city && props.touched.city
                                        ? "validation-message text-input error"
                                        : "validation-message text-input"
                                    }
                                  >
                                    {props.errors.city}
                                  </Typography>
                                )}
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <FormControl fullWidth>
                                <TextField
                                  size="small"
                                  id="postalCode"
                                  label="Postal Code"
                                  variant="outlined"
                                  margin="dense"
                                  inputProps={{ maxLength: 100 }}
                                  value={props.values.postalCode}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                />
                              </FormControl>
                            </Grid>
                            {/* <Grid item xs={12}>
                          <FormControl fullWidth>
                            <Autocomplete
                              id="state-select-demo"
                              options={states}
                              autoHighlight
                              getOptionLabel={(option) => option.label}
                              onChange={(e, value) => {
                                props.setFieldValue(
                                  "state",
                                  value !== null ? value : "",
                                  false
                                );
                              }}
                              value={props.values.state}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                  {...props}
                                >
                                  {option.label} ({option.code})
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField size="small"
                                  {...params}
                                  label="Choose a State"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password", // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <Autocomplete
                              id="country-select-demo"
                              options={countries}
                              autoHighlight
                              getOptionLabel={(option) => option.label}
                              onChange={(e, value) => {
                                props.setFieldValue(
                                  "country",
                                  value !== null ? value : "",
                                  false
                                );
                              }}
                              value={props.values.country}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                  {...props}
                                >
                                  <img
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                    alt=""
                                  />
                                  {option.label} ({option.code}) +{option.phone}
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField size="small"
                                  {...params}
                                  label="Choose a Country"
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "new-password", // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid> */}




                            <Grid item xs={12} md={6}>
                              <FormControl fullWidth margin="dense">
                                <CustomSelect
                                  isNotCreatable={true}
                                  id={"country"}
                                  FieldName={"country"}
                                  options={countryData}
                                  menuPortalTarget={document.querySelector(
                                    "body"
                                  )}
                                  handleChange={(e) => {
                                    fatchCountryRegion(e?.value)
                                    props.setFieldValue(
                                      "country",
                                      e.value !== null ? e.value : "",
                                      false
                                    );
                                    props.setFieldValue(
                                      "state",
                                      "",
                                      true
                                    );
                                    props.setFieldValue(
                                      "stateId",
                                      "",
                                      false
                                    );
                                  }}
                                  Value={countryData?.find((x: any) => x?.value?.toString() === props.values.country?.toString())}
                                  isClearable
                                  isSearchable
                                  placeholder={"Country *"}
                                  className={
                                    props.values.country === "" &&
                                      props.errors.country &&
                                      props.touched.country
                                      ? "text-input error"
                                      : "text-input"
                                  }
                                />
                                {props.values.country === "" &&
                                  props.errors.country &&
                                  props.touched.country && (
                                    <Typography
                                      className={
                                        props.values.country === "" &&
                                          props.errors.country &&
                                          props.touched.country
                                          ? "validation-message text-input error"
                                          : "validation-message text-input"
                                      }
                                    >
                                      {props.errors.country}
                                    </Typography>
                                  )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                              <FormControl fullWidth margin="dense">
                                <CustomSelect
                                  isNotCreatable={true}
                                  id={"state"}
                                  FieldName={"state"}
                                  options={stateData}
                                  isClearable
                                  menuPortalTarget={document.querySelector(
                                    "body"
                                  )}
                                  handleChange={(e) => {
                                    props.setFieldValue(
                                      "state",
                                      e.value !== null ? e.value : "",
                                      true
                                    );
                                    props.setFieldValue(
                                      "stateId",
                                      e.value !== null ? e.value : "",
                                      true
                                    );
                                  }}
                                  Value={stateData?.find((x: any) => x?.value?.toString() === props.values.state?.toString())}
                                  placeholder={"State *"}
                                  className={
                                    props.values.state === "" &&
                                      props.errors.state &&
                                      props.touched.state
                                      ? "text-input error"
                                      : "text-input"
                                  }
                                  isClearable
                                  isSearchable
                                />
                                {props.values.state === "" &&
                                  props.errors.state &&
                                  props.touched.state && (
                                    <Typography
                                      className={
                                        props.values.state === "" &&
                                          props.errors.state &&
                                          props.touched.state
                                          ? "validation-message text-input error"
                                          : "validation-message text-input"
                                      }
                                    >
                                      {props.errors.state}
                                    </Typography>
                                  )}
                              </FormControl>
                            </Grid>


                            <Grid
                              container
                              spacing={2}
                              marginTop={1}
                              marginLeft={3}
                            >
                              <Grid item xs={6} textAlign="right">
                                <LoadingButton
                                  className="button-border-remove"
                                  color="primary"
                                  type="submit"
                                  //onClick={handleClick}
                                  loading={isSubmitting}
                                  // loadingPosition="start"
                                  // startIcon={<></>}
                                  variant="contained"
                                >
                                  Update
                                </LoadingButton>
                              </Grid>

                              {/* <Grid item xs={6}>
                            <Button variant="outlined">Cancel</Button>
                          </Grid> */}
                            </Grid>
                          </Grid>
                        </Form>
                      );
                    }}
                  </Formik>
                </Paper>
              </TabPanel>


              <TabPanel id="tabSecuritySettings" value={value} index={1}>
                <Paper elevation={1}  >
                  <h4 className="page-title" text-align="center">
                    Security settings
                  </h4>
                  <Formik
                    enableReinitialize
                    initialValues={userData}
                    onSubmit={(values) => {
                      SubmitUserEmail(values);
                    }}
                    validationSchema={formValidationEmail}
                  >
                    {(props) => {
                      return (
                        <Form onSubmit={props.handleSubmit} noValidate>
                          <Grid container spacing={3} marginTop={3}>
                            <Grid item xs={12} md={6}>
                              <FormControl fullWidth>
                                <TextField
                                  size="small"
                                  required
                                  id="email"
                                  label="Email"
                                  fullWidth
                                  inputProps={{ maxLength: 512 }}
                                  margin="dense"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.email}
                                  error={
                                    props.errors.email && props.touched.email
                                      ? true
                                      : false
                                  }
                                  className={
                                    props.errors.email && props.touched.email
                                      ? "text-input error"
                                      : "text-input"
                                  }
                                />

                                {props.errors.email && props.touched.email && (
                                  <Typography
                                    className={
                                      props.errors.email && props.touched.email
                                        ? "validation-message text-input error"
                                        : "validation-message text-input"
                                    }
                                  >
                                    {props.errors.email}
                                  </Typography>
                                )}
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6} />
                            <Grid item xs={12} md={6}>
                              <FormControl fullWidth>
                                <TextField
                                  size="small"
                                  required
                                  id="mobileNumber"
                                  label="Primary Number"
                                  fullWidth
                                  inputProps={{ maxLength: 10 }}
                                  margin="dense"
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  value={props.values.mobileNumber}
                                  error={
                                    props.errors.mobileNumber &&
                                      props.touched.mobileNumber
                                      ? true
                                      : false
                                  }
                                  className={
                                    props.errors.mobileNumber &&
                                      props.touched.mobileNumber
                                      ? "text-input error"
                                      : "text-input"
                                  }
                                />

                                {props.errors.mobileNumber &&
                                  props.touched.mobileNumber && (
                                    <Typography
                                      className={
                                        props.errors.mobileNumber &&
                                          props.touched.mobileNumber
                                          ? "validation-message text-input error"
                                          : "validation-message text-input"
                                      }
                                    >
                                      {props.errors.mobileNumber}
                                    </Typography>
                                  )}
                              </FormControl>
                            </Grid>

                            <Grid
                              container
                              spacing={2}
                              marginTop={1}
                              marginLeft={3}
                            >
                              <Grid item xs={6} textAlign="center">
                                <LoadingButton
                                  className="button-border-remove"
                                  color="primary"
                                  type="submit"
                                  loading={isSubmittingEmail}
                                  // loadingPosition="start"
                                  // startIcon={<></>}
                                  variant="contained"
                                >
                                  Update
                                </LoadingButton>
                              </Grid>
                              {/* <Grid item xs={6}>
                      <Button variant="outlined">Cancel`</Button>
                    </Grid> */}
                            </Grid>
                          </Grid>
                        </Form>
                      );
                    }}
                  </Formik>

                </Paper>
              </TabPanel>

              <TabPanel id="tabChangePassword" value={value} index={2}>
                <Paper elevation={1} className="responsive-tabs" >
                  <h4 className="page-title" text-align="center">
                    Change Password
                  </h4>

                  <Formik
                    enableReinitialize
                    initialValues={userData}
                    onSubmit={(values: any, { resetForm }) => {
                      SubmitUserPassword(values, resetForm);
                    }}
                    validationSchema={formValidationPassword}
                  >
                    {(props) => {
                      return (
                        <Form onSubmit={props.handleSubmit} noValidate>
                          <Grid container spacing={3} marginTop={3}>
                            <Grid item xs={12} md={6}>
                              <FormControl fullWidth>
                                {showAlert.isSuccess ? (
                                  <Grid marginBottom={2}>
                                    {/* <Alert>{ alertMessage: resultUserUpdate.message, alertType: AlertType.Error}</Alert> */}
                                    <Alert severity="error">
                                      {showAlert.message}
                                    </Alert>
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                <TextField
                                  size="small"
                                  id="currentpassword"
                                  label="Current Password*"
                                  fullWidth
                                  margin="dense"
                                  type={
                                    securityValues.showCurrentPassword
                                      ? "text"
                                      : "password"
                                  }
                                  value={props.values.currentpassword}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  error={
                                    props.errors.currentpassword &&
                                      props.touched.currentpassword
                                      ? true
                                      : false
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={
                                            handleClickShowCurrentPassword
                                          }
                                          edge="end"
                                        >
                                          {securityValues.showCurrentPassword ? (
                                            <VisibilityOff />
                                          ) : (
                                            <Visibility />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />

                                {props.errors.currentpassword &&
                                  props.touched.currentpassword && (
                                    <Typography
                                      className={
                                        props.errors.currentpassword &&
                                          props.touched.currentpassword
                                          ? "validation-message text-input error"
                                          : "validation-message text-input"
                                      }
                                    >
                                      {props.errors.currentpassword}
                                    </Typography>
                                  )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6} />
                            <Grid item xs={12} md={6}>
                              <FormControl fullWidth>
                                <TextField
                                  size="small"
                                  id="newpassword"
                                  label="New Password*"
                                  fullWidth
                                  margin="dense"
                                  type={
                                    securityValues.showNewPassword
                                      ? "text"
                                      : "password"
                                  }
                                  value={props.values.newpassword}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  error={
                                    props.errors.newpassword &&
                                      props.touched.newpassword
                                      ? true
                                      : false
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowNewPassword}
                                          edge="end"
                                        >
                                          {securityValues.showNewPassword ? (
                                            <VisibilityOff />
                                          ) : (
                                            <Visibility />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />

                                {props.errors.newpassword &&
                                  props.touched.newpassword && (
                                    <Typography
                                      className={
                                        props.errors.newpassword &&
                                          props.touched.newpassword
                                          ? "validation-message text-input error"
                                          : "validation-message text-input"
                                      }
                                    >
                                      {props.errors.newpassword}
                                    </Typography>
                                  )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6} />
                            <Grid item xs={12} md={6}>
                              <FormControl fullWidth>
                                <TextField
                                  size="small"
                                  id="confirmPassword"
                                  label="Confirm Password*"
                                  fullWidth
                                  margin="dense"
                                  type={
                                    securityValues.showConfirmPassword
                                      ? "text"
                                      : "password"
                                  }
                                  value={props.values.confirmPassword}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  error={
                                    props.errors.confirmPassword &&
                                      props.touched.confirmPassword
                                      ? true
                                      : false
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={
                                            handleClickShowConfirmPassword
                                          }
                                          edge="end"
                                        >
                                          {securityValues.showConfirmPassword ? (
                                            <VisibilityOff />
                                          ) : (
                                            <Visibility />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                {props.errors.confirmPassword &&
                                  props.touched.confirmPassword && (
                                    <Typography
                                      className={
                                        props.errors.confirmPassword &&
                                          props.touched.confirmPassword
                                          ? "validation-message text-input error"
                                          : "validation-message text-input"
                                      }
                                    >
                                      {props.errors.confirmPassword}
                                    </Typography>
                                  )}
                              </FormControl>
                            </Grid>

                            <Grid
                              container
                              spacing={2}
                              marginTop={1}
                              marginLeft={3}
                            >
                              <Grid item xs={6} textAlign="center">
                                <LoadingButton
                                  className="button-border-remove"
                                  color="primary"
                                  type="submit"
                                  loading={isSubmittingPassword}
                                  //loadingPosition="start"
                                  startIcon={<></>}
                                  variant="contained"
                                >
                                  Change Password
                                </LoadingButton>
                              </Grid>
                              {/* <Grid item xs={6}>
                      <Button variant="outlined">Cancel</Button>
                    </Grid> */}
                            </Grid>
                          </Grid>
                        </Form>
                      );
                    }}
                  </Formik>
                </Paper>
              </TabPanel>
            </Box>
          </Box>

        </Box>
      )}

    </>
  );
}