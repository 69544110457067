import React from 'react'
import NavBarDrawer from '../Drawer/NavBarDrawer'
import { Outlet } from 'react-router-dom'

export default function NavBar(props: { children: React.ReactNode }) {
    return (
        <>
            <NavBarDrawer children={props.children} />
        </>
    )
}


