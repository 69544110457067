// import { RefreshToken } from "../AuthService";
import { ContextModel } from "../models/Context";
import { EncryptDecryptService } from "./EncryptionService";

const STORAGE_KEY = "td_1";
const VERSION_KEY = "tdv";

function isTokenExpired(token: string, tokenExpiryTime: string) {
  if (token === "" && tokenExpiryTime === "") {
    return true;
  }
  else {
    let expiryTime: Date = new Date(tokenExpiryTime);
    let nowDate: Date = new Date(new Date().toUTCString());
    let pendingSeconds: number = (+expiryTime - +nowDate) / 1000;

    // if (pendingSeconds < 300) {
    //   return true;
    // }
    // else {
    //   return false;
    // }
    if (pendingSeconds < 10) {
      return true;
    }
    else {
      return false;
    }
  }

}


// async function refreshAuthToken(context: ContextModel) {
//   try {
//     const user: any = await RefreshToken({
//       Username: context.email,
//       Password: ""
//     });

//     let curuserInfo: ContextModel = new ContextModel();
//     curuserInfo.token = user.data.token;
//     curuserInfo.tokenExpiryTime = user.data.tokenExpiryTime;
//     curuserInfo.userId = user.data.userId;
//     curuserInfo.userKey = user.data.userKey;
//     curuserInfo.userRole = user.data.roles[0].toLowerCase();
//     curuserInfo.firstName = user.data.firstName;
//     curuserInfo.lastName = user.data.lastName;
//     curuserInfo.fullName = user.data.fullName;
//     curuserInfo.email = user.data.email;
//     curuserInfo.isMenuVisible = true;
//     curuserInfo.isLoadding = true;
//     curuserInfo.stateId = user.data.countryRegionId;
//     curuserInfo.isFatchinToken = false;
//     setStorage(curuserInfo);
//     return curuserInfo;

//   } catch (error) {
//     if (error) {
//       console.log(error);
//     }
//     return null;
//   }
// }

// async function getVerifiedStorageInfo(context: ContextModel) {
//   try {
//     if (context && context.token && context.token !== "" && context.tokenExpiryTime !== "") {
//       if (!isTokenExpired(context.token, context.tokenExpiryTime)) {
//         return context;
//       } else {
//         let response = await refreshAuthToken(context);
//         if (response) {
//           setStorage(response);
//         } else {
//           response = new ContextModel();
//         }
//         return response;
//       }
//     }
//   } catch (e: any) {
//     console.log(e)
//     // updateUserContext({
//     //   ...userContext,
//     //   isAlert: true,
//     //   alertMessage: typeof e === 'string' ? e as string : e?.message as string,
//     //   alertType: AlertType.Error,
//     // });
//   }
// }



function storeDataToStorage(credentials: ContextModel) {
  try {
    if (!(credentials && credentials.token)) {
      credentials = new ContextModel();
    }

    //remove existing
    localStorage.removeItem(STORAGE_KEY);

    const edService = new EncryptDecryptService();

    //  // Save to local storage
    const A = edService.encrypt(JSON.stringify(credentials))!!.toString()

    //No enc
    // const A = JSON.stringify(credentials)!!.toString();

    //add new
    localStorage.setItem(STORAGE_KEY, A);


  } catch (error) {
    // Error saving data
    console.log(error);
  }
}

const readDataFromStorage = () => {
  try {
    let credentials = localStorage.getItem(STORAGE_KEY);

    let storage_value: any = null;

    //No enc
    //storage_value = credentials;

    //Encryption
    const edService = new EncryptDecryptService();

    const d = edService.decrypt(credentials)
    // let storage_value;
    if (d != null) {
      storage_value = d;
    } else {

    }

    let context: ContextModel = (storage_value) ? JSON.parse(storage_value) : null;

    if (context != null) {
      if (context && !context.isFatchinToken && context.token !== "" && context.tokenExpiryTime !== "") {
        if (isTokenExpired(context.token, context.tokenExpiryTime)) {
          context.isUnauthorized = true;
          setStorage(context);
        }
      }
      return context
      //return cred
    } else {
      return new ContextModel();
    }
  } catch (error) {
    console.log(error)
  }

  return new ContextModel();
}


export const getStorage = () => {
  return readDataFromStorage();
}

export function setStorage(credentials: ContextModel) {
  return storeDataToStorage(credentials);
}

export const getVersion = () => {
  return localStorage.getItem(VERSION_KEY);;
}

export function setVersion(version: string) {
  return localStorage.setItem(VERSION_KEY, version);;
}

function updateUserContext(arg0: any) {
  throw new Error("Function not implemented.");
}
