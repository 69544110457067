import { Dialog, DialogContent } from '@mui/material';
import React from 'react'

export const PopupDialog = ({ fullScreen, Open, OnClose, children }) => {
    return (
        <Dialog
            fullScreen={fullScreen}
            classes={{ paperFullScreen: "prePrint printDialog" }}
            open={Open}
            onClose={(event, reason) => {
                if (reason && reason == "backdropClick") {
                    return;
                } else {
                    OnClose(false)
                }
            }}
            maxWidth="xl"
            sx={{ padding: "0px !important" }}
        >
            <DialogContent sx={{ padding: '0px !important' }}>
                {children}
            </DialogContent>
        </Dialog>
    )
}
