import { useNavigate } from "react-router-dom";
import * as React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Button
} from "@mui/material";
import TiyaDocsLogo from "../../components/TiyaDocsLogo";
import NotFoundImg from "../../assets/images/not-found.png";
import '../../theme/style.scss';
export default function NotFound(props: any) {
  let navigate = useNavigate();
  const onClickReturn = () => {
    navigate("/");
  };

  return (
    <Box sx={{ height: '100%' }}>
      <Box sx={{ flexGrow: 1, height: '100%' }}>
        <AppBar position="static" className="app-bar" enableColorOnDark>
          <Toolbar>
            <div>
              <TiyaDocsLogo className="logo nav-logo" size={30} />
            </div>
          </Toolbar>

        </AppBar>
        <p className="page-not-found">Page Not Found</p>
        <Box sx={{ display: 'flex', justifyContent: 'center', height: '90% ' }}>
          <Box sx={{  height: '100%',display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <img src={NotFoundImg} style={{ height: '80%', width: '100%' }} alt="Not Found Img"></img>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <Button
                className="button-border-remove"
                color="primary"
                variant="contained"
                onClick={onClickReturn}
              >
                Back to Home
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

    </Box>
  );
}
