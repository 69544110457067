export const displayNameMapping: { [key: string]: string } = {
    '': 'Sign In',
    'forgot-password': 'Forgot Password',
    'reset-password': 'Reset Password',
    'manage-user':'Manage User',
    'home': 'Home',
    'users': 'Users',
    'group-save': 'Group Save',
    'groups': 'Groups',
    'users-save': 'Users Save',
    'tags': 'Tags',
    'tag-save': 'Tag Save',
    'home-save':'File Save'
};
