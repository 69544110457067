import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../theme/style.scss";
import { Box, FormControl, Grid, TextField, Typography, styled } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Form, Formik } from "formik";
import 'react-phone-number-input/style.css';
import * as Yup from "yup";
import CustomBreadcrumbs from "../../../components/BreadcrumbsComponent/CustomBreadcrumbs";
import { CustomSelect } from "../../../components/Common/CustomSelect";
import { EncryptDecryptService } from "../../../services/common/EncryptionService";
import { GetCountryRegionListForSelect } from "../../../services/CountryRegionService";
import { GetCountryListForSelect } from "../../../services/CountryService";
import { PreferencesContext } from "../../../PreferenceContext";
import { AlertType } from "../../../services/models/AlertType";
import { GetUserDetail, UpdateUser } from "../../../services/UserService";
import { GetUserGroupList } from "../../../services/UserGroupService";
import { PAGE_SIZE } from "../../../services/common/const";
import { MultiSelect } from "../../../components/Common/MultiSelect";

const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#fff", // Set the desired background color
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));
const formInitialValues = {
  userId: 0,
  userGroupIds: 0,
  aspNetUserID: "",
  firstName: "",
  middleName: "",
  lastName: "",
  address1: "",
  address2: "",
  city: "",
  oldEmail: "",
  email: "",
  postalCode: "",
  mobileNumber: "",
  currentpassword: "",
  newpassword: "",
  confirmPassword: "",
  state: "",
  country: "60",
};
const formValidation = Yup.object().shape({
  firstName: Yup.string().required("First Name is required."),
  lastName: Yup.string().required("Last Name is required."),
  address1: Yup.string().required("Address1 is required."),
  city: Yup.string().required("City is required."),
  state: Yup.string().required("State is required."),
  country: Yup.string().required("Country is required."),
});
const parameters: any = {
  pageSize: PAGE_SIZE,
  sortColumn: "",
  sortDirection: "",
  totalCount: "",
  maxAllowedUsers: "",
  searchValue: "",
};
export default function UsersSave() {
  const navigate = useNavigate();
  const [value, setValue] = useState('');
  const [stateData, setCountryRegion] = React.useState([]);
  const [countryData, setCountry] = React.useState([]);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [userData, setUserData] = React.useState(formInitialValues);
  const [isLoading, setLoading] = React.useState(false);
  const [page_Index, setPage_Index] = useState(1);
  const [param, setParam] = useState(parameters);
  const [selectedRole, setselectedRole] = useState("User");
  const [UserGroupCollection, setUserGroupCollection] = useState([]);
  const edService = new EncryptDecryptService();
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let urlParams = useQuery();
  let curRawUserId = urlParams.get("q");
  // let curType = urlParams.get("t");
  let curUserId = 0;
  if (curRawUserId !== null && curRawUserId !== undefined) {
    let decId = edService.decrypt(curRawUserId);
    if (decId !== "" && decId !== null && decId !== undefined) {
      curUserId = +decId;
    } else {
      navigate("/not-found");
    }
  } else {
    navigate("/not-found");
  }
  const [userId, setUserId] = React.useState(curUserId);
  const { userContext, updateUserContext } = React.useContext(PreferencesContext);


  async function fatchData() {
    try {
      setLoading(true);
      let params = new URLSearchParams();
      params.append("userId", userId.toString());
      const result: any = await GetUserDetail(params);
      let editDetails = result.data.data;
      let updatedValue = {
        userId: editDetails.userId,
        userGroupIds: editDetails.userGroupIds,
        aspNetUserID: editDetails.aspNetUserID,
        firstName: editDetails.firstName,
        middleName: editDetails.middleName,
        lastName: editDetails.lastName,
        address1: editDetails.address1,
        address2: editDetails.address2,
        city: editDetails.city,
        postalCode: editDetails.postalCode,
        mobileNumber: editDetails.mobileNumber,
        email: editDetails.email,
        currentpassword: "",
        oldEmail: "",
        newpassword: "",
        confirmPassword: "",
        state: editDetails.stateId,
        country: editDetails.countryId,
      }
      setUserData(updatedValue);
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }
  }

  async function SubmitUser(value: any) {
    setSubmitting(true);
    const arr1 = value.userGroupIds;
    let groupRecords = arr1.filter((e: any) => e.user_group_id.toString() >= 0);
    let saveUpdateUser = {
      userId: value.userId,
      GroupList: (groupRecords.length > 0 ? JSON.stringify(groupRecords) : ""),
      //formData.append('shareDocument', groupRecords.length > 0 ? JSON.stringify(groupRecords) : "");
      aspNetUserID: value.aspNetUserID,
      firstName: value.firstName.trim(),
      middleName: value.middleName.trim(),
      lastName: value.lastName.trim(),
      address1: value.address1,
      address2: value.address2,
      city: value.city,
      postalCode: value.postalCode,
      email: value.email,
      oldEmail: value.oldEmail,
      mobileNumber: value.mobileNumber,
      stateId: value.state,
      countryId: value.country,
    };

    if (userContext.userId > 0) {
      const resultUserUpdate: any = await UpdateUser(saveUpdateUser);
      try {
        if (resultUserUpdate.data === true) {
          setSubmitting(false);
          updateUserContext({ ...userContext, isAlert: true, alertMessage: resultUserUpdate.message, alertType: AlertType.Success });
          navigate(-1);
        } else {
          setSubmitting(false);
          updateUserContext({ ...userContext, isAlert: true, alertMessage: resultUserUpdate.message, alertType: AlertType.Error });
        }
      } catch (e: any) {
        setSubmitting(false);
        updateUserContext({ ...userContext, isAlert: true, alertMessage: typeof e === 'string' ? e as string : e?.message as string, alertType: AlertType.Error });
      }
    }
  }
  async function fetchUserGroupList() {
    let groupInfo = [];
    try {
      setLoading(true);
      let params = new URLSearchParams();
      params.append("pageIndex", page_Index.toString());
      params.append("pageSize", param.pageSize);
      params.append("sortColumn", param.sortColumn);
      params.append("sortDirection", param.sortDirection);
      params.append("searchValue", param.searchValue);
      const result: any = await GetUserGroupList(params);
      if (result && result.data?.data?.userGroupCollection) {
        let userGroupInfo = result.data.data.userGroupCollection;
        groupInfo = userGroupInfo.map((group: any) => ({
          value: group.value,
          label: group.label,
        }));
        setUserGroupCollection(groupInfo);
      } else {
        console.error("Error: No user group data found");
      }
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e : e?.message || 'An error occurred',
        alertType: AlertType.Error,
      });
    } finally {
      setLoading(false);
    }

    return groupInfo;
  }

  //Country Region
  async function fatchCountryRegion(countryId) {
    try {
      let params = new URLSearchParams();
      params.append("countryID", countryId);
      const countryRegionData: any = await GetCountryRegionListForSelect(
        params
      );
      if (countryRegionData) {
        let stateCollection = countryRegionData.data.data;
        setCountryRegion(stateCollection);
      }
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }
  }

  // //Fatch Country
  async function fatchCountry() {
    try {
      let params = new URLSearchParams();
      const countryData: any = await GetCountryListForSelect(params);
      if (countryData?.data?.data) {
        let countyCollection = countryData.data.data;
        setCountry(countyCollection);
      }
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }
  }

  useEffect(() => {
    fatchCountry();
    fatchData();
    fatchCountryRegion(60);
    fetchUserGroupList();
  }, []);

  return (
    <Box className="main-container">
      <Grid item xs={12} container className="page-header-save">
        <Grid xs={6} sm={6} item className="page-breadcrumbs">
          <CustomBreadcrumbs />
        </Grid>
      </Grid>
      <Formik
        enableReinitialize
        initialValues={userData}
        onSubmit={(values) => {
          SubmitUser(values);
        }}
        validationSchema={formValidation}
      >
        {(props) => {
          return (
            <Form onSubmit={props.handleSubmit} noValidate>
              <Grid container>
                <Grid item xs={12} mb={3} className="group-container">
                  <Grid container item spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6">User Edit</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          size="small"
                          required
                          id="firstName"
                          label="First Name"
                          fullWidth
                          inputProps={{ maxLength: 100 }}
                          margin="dense"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          //onKeyPress="return AvoidSpace(firstName)"
                          value={props.values.firstName.trim()}
                          error={
                            props.errors.firstName &&
                              props.touched.firstName
                              ? true
                              : false
                          }
                          className={
                            props.errors.firstName &&
                              props.touched.firstName
                              ? "text-input error"
                              : "text-input"
                          }
                        />

                        {props.errors.firstName &&
                          props.touched.firstName && (
                            <Typography
                              className={
                                props.errors.firstName &&
                                  props.touched.firstName
                                  ? "validation-message text-input error"
                                  : "validation-message text-input"
                              }
                            >
                              {props.errors.firstName}
                            </Typography>
                          )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          size="small"
                          id="middleName"
                          label="Middle Name"
                          fullWidth
                          inputProps={{ maxLength: 100 }}
                          margin="dense"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.middleName.trim()}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          size="small"
                          required
                          id="lastName"
                          label="Last Name"
                          fullWidth
                          inputProps={{ maxLength: 100 }}
                          margin="dense"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.lastName.trim()}
                          error={
                            props.errors.lastName &&
                              props.touched.lastName
                              ? true
                              : false
                          }
                          className={
                            props.errors.lastName &&
                              props.touched.lastName
                              ? "text-input error"
                              : "text-input"
                          }
                        />

                        {props.errors.lastName &&
                          props.touched.lastName && (
                            <Typography
                              className={
                                props.errors.lastName &&
                                  props.touched.lastName
                                  ? "validation-message text-input error"
                                  : "validation-message text-input"
                              }
                            >
                              {props.errors.lastName}
                            </Typography>
                          )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <FormControl fullWidth>
                        <TextField
                          size="small"
                          required
                          id="address1"
                          label="Address1"
                          fullWidth
                          inputProps={{ maxLength: 100 }}
                          margin="dense"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.address1}
                          error={
                            props.errors.address1 &&
                              props.touched.address1
                              ? true
                              : false
                          }
                          className={
                            props.errors.address1 &&
                              props.touched.address1
                              ? "text-input error"
                              : "text-input"
                          }
                        />
                      </FormControl>

                      {props.errors.address1 &&
                        props.touched.address1 && (
                          <Typography
                            className={
                              props.errors.address1 &&
                                props.touched.address1
                                ? "validation-message text-input error"
                                : "validation-message text-input"
                            }
                          >
                            {props.errors.address1}
                          </Typography>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <FormControl fullWidth>
                        <TextField
                          size="small"
                          id="address2"
                          label="Address2"
                          inputProps={{ maxLength: 100 }}
                          margin="dense"
                          variant="outlined"
                          value={props.values.address2}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          size="small"
                          required
                          id="city"
                          label="City"
                          fullWidth
                          inputProps={{ maxLength: 100 }}
                          margin="dense"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.city}
                          error={
                            props.errors.city && props.touched.city
                              ? true
                              : false
                          }
                          className={
                            props.errors.city && props.touched.city
                              ? "text-input error"
                              : "text-input"
                          }
                        />

                        {props.errors.city && props.touched.city && (
                          <Typography
                            className={
                              props.errors.city && props.touched.city
                                ? "validation-message text-input error"
                                : "validation-message text-input"
                            }
                          >
                            {props.errors.city}
                          </Typography>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          size="small"
                          id="postalCode"
                          label="Postal Code"
                          variant="outlined"
                          margin="dense"
                          inputProps={{ maxLength: 100 }}
                          value={props.values.postalCode}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                      <FormControl fullWidth>
                        <TextField
                          size="small"
                          required
                          id="mobileNumber"
                          label="Primary Number"
                          fullWidth
                          inputProps={{ maxLength: 10 }}
                          margin="dense"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.mobileNumber}
                          error={
                            props.errors.mobileNumber &&
                              props.touched.mobileNumber
                              ? true
                              : false
                          }
                          className={
                            props.errors.mobileNumber &&
                              props.touched.mobileNumber
                              ? "text-input error"
                              : "text-input"
                          }
                        />

                        {props.errors.mobileNumber &&
                          props.touched.mobileNumber && (
                            <Typography
                              className={
                                props.errors.mobileNumber &&
                                  props.touched.mobileNumber
                                  ? "validation-message text-input error"
                                  : "validation-message text-input"
                              }
                            >
                              {props.errors.mobileNumber}
                            </Typography>
                          )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <FormControl fullWidth>
                        <CustomSelect
                          isNotCreatable={true}
                          id={"country"}
                          FieldName={"country"}
                          options={countryData}
                          menuPortalTarget={document.querySelector(
                            "body"
                          )}
                          handleChange={(e) => {
                            fatchCountryRegion(e?.value)
                            props.setFieldValue(
                              "country",
                              e.value !== null ? e.value : "",
                              false
                            );
                            props.setFieldValue(
                              "state",
                              "",
                              true
                            );
                            props.setFieldValue(
                              "stateId",
                              "",
                              false
                            );
                          }}
                          Value={countryData?.find((x: any) => x?.value?.toString() === props.values.country?.toString())}
                          isClearable
                          isSearchable
                          placeholder={"Country *"}
                          className={
                            props.values.country === "" &&
                              props.errors.country &&
                              props.touched.country
                              ? "text-input error"
                              : "text-input"
                          }
                        />
                        {props.values.country === "" &&
                          props.errors.country &&
                          props.touched.country && (
                            <Typography
                              className={
                                props.values.country === "" &&
                                  props.errors.country &&
                                  props.touched.country
                                  ? "validation-message text-input error"
                                  : "validation-message text-input"
                              }
                            >
                              {props.errors.country}
                            </Typography>
                          )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <FormControl fullWidth>
                        <CustomSelect
                          isNotCreatable={true}
                          id={"state"}
                          FieldName={"state"}
                          options={stateData}
                          isClearable
                          menuPortalTarget={document.querySelector(
                            "body"
                          )}
                          handleChange={(e) => {
                            props.setFieldValue(
                              "state",
                              e.value !== null ? e.value : "",
                              true
                            );
                            props.setFieldValue(
                              "stateId",
                              e.value !== null ? e.value : "",
                              true
                            );
                          }}
                          Value={stateData?.find((x: any) => x?.value?.toString() === props.values.state?.toString())}
                          placeholder={"State *"}
                          className={
                            props.values.state === "" &&
                              props.errors.state &&
                              props.touched.state
                              ? "text-input error"
                              : "text-input"
                          }
                          isClearable
                          isSearchable
                        />
                        {props.values.state === "" &&
                          props.errors.state &&
                          props.touched.state && (
                            <Typography
                              className={
                                props.values.state === "" &&
                                  props.errors.state &&
                                  props.touched.state
                                  ? "validation-message text-input error"
                                  : "validation-message text-input"
                              }
                            >
                              {props.errors.state}
                            </Typography>
                          )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <FormControl fullWidth>
                        <TextField
                          required
                          id="email"
                          label="Email"
                          fullWidth
                          size="small"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.email}
                          disabled={true}
                          error={
                            props.errors.email && props.touched.email
                              ? true
                              : false
                          }
                          className={
                            props.errors.email && props.touched.email
                              ? "text-input error"
                              : "text-input"
                          }
                        />
                        <Typography variant="inherit" color="red" className="valiation-message">
                          {props.errors.email && props.touched.email && (
                            <Grid
                              className={
                                props.errors.email && props.touched.email
                                  ? "text-input error"
                                  : "text-input"
                              }
                            >
                              {props.errors.email}
                            </Grid>
                          )}
                        </Typography>
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={6}>
                      <FormControl fullWidth>
                        <CustomSelect
                          id={"userGroupId"}
                          FieldName={"userGroupId"}
                          menuPortalTarget={document.querySelector("body")}
                          placeholder="User Group *"
                          isNotCreatable={true}
                          isClearable={false}
                          //openMenuOnFocus
                          //autoFocus
                          options={UserGroupCollection}
                          handleChange={(e: any) => {
                            setselectedRole(e?.value)
                            props.setFieldValue(
                              `userGroupId`,
                              e.value !== null ? e.value : null,
                              true
                            );
                          }}
                          Value={UserGroupCollection?.find((x: any) => x?.value?.toString() === props.values.userGroupId?.toString()) || null}
                        />
                        {props.errors.userGroupId &&
                          props.touched.userGroupId && (
                            <Typography
                              variant="inherit"
                              className={
                                props.errors.userGroupId &&
                                  props.touched.userGroupId
                                  ? "validation-message text-input error"
                                  : "validation-message text-input"
                              }
                            >
                              {props.errors.userGroupId}
                            </Typography>
                          )}
                      </FormControl>
                    </Grid> */}
                    {/* <Grid item xs={12} sm={6} md={6}>
                      <FormControl fullWidth>
                        <CustomSelect
                          id={"userGroupId"}
                          FieldName={"userGroupId"}
                          menuPortalTarget={document.querySelector("body")}
                          placeholder="User Group *"
                          isNotCreatable={true}
                          isClearable={false}
                          //openMenuOnFocus
                          //autoFocus
                          options={UserGroupCollection}
                          handleChange={(e: any) => {
                            setselectedRole(e?.value)
                            props.setFieldValue(
                              `userGroupId`,
                              e.value !== null ? e.value : null,
                              true
                            );
                          }}
                          Value={UserGroupCollection?.find((x: any) => x?.value?.toString() === props.values.userGroupId?.toString()) || null}
                        />
                        {props.errors.userGroupId &&
                          props.touched.userGroupId && (
                            <Typography
                              variant="inherit"
                              className={
                                props.errors.userGroupId &&
                                  props.touched.userGroupId
                                  ? "validation-message text-input error"
                                  : "validation-message text-input"
                              }
                            >
                              {props.errors.userGroupId}
                            </Typography>
                          )}
                      </FormControl>
                    </Grid> */}
                    <Grid item xs={12} sm={6} md={6}>
                      <FormControl fullWidth>
                        <MultiSelect
                          id={"userGroupIds"}
                          FieldName={"userGroupIds"}
                          menuPortalTarget={document.querySelector("body")}
                          placeholder="Group *"
                          onCreateOption={false}
                          openMenuOnFocus
                          isClearable={false}
                          options={UserGroupCollection}
                          // onFocus={(e) => {
                          //   setOpenCreateTag({ ...openCreateTag, event: e })
                          // }}
                          // handleChange={(selectedOptions) => {
                          //   props.setFieldValue(
                          //     "tagId",
                          //     selectedOptions.map((option) => option.value) || [],
                          //     true
                          //   );
                          // }}
                          handleChange={(selectedOptions: any[]) => {
                            const group = selectedOptions.map((option) => ({
                              user_id: (userId),
                              user_group_id: option.value, // set tag value
                              group_name: option.label, // set tag label
                              is_deleted: false, // by default false
                            }));
                            props.setFieldValue("userGroupIds", group, true);
                          }}
                          // Value={tagCollection.filter((option: any) =>
                          //   Array.isArray(props.values.tagId) && props.values.tagId.includes(option.value)
                          // )}
                          Value={UserGroupCollection.filter((option: any) =>

                            Array.isArray(props.values.userGroupIds) &&
                            props.values.userGroupIds.map(x => x.user_group_id?.toString())?.includes(option.value?.toString()))
                          }

                        />
                        {props.errors.userGroupIds &&
                          props.touched.userGroupIds && (
                            <Typography
                              variant="inherit"
                              className={
                                props.errors.userGroupIds &&
                                  props.touched.userGroupIds
                                  ? "validation-message text-input error"
                                  : "validation-message text-input"
                              }
                            >
                            {props.errors.userGroupIds}
                            </Typography>
                          )}
                      </FormControl>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      marginTop={1}
                      marginLeft={3}
                      sx={{ justifyContent: "center" }}
                    >
                      <Grid sx={{ marginTop: "10px" }}>
                        <LoadingButton
                          className="button-border-remove"
                          color="primary"
                          type="submit"
                          //onClick={handleClick}
                          loading={isSubmitting}
                          // loadingPosition="start"
                          // startIcon={<></>}
                          variant="contained"
                        >
                          Update
                        </LoadingButton>
                      </Grid>

                      {/* <Grid item xs={6}>
                            <Button variant="outlined">Cancel</Button>
                          </Grid> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}

