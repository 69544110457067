import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import * as React from "react";
import { makeStyles } from '@mui/styles';

interface DatepickerProperties {
    label?: string;
    onChange?: (value: Dayjs | null) => void;
    format?: string;
    value?: (Dayjs | null | undefined);
    disablePast?: boolean;
    slotProps?: any;
    disabled?: boolean;
    name?: string;
    mode?: string;
    sx?: any;
    readonly?: boolean;
    minDate?: any;
    maxDate?: any;
    renderInput?: any;
    isClassName: any;
    hideIconButton?: boolean; // New prop to hide the entire icon button
}

export default function DatePickerComponent(props: DatepickerProperties) {
    const useStyles = makeStyles((theme) => ({
        noIconButton: {
            '& .MuiInputAdornment-root ': {
                display: 'none !important', // Hide the entire icon button container
            },
        },
    }));
    const classes = useStyles();

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} >
            <DesktopDatePicker
                {...props}
                views={['year', 'month', 'day']}
                className={`${props.hideIconButton ? classes.noIconButton : ''}`}
            />
        </LocalizationProvider>
    );
}
