import {
  Grid,
  Paper,
  FormControl,
  TextField,
  Button,
  Typography,
  Box,
  InputAdornment,
  IconButton,
  Stack,
  Alert,
  Fade,
  styled,
} from "@mui/material";
import "../../theme/style.scss";
import "../../theme/SignIn.scss";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate, useLocation, Link } from "react-router-dom";
import * as React from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
// import "../../theme/Palette/SignIn.scss";
import logo from "../../assets/images/logo.png";
import { Form, Formik } from "formik";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { PreferencesContext } from "../../PreferenceContext";
import { useContext, useEffect } from "react";
//import { UserInfoModal } from "../../shared/Modal/UserInfoModal";
import { Info, ResetPassword } from "../../services/AuthService";
import { AlertType } from "../../services/models/AlertType";
import TiyaDocsLogo from "../../components/TiyaDocsLogo";

const CustomTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: "#fffff", // Set the desired background color
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: theme.palette.grey[300],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&:hover fieldset": {
      borderColor: theme.palette.grey[500],
      borderWidth: "1px",
      BorderStyle: "solid",
    },
    "&.Mui-focused fieldset": {
      borderWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));
const initialState = {
  email: "",
  token: "",
  newPassword: "",
  confirmPassword: "",
  showPassword: false,
  showConfPassword: false,
  isSubmitted: false,
  isSubmitting: false,
};
const validateSchema = yup.object({
  newPassword: yup.string()
    .required("New Password is required.")
    .matches(
      /^(?=.{8,})(?=.*[a-z])((?=.*[\d+]))(?=.*[A-Z])(?=.*[!@#$%^&+=]).*$/,
      "Password should contain at least 8 characters, including one capital, one small, one special letter and one number"
    )
  ,
  confirmPassword: yup.string()
    .required("Confirm Password is required.")
    .oneOf([yup.ref("newPassword"), null], "Confirm password doesn't match with New Password"),
});

//const validateSchema = yup.object().shape({
//email: yup.string().email("Email is not valid!").required("Enter an Email."),
//});

export default function ResetPasswordPage(props: any) {
  const [values, setValues] = React.useState(initialState);
  const [loading, setLoading] = React.useState(false);
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [data, setData] = React.useState(initialState);
  const [showAlert, setAlertMessage] = React.useState({
    isSuccess: false,
    type: 0,
    message: "",
  });

  let navigate = useNavigate();
  let location = useLocation();


  const onClickReturn = () => {
    navigate("/");
  };


  // For Navbar visible
  async function init() {
    const data: any = await Info(null);
    const query = new URLSearchParams(location.search);
    const localToken = query.get("t") || "";
    const localEmail = query.get("e") || "";

    setValues({ ...values, token: localToken, email: localEmail });

    let useContextData = userContext;
    updateUserContext({ ...useContextData, isMenuVisible: false });
  }

  //POST Method
  async function submitForm(values: any, resetForm: any) {
    setLoading(true);
    let newPassword = values.newPassword;
    let confirmPassword = values.confirmPassword;


    const query = new URLSearchParams(location.search);
    const token = query.get("t") || "";
    const email = query.get("e") || "";

    let useContextData = userContext;
    // setValue({ ...useContextData, isLoadding: true });
    //setData({ ...data, isSubmitting: true });
    try {
      const formData = {
        Password: newPassword,
        ConfirmPassword: confirmPassword,
        Email: email,
        Token: token,
      }
      const result: any = await ResetPassword(formData);
      // updateUserContext({ ...userContext, isAlert: true, alertMessage: resultUserSave.message, alertType: AlertType.Success });

      updateUserContext({ ...useContextData, token: values.token, isMenuVisible: false, isAlert: true, alertMessage: result.message, alertType: AlertType.Success });

      setLoading(false);


      if (result) {
        setData({
          ...data,
          isSubmitting: false,
          isSubmitted: true,
          email: values.email,
        });
        navigate("/");
        setIsSubmitting(false);
        if (result) {
          setAlertMessage({
            type: 1,
            isSuccess: true,
            message: result.message.message as string,
          });
        }
        // setData({
        //   ...data,
        //   isSubmitting: false,
        //   isSubmitted: true,
        //   email: values.email,
        //   newPassword:"",
        //   confirmPassword:"",
        // });
      }

    } catch (error) {
      setLoading(false);

      setData({
        ...data,
        isSubmitting: false,
        isSubmitted: false,
        email: values.email,
      });


      setIsSubmitting(false);
      if (error) {
        setAlertMessage({
          type: 2,
          isSuccess: false,
          message: error as string,
        });
      }
    }
    setTimeout(() => {
      setAlertMessage({
        type: 0,
        isSuccess: false,
        message: "",
      })
      // window.location.reload();
    }, 5000)


  }

  //--show & hide function for NewPassword--
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  //--show & hide function for CondormPassword--
  const handleClickShowCConfPassword = () => {
    setValues({
      ...values,
      showConfPassword: !values.showConfPassword,
    });
  };
  useEffect(() => {
    init();
  }, []);


  return (
    <Fade in={true} timeout={{ enter: 500 }}>
      <Box className="signin-container">
        <Paper elevation={3} className="paper-singin">
          <Grid className="img-wrapper" item xs={6}>
            {" "}
          </Grid>
          <Grid item className="signin-wrapper" xs={4}>
            <Box className="box-container" sx={{ my: 2 }}>
              <Paper className="paper-container-forgot paper-pre-signin">
                <Grid
                  // marginTop={3}
                  display="flex"
                  //justifyContent="flex-start"
                  pb={5}
                  item
                >
                  <img
                    loading="lazy"
                    className="img-page"
                    src={logo}
                    alt="logo"
                  /></Grid>
                <Formik
                  initialValues={initialState}
                  onSubmit={(values: any, { resetForm }) => {
                    submitForm(values, resetForm);
                  }}
                  validationSchema={validateSchema}
                >
                  {(props) => {
                    return (
                      <Grid>
                        <Grid>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "left",
                              color:"#ac8445"
                            }}>
                            <b>Reset your password</b>

                          </Box>
                        </Grid>
                        <Form>
                          <Grid container spacing={2} marginTop={1}>
                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                <CustomTextField
                                  id="newPassword"
                                  label="New Password*"
                                  fullWidth
                                  size="small"
                                  margin="dense"
                                  type={values.showPassword ? "text" : "password"}
                                  value={props.values.password}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  error={
                                    props.errors.newPassword &&
                                      props.touched.newPassword
                                      ? true
                                      : false
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowPassword}
                                          edge="end"
                                        >
                                          {values.showPassword ? (
                                            <VisibilityOff />
                                          ) : (
                                            <Visibility />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                <Typography variant="inherit" className="validation-message">
                                  {props.errors.newPassword &&
                                    props.touched.newPassword && (
                                      <Grid
                                        className={
                                          props.errors.newPassword &&
                                            props.touched.newPassword
                                            ? "text-input error"
                                            : "text-input"
                                        }
                                      >
                                        {props.errors.newPassword}
                                      </Grid>
                                    )}
                                </Typography>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                <CustomTextField
                                  id="confirmPassword"
                                  label="Confirm Password*"
                                  fullWidth
                                  size="small"
                                  margin="dense"
                                  type={values.showConfPassword ? "text" : "password"}
                                  value={props.values.password}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                  error={
                                    props.errors.confirmPassword &&
                                      props.touched.confirmPassword
                                      ? true
                                      : false
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          aria-label="toggle password visibility"
                                          onClick={handleClickShowCConfPassword}
                                          edge="end"
                                        >
                                          {values.showConfPassword ? (
                                            <VisibilityOff />
                                          ) : (
                                            <Visibility />
                                          )}
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                <Typography variant="inherit" className="validation-message">
                                  {props.errors.confirmPassword &&
                                    props.touched.confirmPassword && (
                                      <Grid
                                        className={
                                          props.errors.confirmPassword &&
                                            props.touched.confirmPassword
                                            ? "text-input error"
                                            : "text-input"
                                        }
                                      >
                                        {props.errors.confirmPassword}
                                      </Grid>
                                    )}
                                </Typography>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid container spacing={3} marginTop={0}>
                            <Grid item xs={12} textAlign="right">
                              <LoadingButton
                                className="btn"
                                color="primary"
                                //onClick={() => setLoading(true)}
                                // loading={loading}
                                loading={loading}
                                loadingPosition="start"
                                fullWidth
                                startIcon={<></>}
                                variant="contained"
                                type="submit"
                               
                              >
                                Submit
                              </LoadingButton>
                            </Grid>
                          </Grid>
                          <Grid spacing={3} marginTop={3}>
                            <Box
                              className="text-primary"
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "center",
                              }}
                            >
                              <Link
                                to="/"
                                style={{
                                  textDecoration: "none",
                                  color: '#2662f0',
                                  fontSize: "13px",
                                  fontFamily: "$font-family-inter",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <span
                                  style={{
                                    marginRight: "10px",
                                    lineHeight: "1px",
                                  }}
                                >
                                  <ArrowBackIcon sx={{ fontSize: "13px", color: "#ac8445" }} />
                                </span>
                                <span
                                  style={{ color: "#ac8445" }}
                                >
                                  Back to Sign in
                                </span>
                              </Link>
                            </Box>
                          </Grid>
                        </Form>
                      </Grid>
                    );
                  }}
                </Formik>
              </Paper>
            </Box>
          </Grid>
        </Paper>
      </Box>
    </Fade>
  );

}
