import React from "react";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import { useField } from "formik";

interface SelectPropsType {
  id: string;
  className?: string;
  placeholder?: string;
  FieldName: string;
  name?: string;
  options: Array<any>;
  isDisabled?: boolean;
  onCreateOption?: any;
  handleChange: any;
  Value: any;
  changeValuePerameter?: any;
  defaultValue?: any;
  onClear?: any;
  isNotCreatable?: boolean;
  CustomOptionComponent?: any;
  isClearable?: boolean;
  CustomFilterOption?: any;
  menuPortalTarget?: any;
  isSearchable?: boolean;
  autoFocus?: boolean;
  optionInnerWidth?: any;
  isInnerWidth?: any;
  defaultOptions?: any;
  onBlur?: any;
  tabIndex?: number;
  tabSelectsValue?: boolean;
  openMenuOnFocus?: boolean;
  onFocus?: any;
  ref?: null;
}

const Option = (props) => (
  <components.Option {...props}>
    <div>{props.children}</div>
  </components.Option>
);

const ValueContainer = (props) => (
  <components.ValueContainer {...props}>
    <components.Placeholder {...props} isFocused={props.isFocused}>
      {props.selectProps.placeholder}
    </components.Placeholder>
    {React.Children.map(props.children, (child) =>
      child && child.type !== components.Placeholder ? child : null
    )}
  </components.ValueContainer>
);

const filterOption = (candidate, input) => {
  const label = candidate.label || "";
  const searchTerm = input ? input.toLowerCase().trim() : "";

  return candidate.data.__isNew__ || label.toLowerCase().includes(searchTerm);
};

export const MultiSelect = (props: SelectPropsType) => {
  const {
    FieldName,
    options,
    handleChange,
    Value,
    onCreateOption,
    onClear,
    isNotCreatable,
    CustomOptionComponent,
    CustomFilterOption,
    placeholder,
    onBlur,
    ...restProps
  } = props;

  const [field, meta, helpers] = useField(`${FieldName}`);

  // Use CreatableSelect if onCreateOption is provided, otherwise use Select
  const SelectComponent = onCreateOption ? CreatableSelect : Select;

  return (
    <SelectComponent
      {...field}
      {...restProps}
      inputId={restProps.id}
      isMulti={true}
      tabSelectsValue
      onBlur={onBlur || null}
      placeholder={placeholder || ""}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: "#ebf2fe",
          primary25: "#f1f6fe",
          primary50: "#f1f6fe",
        },
      })}
      onChange={(e, ActionType) => {
        if (ActionType.action === "clear") {
          helpers.setValue([]);
          handleChange({ value: [] });
          if (onClear) {
            onClear();
          }
        } else {
          handleChange(e);
        }
      }}
      components={{
        Option: CustomOptionComponent || Option,
        ValueContainer: ValueContainer,
      }}
      options={options}
      value={Value}
      styles={selectStyles(meta, props)}
      filterOption={CustomFilterOption || filterOption}
      menuPortalTarget={props.menuPortalTarget}
      onCreateOption={onCreateOption} // This line will now be active
    />
  );
};

const selectStyles = (meta, props) => {
  return {
    control: (basestyle, state) => ({
      ...basestyle,
      // height: "44px",
      border:
        meta.error && meta.touched
          ? state.isFocused
            ? "1px solid #d32f2f"
            : "1px solid #d32f2f"
          : state.isFocused
          ? "1px solid black"
          : "1px solid $grey-100",
      boxShadow: "none",
      "&:hover": {
        border:
          meta.error && meta.touched ? "1px solid #d32f2f" : "1px solid black",
      },
    }),
    placeholder: (basestyle, state) => ({
      ...basestyle,
      color:
        meta.error && meta.touched
          ? "#d32f2f"
          : state.hasValue || state.selectProps.inputValue
          ? state.isFocused
            ? "#0b0b45"
            : "gray"
          : "gray",
      overflow: "hidden",
      backgroundColor:
        state.hasValue || state.selectProps.inputValue
          ? "white"
          : "transparant",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      position:
        state.hasValue || state.selectProps.inputValue ? "absolute" : "static",
      top: state.hasValue || state.selectProps.inputValue ? -15 : "10%",
      transition: "top 0.3s, font-size 0.1s",
      fontSize: (state.hasValue || state.selectProps.inputValue) && 10,
      paddingLeft:
        state.hasValue || state.selectProps.inputValue ? "3px" : "0px",
      paddingRight:
        state.hasValue || state.selectProps.inputValue ? "3px" : "0px",
    }),
    option: (basestyle, state) => ({
      ...basestyle,
      color: state.isSelected ? "black" : "black",
      border: "1px solid #f1f6fe",
      // backgroundColor: "red !important",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      overflow:
        state.hasValue || state.selectProps.inputValue ? "visible" : "hidden",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      color: meta.error && meta.touched ? "#d32f2f" : "#adb5bd",
      "&:hover": {
        color: meta.error && meta.touched ? "#d32f2f" : "black",
      },
    }),
    menu: (base, state) => ({
      ...base,
      width: props?.isInnerWidth ? props?.optionInnerWidth : "100% !important", // your desired heights
    }),
    indicatorSeparator: (base, state) => ({
      ...base,
      backgroundColor: meta.error && meta.touched ? "#d32f2f" : "#adb5bd",
    }),
    clearIndicator: (base, state) => ({
      ...base,
      color: meta.error && meta.touched ? "#d32f2f" : "#adb5bd",
      "&:hover": {
        color: meta.error && meta.touched ? "#d32f2f" : "black",
      },
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };
};

