import React, { useContext, useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import "../../theme/SignIn.scss";
import {
    Box,
    FormControl,
    Fade,
    TextField,
    Typography,
    Grid,
    Paper,
    FormLabel,
    styled,
    Stack,
    Alert,
} from "@mui/material";
import logo from "../../assets/images/logo.png";
import { PreferencesContext } from "../../PreferenceContext";
import { Link, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as yup from "yup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ForgotPassword } from "../../services/AuthService";

//For CustomTextField 
const CustomTextField = styled(TextField)(({ theme }) => ({
    backgroundColor: "#fffff", // Set the desired background color
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: theme.palette.grey[300],
            borderWidth: "1px",
            BorderStyle: "solid",
        },
        "&:hover fieldset": {
            borderColor: theme.palette.grey[500],
            borderWidth: "1px",
            BorderStyle: "solid",
        },
        "&.Mui-focused fieldset": {
            borderWidth: "2px",
            borderColor: theme.palette.primary.main,
        },
    },
}));
const initialState = {
    email: "",
    token: "",
    isSubmitted: false,
    isSubmitting: false,
    message: {},
};
const validateSchema = yup.object().shape({
    email: yup.string().email("Email is not valid!").required("Enter your email to reset password."),
});

export default function ForgotPasswordPage() {
    const [data, setData] = useState(initialState);
    const [loading, setLoading] = React.useState(false);
    const { userContext, updateUserContext } = useContext(PreferencesContext);
    const [showAlert, setAlertMessage] = React.useState({
        isSuccess: false,
        type: 0,
        message: "",
    });

    let navigate = useNavigate();
    const onClickReturn = () => {
        navigate("/");
    };

    // For Navbar visible
    function init() {

        let useContextData = userContext;
        updateUserContext({ ...useContextData, isMenuVisible: false });
    }
    //POST Method
    async function submitForm(values: any, resetForm: any) {
        setLoading(true);
        setData({
            ...data,
            isSubmitting: true,
            isSubmitted: false,
            email: values.email,
        });

        let useContextData = userContext;
        try {
            let formData = {
                email: values.email,
            };
            const result: any = await ForgotPassword(formData);
            updateUserContext({ ...useContextData, isMenuVisible: false });
            setLoading(false);
            //--After seccessfully Submit--:
            if (result) {
                setData({
                    ...data,
                    isSubmitting: false,
                    isSubmitted: true,
                    email: "",
                });

                if (result) {
                    setAlertMessage({
                        type: 1,
                        isSuccess: true,
                        message: result.message as string,
                    });
                }
                resetForm();
                // window.location.reload();
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setData({
                ...data,
                isSubmitting: false,
                isSubmitted: false,
                email: values.email,
            });

            if (error) {
                setAlertMessage({
                    type: 2,
                    isSuccess: false,
                    message: error as string,

                });
                setLoading(false);
            }

        }
        setTimeout(() => {
            setAlertMessage({
                type: 0,
                isSuccess: false,
                message: "",
            })
        }, 5000)
    }

    useEffect(() => {
        init();
    }, []);

    return (
        <Fade in={true} timeout={{ enter: 500 }}>
            <Box className="signin-container">
                <Paper elevation={3} className="paper-singin">
                    <Grid className="img-wrapper" item xs={6}>
                        {" "}
                    </Grid>
                    <Grid item className="signin-wrapper" xs={4}>
                        <Box className="box-container" sx={{ my: 2 }}>
                            <Paper className="paper-container-forgot paper-pre-signin">
                                <Grid
                                    // marginTop={3}
                                    display="flex"
                                    //justifyContent="flex-start"
                                    pb={5}
                                    item
                                >
                                    <img
                                        loading="lazy"
                                        className="img-page"
                                        src={logo}
                                        alt="logo"
                                    /></Grid>
                                <Formik
                                    initialValues={initialState}
                                    onSubmit={(values: any, { resetForm }) => {
                                        submitForm(values, resetForm);
                                    }}
                                    validationSchema={validateSchema}
                                >
                                    {(props) => {
                                        return (
                                            <Grid>
                                                <Grid>
                                                    <Box
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "left",
                                                            color:"#ac8445"
                                                        }}>
                                                        <b>Reset your password</b>

                                                    </Box>
                                                </Grid>

                                                <Form>
                                                    {(showAlert.isSuccess === true) && (showAlert.type === 1) ? (
                                                        <Grid marginTop={2}>
                                                            <Stack sx={{ width: "100%" }}>
                                                                <Alert severity="success">{showAlert.message}</Alert>
                                                            </Stack>
                                                        </Grid>
                                                    ) : (
                                                        (showAlert.isSuccess === false) && (showAlert.type === 2) ? (
                                                            <Grid marginTop={2}>
                                                                <Stack sx={{ width: "100%" }}>
                                                                    <Alert severity="error">{showAlert.message}</Alert>
                                                                </Stack>
                                                            </Grid>
                                                        ) : (''
                                                        ))
                                                    }
                                                    <Grid container spacing={1} marginTop={1}>
                                                        <Grid item xs={12}>
                                                            <FormControl fullWidth>
                                                                {/* <FormLabel
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "left",
                                                                        fontSize: "13px",
                                                                    }}
                                                                >
                                                                    Email
                                                                </FormLabel> */}
                                                                <CustomTextField
                                                                    id="email"
                                                                    placeholder="Enter email"
                                                                    label="Email*"
                                                                    fullWidth
                                                                    size="small"
                                                                    margin="dense"
                                                                    value={props.values.email}
                                                                    onChange={props.handleChange}
                                                                    onBlur={props.handleBlur}
                                                                    error={
                                                                        props.errors.email && props.touched.email
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                                <Typography
                                                                    variant="inherit"
                                                                    className="validation-message"
                                                                >
                                                                    {props.errors.email && props.touched.email && (
                                                                        <Grid
                                                                            className={
                                                                                props.errors.email && props.touched.email
                                                                                    ? "text-input error"
                                                                                    : "text-input"
                                                                            }
                                                                        >
                                                                            {props.errors.email}
                                                                        </Grid>
                                                                    )}
                                                                </Typography>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3} marginTop={0}>
                                                        <Grid item xs={12} textAlign="right">
                                                            <LoadingButton
                                                                color="primary"
                                                                //onClick={() => setLoading(true)}
                                                                // loading={loading}
                                                                loading={loading}
                                                                loadingPosition="start"
                                                                fullWidth
                                                                startIcon={<></>}
                                                                variant="contained"
                                                                type="submit"
                                                                className="button-border-remove"
                                                            >
                                                                Submit
                                                            </LoadingButton>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid spacing={3} marginTop={3}>
                                                        <Box
                                                            className="text-primary"
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "flex-end",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <Link
                                                                to="/"
                                                                style={{
                                                                    textDecoration: "none",
                                                                    color: '#2662f0',
                                                                    fontSize: "13px",
                                                                    fontFamily: "$font-family-inter",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <span
                                                                    style={{
                                                                        marginRight: "10px",
                                                                        lineHeight: "1px",
                                                                    }}
                                                                >
                                                                    <ArrowBackIcon sx={{ fontSize: "13px", color: "#ac8445" }} />
                                                                </span>
                                                                <span
                                                                    style={{ color: "#ac8445" }}
                                                                >
                                                                    Back to Sign in
                                                                </span>
                                                            </Link>
                                                        </Box>
                                                    </Grid>
                                                </Form>
                                            </Grid>
                                        );
                                    }}
                                </Formik>
                            </Paper>
                        </Box>
                    </Grid>
                </Paper>
            </Box>
        </Fade>
    );
}