import * as React from "react";
import Button from "@mui/material/Button";
import Select from "react-select";

import {
  Box,
  FormControl,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  //Select,
  CircularProgress,
  Backdrop,
  TableCell,
  TextField,
  Typography,
  useMediaQuery,
  IconButton,
  InputAdornment,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { Formik, Form, validateYupSchema } from "formik";
import * as Yup from "yup";
import Autocomplete from "@mui/material/Autocomplete";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { PreferencesContext } from "../../../PreferenceContext";
import { GetCountryRegionListForSelect } from "../../../services/CountryRegionService";
import { GetCountryListForSelect } from "../../../services/CountryService";
import { AlertType } from "../../../services/models/AlertType";
import { CreateUser } from "../../../services/UserService";
import { isElementVisible } from "../../../services/common/CommonFuctions";
import { CustomSelect } from "../../../components/Common/CustomSelect";
import { CheckIsExpireInvitation, SetPassword } from "../../../services/AuthService";
import { Password, Visibility, VisibilityOff } from "@mui/icons-material";




export default function ManageUser(props: any) {
  const [isSubmitting, setSubmitting] = React.useState(false);
  const { userContext, updateUserContext } = React.useContext(PreferencesContext);


  const formInitialValues = {
    userId: 0,
    aspNetUserID: "",
    firstName: "",
    middleName: "",
    lastName: "",
    address1: "",
    address2: "",
    city: "",
    oldEmail: "",
    email: userContext.email,
    postalCode: "",
    imageProfile: "",
    mobileNumber: "",
    currentpassword: "",
    newpassword: "",
    confirmPassword: "",
    state: "",
    country: '60',
    token: "",
    newPassword: "",
    password: "",
    showPassword: false,
    showConfPassword: false,
    isSubmitted: false,
    isSubmitting: false,
  };
  const [userinitial, setUserInitial] = React.useState(formInitialValues);
  const titlestyle = useMediaQuery("(max-width:600px)");
  let navigate = useNavigate();
  //Formik validation
  const typeValidation = Yup.object().shape({
    id: Yup.number().required("id"),
    code: Yup.string().required("code"),
    label: Yup.string().required("label"),
  });
  const [isRegister, setIsRegister] = React.useState(false);
  const [showData, setShowData] = React.useState(true);
  const [isPartial, setIsPartial] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [isAllowAdd, setAllowAdd] = React.useState(true);
  const [stateData, setCountryRegion] = React.useState([]);
  const [countryData, setCountry] = React.useState([]);
  const [selectcountry, setSelectcountry] = React.useState(0);
  const formValidation = Yup.object().shape({
    firstName: Yup.string().required("First Name is required."),
    lastName: Yup.string().required("Last Name is required."),
    address1: Yup.string().required("Address 1 is required."),
    postalCode: Yup.string().required("Postal code is required"),
    city: Yup.string().required("City is required."),
    state: Yup.string().required("State is required."),
    country: Yup.string().required("Country is required."),
    mobileNumber: Yup.string()
      .required("Contact Number is required.")
      .matches(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, {
        message: "Invalid  number",
        excludeEmptyString: false,
      }),
    email: Yup.string()
      .required("Email  is required.")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, {
        message: "Enter a valid email. ",
      }),
    password: Yup.string()
      .required("New Password is required.")
      .matches(
        /^(?=.{8,})(?=.*[a-z])((?=.*[\d+]))(?=.*[A-Z])(?=.*[!@#$%^&+=]).*$/,
        "New Password should contain at least 8 characters, including one capital, one small, one special letter and one number."
      )
    ,
    confirmPassword: Yup.string()
      .required("Confirm Password is required.")
      .oneOf([Yup.ref("password"), null], "Confirm Password doesn't match with the New Password."),
  });


  let location = useLocation();
  //For Props
  const { title, button, userId } =
    (location && location.state) || {};

  const query = new URLSearchParams(location.search);
  const localToken = query.get("t") || "";


  //For Props
  async function init() {
    const formData = {
      Token: localToken,
    }
    try {
      const result: any = await CheckIsExpireInvitation(formData);
      if (result.data) {
        setUserInitial({ ...userinitial, token: localToken, email: result?.data?.data?.email });
        updateUserContext({ ...userContext, isMenuVisible: true, token: result?.data?.token, userId: 0, userKey: result?.data?.userKey, email: result?.data?.data?.email });
        if (result.data?.isExpired) {
          setShowData(false)
          console.log("the user is expired");
        }
        else if (result.data?.isRegister) {
          setIsRegister(true);
        } else if (result.data?.isPartial) {
          setIsPartial(true)
        }
      }
    } catch (error) {

    }
  }

  //Country Region
  async function fatchCountryRegion(countryId) {
    try {
      let params = new URLSearchParams();
      params.append("countryID", countryId);
      const countryRegionData: any = await GetCountryRegionListForSelect(params)
      if (countryRegionData) {
        let stateCollection = countryRegionData.data.data;
        setCountryRegion(stateCollection);
      }
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }


  }
  //Fatch Country
  async function fatchCountry() {
    try {
      let params = new URLSearchParams();
      const countryData: any = await GetCountryListForSelect(params)
      if (countryData) {
        let countyCollection = countryData.data.data;
        setCountry(countyCollection)
      }
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }
  }


  async function fatchData() {
    try {
      setLoading(true);
      let updatedValue = {
        userId: userContext.userId,
        aspNetUserID: userContext.userKey,
        firstName: userContext.firstName,
        middleName: "",
        lastName: userContext.lastName,
        address1: "",
        address2: "",
        city: "",
        oldEmail: "",
        email: userContext.email,
        postalCode: "",
        imageProfile: "",
        mobileNumber: "",
        currentpassword: "",
        newpassword: "",
        state: "",
        country: '60',
        confirmPassword: "",
        newPassword: "",
        token: "",
        password: "",
        showPassword: false,
        showConfPassword: false,
        isSubmitted: false,
        isSubmitting: false
      }
      setUserInitial(updatedValue);
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }
  }

  // For Navbar visible
  // function init() {
  //   // const query = new URLSearchParams(props.location.search);
  //   // const localToken = query.get("t") || "";
  //   // const localEmail = query.get("e") || "";
  //   let useContextData = userContext;
  //   updateUserContext({ ...useContextData, isMenuVisible: false });
  // }
  //Submit Form
  async function SubmitUser(value: any) {
    let newPassword = value.password;
    let confirmPassword = value.confirmPassword;

    const query = new URLSearchParams(location.search);
    const token = query.get("t") || "";
    setSubmitting(true);
    let SaveUpdateUser = {
      userId: userContext.userId,
      aspNetUserID: userContext.userKey,
      firstName: value.firstName,
      middleName: value.middleName,
      lastName: value.lastName,
      address1: value.address1,
      address2: value.address2,
      city: value.city,
      postalCode: value.postalCode,
      email: userContext.email,
      oldEmail: userContext.email,
      imageProfile: value.imageProfile,
      mobileNumber: value.mobileNumber,
      stateId: value.state,
      countryId: value.country,
      role: userContext.userRole,
      password: newPassword,
      confirmPassword: confirmPassword,
      token: token,
    };
    if (userContext.userId > 0) {
      setSubmitting(false);
      navigate("/");
    } else {
      const resultUserSave: any = await SetPassword(SaveUpdateUser);
      updateUserContext({ ...userContext, isAlert: true, alertMessage: resultUserSave.message, alertType: AlertType.Success });
      try {
        if (resultUserSave.data === true) {
          setSubmitting(false);
          navigate("/");
        }
        else {
          console.log("Error in Create the User");
        }
      } catch (e: any) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: typeof e === 'string' ? e as string : e?.message as string,
          alertType: AlertType.Error,
        });
      }

    }
  }

  const handleClickShowPassword = () => {
    setUserInitial({
      ...userinitial,
      showPassword: !userinitial.showPassword,
    });
  };

  //--show & hide function for CondormPassword--
  const handleClickShowCConfPassword = () => {
    setUserInitial({
      ...userinitial,
      showConfPassword: !userinitial.showConfPassword,
    });
  };

  useEffect(() => {
    fatchCountry();
    // fatchData();
    fatchCountryRegion(userinitial.country)
    init();
  }, []);

  React.useEffect(() => {
    document.addEventListener("keydown", function (event: any) {
      if (event.keyCode === 13 && event.target.nodeName === "INPUT") {
        let form = event.target.form;
        let index = Array.prototype.indexOf.call(form, event.target);
        let Elements = form.elements;
        if (event.target.name === "mobileNumber") {
          Elements["submit"].focus();
        } else {
          let nextIndex = index + 2;
          while ((nextIndex < Elements.length && !isElementVisible(Elements[nextIndex])) || (Elements[nextIndex]?.disabled === true)) {
            nextIndex++;
          }
          if (nextIndex < Elements.length) {
            Elements[nextIndex].focus();
          }
        }
        event.preventDefault();
      }
    });
  }, []);

  // useEffect(() => { }, [userinitial]);

  return (

    <React.Fragment>
      <Grid className="padding-manageuser-paper">
        <Grid item xs={12} mb={3} className="group-container add-padding-manage-user">
          <Grid container item spacing={2}>
            <Backdrop
              sx={{ color: "#fff", backgroundColor: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
            >
            </Backdrop>


            <h4 className="page-title" text-align='center'>
              My Information
            </h4>
            <Formik
              enableReinitialize
              initialValues={userinitial}
              onSubmit={(values) => {
                SubmitUser(values);
              }}
              validationSchema={formValidation}
            >
              {(props) => {
                return (

                  <Grid className="formsave">
                    <Form onSubmit={props.handleSubmit} noValidate>
                      <Grid container spacing={3} marginTop={3}>
                        <Grid item xs={12} sm={4} md={4} >
                          <FormControl fullWidth>
                            <TextField
                              required
                              size="small"
                              id="firstName"
                              autoFocus
                              label="First Name"
                              fullWidth
                              inputProps={{ maxLength: 100 }}

                              onChange={props.handleChange("firstName")}
                              onBlur={props.handleBlur("firstName")}
                              value={props.values.firstName}
                              error={
                                props.errors.firstName &&
                                  props.touched.firstName
                                  ? true
                                  : false
                              }
                              className={
                                props.errors.firstName &&
                                  props.touched.firstName
                                  ? "text-input error"
                                  : "text-input"
                              }
                            />
                            <Typography
                              variant="inherit"
                              className="validation-message"
                              component={"span"}
                            >
                              {props.errors.firstName &&
                                props.touched.firstName && (
                                  <Grid
                                    className={
                                      props.errors.firstName &&
                                        props.touched.firstName
                                        ? "text-input error"
                                        : "text-input"
                                    }
                                  >
                                    {props.errors.firstName}
                                  </Grid>
                                )}
                            </Typography>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <FormControl fullWidth>
                            <TextField
                              size="small"
                              label="Middle Name"
                              id="middleName"
                              fullWidth

                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.middleName}
                              rows={1}
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <FormControl fullWidth>
                            <TextField
                              required
                              size="small"
                              id="lastName"
                              label="Last Name"
                              fullWidth
                              inputProps={{ maxLength: 100 }}

                              onChange={props.handleChange("lastName")}
                              onBlur={props.handleBlur("lastName")}
                              value={props.values.lastName}
                              error={
                                props.errors.lastName &&
                                  props.touched.lastName
                                  ? true
                                  : false
                              }
                              className={
                                props.errors.lastName &&
                                  props.touched.lastName
                                  ? "text-input error"
                                  : "text-input"
                              }
                            />
                            <Typography
                              variant="inherit"
                              className="validation-message"
                              component={"span"}
                            >
                              {props.errors.lastName &&
                                props.touched.lastName && (
                                  <Grid
                                    className={
                                      props.errors.lastName &&
                                        props.touched.lastName
                                        ? "text-input error"
                                        : "text-input"
                                    }
                                  >
                                    {props.errors.lastName}
                                  </Grid>
                                )}
                            </Typography>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <FormControl fullWidth>
                            <TextField
                              required
                              size="small"
                              id="address1"
                              label="Address 1"
                              fullWidth
                              inputProps={{ maxLength: 100 }}

                              onChange={props.handleChange("address1")}
                              onBlur={props.handleBlur("address1")}
                              value={props.values.address1}
                              error={
                                props.errors.address1 &&
                                  props.touched.address1
                                  ? true
                                  : false
                              }
                              className={
                                props.errors.address1 &&
                                  props.touched.address1
                                  ? "text-input error"
                                  : "text-input"
                              }
                            />
                            <Typography
                              variant="inherit"
                              className="validation-message"
                              component={"span"}
                            >
                              {props.errors.address1 &&
                                props.touched.address1 && (
                                  <Grid
                                    className={
                                      props.errors.address1 &&
                                        props.touched.address1
                                        ? "text-input error"
                                        : "text-input"
                                    }
                                  >
                                    {props.errors.address1}
                                  </Grid>
                                )}
                            </Typography>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <FormControl fullWidth>
                            <TextField
                              size="small"
                              label="Address 2"
                              id="address2"
                              fullWidth

                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.address2}
                              rows={1}
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <FormControl fullWidth>
                            <TextField
                              required
                              id="city"
                              label="City"
                              size="small"
                              fullWidth
                              inputProps={{ maxLength: 100 }}

                              onChange={props.handleChange("city")}
                              onBlur={props.handleBlur("city")}
                              value={props.values.city}
                              error={
                                props.errors.city &&
                                  props.touched.city
                                  ? true
                                  : false
                              }
                              className={
                                props.errors.city &&
                                  props.touched.city
                                  ? "text-input error"
                                  : "text-input"
                              }
                            />
                            <Typography
                              variant="inherit"
                              className="validation-message"
                              component={"span"}
                            >
                              {props.errors.city &&
                                props.touched.city && (
                                  <Grid
                                    className={
                                      props.errors.city &&
                                        props.touched.city
                                        ? "text-input error"
                                        : "text-input"
                                    }
                                  >
                                    {props.errors.city}
                                  </Grid>
                                )}
                            </Typography>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4} md={4}>
                          <FormControl fullWidth margin="dense">
                            <CustomSelect
                              isNotCreatable={true}
                              id={"country"}
                              FieldName={"country"}
                              options={countryData}
                              menuPortalTarget={document.querySelector(
                                "body"
                              )}
                              handleChange={(e) => {
                                fatchCountryRegion(e?.value)
                                props.setFieldValue(
                                  "country",
                                  e.value !== null ? e.value : "",
                                  false
                                );
                                props.setFieldValue(
                                  "state",
                                  "",
                                  true
                                );
                                props.setFieldValue(
                                  "stateId",
                                  "",
                                  false
                                );
                              }}
                              Value={countryData?.find((x: any) => x?.value?.toString() === props.values.country?.toString())}
                              isClearable
                              isSearchable
                              placeholder={"Country *"}
                              className={
                                props.values.country === "" &&
                                  props.errors.country &&
                                  props.touched.country
                                  ? "text-input error"
                                  : "text-input"
                              }
                            />
                            {props.values.country === "" &&
                              props.errors.country &&
                              props.touched.country && (
                                <Typography
                                  className={
                                    props.values.country === "" &&
                                      props.errors.country &&
                                      props.touched.country
                                      ? "validation-message text-input error"
                                      : "validation-message text-input"
                                  }
                                >
                                  {props.errors.country}
                                </Typography>
                              )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <FormControl fullWidth margin="dense">
                            <CustomSelect
                              isNotCreatable={true}
                              id={"state"}
                              FieldName={"state"}
                              options={stateData}
                              isClearable
                              menuPortalTarget={document.querySelector(
                                "body"
                              )}
                              handleChange={(e) => {
                                props.setFieldValue(
                                  "state",
                                  e.value !== null ? e.value : "",
                                  true
                                );
                                props.setFieldValue(
                                  "stateId",
                                  e.value !== null ? e.value : "",
                                  true
                                );
                              }}
                              Value={stateData?.find((x: any) => x?.value?.toString() === props.values.state?.toString())}
                              placeholder={"State *"}
                              className={
                                props.values.state === "" &&
                                  props.errors.state &&
                                  props.touched.state
                                  ? "text-input error"
                                  : "text-input"
                              }
                              isClearable
                              isSearchable
                            />
                            {props.values.state === "" &&
                              props.errors.state &&
                              props.touched.state && (
                                <Typography
                                  className={
                                    props.values.state === "" &&
                                      props.errors.state &&
                                      props.touched.state
                                      ? "validation-message text-input error"
                                      : "validation-message text-input"
                                  }
                                >
                                  {props.errors.state}
                                </Typography>
                              )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <FormControl fullWidth margin="dense">
                            <TextField
                              required
                              id="postalCode"
                              label="Postal Code"
                              fullWidth
                              inputProps={{ maxLength: 100 }}
                              size="small"
                              onChange={props.handleChange("postalCode")}
                              onBlur={props.handleBlur("postalCode")}
                              value={props.values.postalCode}
                              error={
                                props.errors.postalCode &&
                                  props.touched.postalCode
                                  ? true
                                  : false
                              }
                              className={
                                props.errors.postalCode &&
                                  props.touched.postalCode
                                  ? "text-input error"
                                  : "text-input"
                              }
                            />
                            <Typography
                              variant="inherit"
                              className="validation-message"
                              component={"span"}
                            >
                              {props.errors.postalCode &&
                                props.touched.postalCode && (
                                  <Grid
                                    className={
                                      props.errors.postalCode &&
                                        props.touched.postalCode
                                        ? "text-input error"
                                        : "text-input"
                                    }
                                  >
                                    {props.errors.postalCode}
                                  </Grid>
                                )}
                            </Typography>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} >
                          <FormControl fullWidth margin="dense">
                            <TextField
                              required
                              id="mobileNumber"
                              label="Mobile Number"
                              fullWidth
                              name="mobileNumber"
                              inputProps={{ maxLength: 100 }}
                              size="small"
                              onChange={props.handleChange("mobileNumber")}
                              onBlur={props.handleBlur("mobileNumber")}
                              value={props.values.mobileNumber}
                              error={
                                props.errors.mobileNumber &&
                                  props.touched.mobileNumber
                                  ? true
                                  : false
                              }
                              className={
                                props.errors.mobileNumber &&
                                  props.touched.mobileNumber
                                  ? "text-input error"
                                  : "text-input"
                              }
                            />
                            <Typography
                              variant="inherit"
                              className="validation-message"
                              component={"span"}
                            >
                              {props.errors.mobileNumber &&
                                props.touched.mobileNumber && (
                                  <Grid
                                    className={
                                      props.errors.mobileNumber &&
                                        props.touched.mobileNumber
                                        ? "text-input error"
                                        : "text-input"
                                    }
                                  >
                                    {props.errors.mobileNumber}
                                  </Grid>
                                )}
                            </Typography>
                          </FormControl>
                        </Grid>
                        {/* <Grid item xs={12} sm={4} md={4}>
                          <FormControl fullWidth>
                            <TextField
                              id="password"
                              label="New Password*"
                              fullWidth
                              size="small"
                              margin="dense"
                              type={props.values.showPassword ? "text" : "password"}
                              value={props.values.password}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={
                                props.errors.password &&
                                  props.touched.password
                                  ? true
                                  : false
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={(e) => {
                                        props.setFieldValue('showPassword', !props.values.showPassword, false)
                                      }}
                                      edge="end"
                                    >
                                      {props.values.showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <Typography variant="inherit" color="red" className="validation-message">
                              {props.errors.password &&
                                props.touched.password && (
                                  <Grid
                                    className={
                                      props.errors.password &&
                                        props.touched.password
                                        ? "text-input error"
                                        : "text-input"
                                    }
                                  >
                                    {props.errors.password}
                                  </Grid>
                                )}
                            </Typography>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <FormControl fullWidth>
                            <TextField
                              id="confirmPassword"
                              label="Confirm Password*"
                              fullWidth
                              size="small"
                              margin="dense"
                              type={props.values.showConfPassword ? "text" : "password"}
                              value={props.values.confirmPassword}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={
                                props.errors.confirmPassword &&
                                  props.touched.confirmPassword
                                  ? true
                                  : false
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={(e) => {
                                        props.setFieldValue('showConfPassword', !props.values.showConfPassword, false)
                                      }}
                                      edge="end"
                                    >
                                      {props.values.showConfPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <Typography variant="inherit" color="red" className="validation-message">
                              {props.errors.confirmPassword &&
                                props.touched.confirmPassword && (
                                  <Grid
                                    className={
                                      props.errors.confirmPassword &&
                                        props.touched.confirmPassword
                                        ? "text-input error"
                                        : "text-input"
                                    }
                                  >
                                    {props.errors.confirmPassword}
                                  </Grid>
                                )}
                            </Typography>
                          </FormControl>
                        </Grid> */}
                        <Grid item xs={12} sm={4} md={4}>
                          <FormControl fullWidth>
                            <TextField
                              required
                              id="email"
                              label="Email"
                              fullWidth
                              size="small"
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              value={props.values.email}
                              disabled={true}
                              error={
                                props.errors.email && props.touched.email
                                  ? true
                                  : false
                              }
                              className={
                                props.errors.email && props.touched.email
                                  ? "text-input error"
                                  : "text-input"
                              }
                            />
                            <Typography variant="inherit" color="red" className="valiation-message">
                              {props.errors.email && props.touched.email && (
                                <Grid
                                  className={
                                    props.errors.email && props.touched.email
                                      ? "text-input error"
                                      : "text-input"
                                  }
                                >
                                  {props.errors.email}
                                </Grid>
                              )}
                            </Typography>
                          </FormControl>
                        </Grid>



                        {/* <Grid
                          container
                          spacing={2}
                          marginTop={1}
                          marginLeft={2.5}
                        >
                          <Grid item xs={12} textAlign="center">
                            <LoadingButton
                              color="primary"
                              type="submit"
                              name="submit"
                              loading={isSubmitting}
                              loadingPosition="start"
                              variant="contained"
                            >
                              Save
                            </LoadingButton>
                          </Grid>
                        </Grid> */}
                      </Grid>


                      <h4 className="page-title" text-align='center' style={{ marginTop: '20px' }}>
                        Account Information
                      </h4>


                      <Grid container spacing={3} marginTop={1}>
                        <Grid item xs={12} sm={4} md={4}>
                          <FormControl fullWidth>
                            <TextField
                              id="password"
                              label="New Password*"
                              fullWidth
                              size="small"
                              margin="dense"
                              type={props.values.showPassword ? "text" : "password"}
                              value={props.values.password}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={
                                props.errors.password &&
                                  props.touched.password
                                  ? true
                                  : false
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={(e) => {
                                        props.setFieldValue('showPassword', !props.values.showPassword, false)
                                      }}
                                      edge="end"
                                    >
                                      {props.values.showPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <Typography variant="inherit" color="red" className="validation-message">
                              {props.errors.password &&
                                props.touched.password && (
                                  <Grid
                                    className={
                                      props.errors.password &&
                                        props.touched.password
                                        ? "text-input error"
                                        : "text-input"
                                    }
                                  >
                                    {props.errors.password}
                                  </Grid>
                                )}
                            </Typography>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                          <FormControl fullWidth>
                            <TextField
                              id="confirmPassword"
                              label="Confirm Password*"
                              fullWidth
                              size="small"
                              margin="dense"
                              type={props.values.showConfPassword ? "text" : "password"}
                              value={props.values.confirmPassword}
                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              error={
                                props.errors.confirmPassword &&
                                  props.touched.confirmPassword
                                  ? true
                                  : false
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={(e) => {
                                        props.setFieldValue('showConfPassword', !props.values.showConfPassword, false)
                                      }}
                                      edge="end"
                                    >
                                      {props.values.showConfPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <Typography variant="inherit" color="red" className="validation-message">
                              {props.errors.confirmPassword &&
                                props.touched.confirmPassword && (
                                  <Grid
                                    className={
                                      props.errors.confirmPassword &&
                                        props.touched.confirmPassword
                                        ? "text-input error"
                                        : "text-input"
                                    }
                                  >
                                    {props.errors.confirmPassword}
                                  </Grid>
                                )}
                            </Typography>
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        spacing={2}
                        marginTop={1}
                        marginLeft={2.5}
                      >
                        <Grid item xs={12} textAlign="center">
                          <LoadingButton
                            color="primary"
                            type="submit"
                            name="submit"
                            loading={isSubmitting}
                            loadingPosition="start"
                            variant="contained"
                          >
                            Save
                          </LoadingButton>
                        </Grid>
                      </Grid>

                    </Form>
                  </Grid>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? '1px solid #0b0b45' : '1px solid #d32f2f',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: state.isFocused ? state.selectProps.color : '#d32f2f',
  }),
}