import React, { useContext, useEffect, useState } from 'react'
import {
  Box, Grid, IconButton, InputAdornment, TextField, Button, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Pagination, Stack, Typography, FormControl, Dialog, Alert, DialogContent, useMediaQuery, useTheme, Chip, Checkbox, tooltipClasses, TooltipProps, Zoom,
  DialogContentText, DialogActions
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import * as yup from "yup";
import { useNavigate } from 'react-router-dom';
import '../../../theme/style.scss';
import LoadingButton from "@mui/lab/LoadingButton";
import CustomBreadcrumbs from '../../../components/BreadcrumbsComponent/CustomBreadcrumbs';
import CloseIcon from "@mui/icons-material/Close";
import { AlertType } from "../../../services/models/AlertType";
// import { CustomSelect } from '../../../components/Common/CustomSelect';
import { MultiSelect } from '../../../components/Common/MultiSelect';
import { PreferencesContext } from '../../../PreferenceContext';
import { PAGE_SIZE } from '../../../services/common/const';
import { DeleteUser, GetUserDeleteList, GetUserList, SendInvite } from '../../../services/UserService';
import { Formik, Form } from 'formik';
import { EncryptDecryptService } from '../../../services/common/EncryptionService';
import { GetUserGroupList } from '../../../services/UserGroupService';
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ClearIcon from "@mui/icons-material/Clear";
import CircularProgress from '@mui/material/CircularProgress';
import { CustomSelect } from '../../../components/Common/CustomSelect';
import { userInfo } from 'os';


const user: any = {
  userCollection: [
    {
      level: 0,
    },
  ],
};
const parameters: any = {
  pageSize: PAGE_SIZE,
  sortColumn: "",
  sortDirection: "",
  totalCount: "",
  maxAllowedUsers: "",
  searchValue: "",
};

let groupColl = {
  user_group_id: 0,
  group_name: "",
  is_deleted: false
}
let userDeleteColl = {
  userId: 0,
  value: "",
  label: ""
}

const initialState = {
  email: "",
  userRole: false,
  userGroup: 0,
  token: "",
  fullName: "",
  userId: 0,
  userGroupId: [groupColl],
  userDeleteId: [userDeleteColl],
  isPasswordSet: null,
  isSubmitted: false,
  isSubmitting: false,
  sendEmail: false,
  message: {},
  isInvitaion: false
};
const initialForm = {
  email: "", userRole: "", userGroupId: [groupColl]
}
const validateSchema = yup.object().shape({
  email: yup.string().email("Email is not valid.").required("Enter an Email."),
  //userRole: yup.string().required("Select User Role."),
  //userGroupId: yup.string().required("User Group is required."),
  userGroupId: yup.array().test('length-check', '"User Group required', (arr) => arr?.length > 0),
  //userGroupId: yup.number().min(1, 'Required').required("Select User Group.").nullable(),
});
const edService = new EncryptDecryptService();

interface Data {
  id: number;
  name: string;
  Address: string;
  Email: string;
  Contact: string;
  Date: string;
  Branch: string;
  none: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc" | "";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface EnhancedTableProps {
  numSelected: number;
  //onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  //onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: String;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, numSelected, rowCount } = props;
  return (
    // checkBox for Header

    <TableHead className='table-header-color'>
      <TableRow>
        <TableCell
          sx={{
            display: {
              xs: "table-cell",
              sm: "table-cell",
              md: "table-cell",
              lg: "table-cell",
            },
            fontWeight: "bold",
            width: "20%",
          }}
          align="left"
        >
          Name
        </TableCell>
        <TableCell
          sx={{
            display: {
              xs: "none",
              sm: "table-cell",
              md: "table-cell",
              lg: "table-cell",
            },
            fontWeight: "bold",
            width: "15%"
          }}
          align="left"
        >
          Role
        </TableCell>
        <TableCell
          sx={{
            display: {
              xs: "none",
              sm: "table-cell",
              md: "table-cell",
              lg: "table-cell",
            },
            fontWeight: "bold",
            width: "20%"
          }}
          align="left"
        >
          Group
        </TableCell>
        <TableCell
          sx={{
            display: {
              xs: "none",
              sm: "table-cell",
              md: "table-cell",
              lg: "table-cell",
            },
            fontWeight: "bold",
            width: "20%"
          }}
          align="left"
        >
          Email
        </TableCell>
        <TableCell
          sx={{
            display: {
              xs: "table-cell",
              sm: "table-cell",
              md: "table-cell",
              lg: "table-cell",
            },
            fontWeight: "bold",
            width: "10%"
          }}
          align="center"
        >
          Status
        </TableCell>
        <TableCell
          sx={{
            display: {
              xs: "table-cell",
              sm: "table-cell",
              md: "table-cell",
              lg: "table-cell",
            },
            fontWeight: "bold",
            width: "30%"
          }}
          align="right"
        >
          {''}
        </TableCell>

      </TableRow>
    </TableHead>
  );
}

// import ClearIcon from "@mui/icons-material/Clear";
export default function UsersList() {
  const navigate = useNavigate();
  const matches = useMediaQuery("(max-width:768px)");
  const theme = useTheme()

  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl3] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const [curDeleteData, setCurDeleteData] = useState(null);
  const [Open, setOpen] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [temp, setTemp] = useState(false)
  const [param, setParam] = useState(parameters);
  const [selected, setSelected] = useState<readonly number[]>(user.userCollection);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [value, setValue] = useState("");
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const { userContext, updateUserContext } = useContext(PreferencesContext);
  const [data, setData] = useState(initialState);
  //const [userDeleteData, setUserDeleteData] = useState(initialState);
  const [resultData, setResultData] = useState(user);
  const [page_Index, setPage_Index] = useState(1);
  const [order, setOrder] = useState<Order>("");
  const [orderBy, setOrderBy] = useState<keyof Data>("none");
  const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE);
  const [counData, setCounData] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageStartIndex, setPageStartIndex] = useState(1);
  const [pageEndIndex, setPageEndIndex] = useState(1);
  const [selectedDeleteId, setSelectedDeleteId] = useState("");
  const [selectedRole, setselectedRole] = useState("User");
  const [Curr_page, setCurrPage] = useState(1);
  const [UserGroupCollection, setUserGroupCollection] = useState([]);
  const [GroupCollection, setGroupCollection] = useState([]);
  const [UserDeleteCollection, setUserDeleteCollection] = useState([]);
  const [userDeleteData, setUserDeleteData] = useState([]);
  const [showAlert, setAlertMessage] = React.useState({
    isSuccess: false,
    type: 0,
    message: "",
  });

  const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      // backgroundColor: theme.palette.common.black
      backgroundColor: "#ac8445",
    },
  }));

  const handleChangePageNew = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    if (Curr_page !== value) {
      setCurrPage(value);
      setPage_Index(value);
      setParam({ ...param, pageIndex: value });
      fetchUserList(null);
    }
  };

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  async function handleEditUser(userId: any) {
    try {
      let encUserId: any = edService.encrypt(userId);
      // let roleType = item?.role === "Admin" ? "A" : "U";
      navigate({
        pathname: "/users/users-save",
        search: `?q=${encodeURIComponent(encUserId)}`,
      }, {
        state: { title: " Edit Tag", button: "Update", userId: userId, isAddPopUp: "false" },
      });
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }
  }

  const handleOpenDeleteUserModal = (curDeletedData: any) => {
    setCurDeleteData(curDeletedData);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setCurDeleteData(null);
  }

  async function handleDeleteUser(data: any, userDeleteId: string) {
    debugger
    try {
      let DeleteData = {
        userDeleteId: userDeleteId.toString(),
        ids: data.userId.toString(),
        isDeleted: true,
        dataManagedBy: userContext.userId,
        role: data.role,
        isInvitaion: data.isInvitaion
      };
      const result: any = await DeleteUser(DeleteData);
      if (result) {
        //setPopOverOpen(null);
        fetchUserList(data.role);
        setOpenDeleteModal(false);
        setCurDeleteData(null);
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: result.message,
          alertType: AlertType.Success,
        });
      } else {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: result.message,
          alertType: AlertType.Error,
        });
      }
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }
    setDeleteModal(false);
  }

  const handleClose = (val: any) => {
    setOpen(false);
    setEmailValue("");
    setTemp(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  }

  function searchValue(val: any) {
    if (val === "") {
      setValue("");
    }
    setLoading(false);
    setPage_Index(1);
    setParam({ ...param, searchValue: val.toString(), pageIndex: 1 });
  }

  //Async Function 
  async function fetchUserGroupList() {
    try {
      //new http for API
      setLoading(true);
      let params = new URLSearchParams();
      params.append("pageIndex", page_Index.toString());
      params.append("pageSize", param.pageSize);
      params.append("sortColumn", param.sortColumn);
      params.append("sortDirection", param.sortDirection);
      params.append("searchValue", param.searchValue);
      const result: any = await GetUserGroupList(params);
      if (result && result.data.data) {
        let userGroupInfo = result.data.data.userGroupCollection;
        setGroupCollection(userGroupInfo);
        setUserGroupCollection(userGroupInfo);
      } else {
        console.log("Error");
      }
      setLoading(false);
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }
  }

  async function fetchUserList(role: any) {
    try {
      setLoading(true);
      //new http for API
      let params = new URLSearchParams();
      params.append("role", userContext.userRole);
      params.append("pageIndex", page_Index.toString());
      params.append("pageSize", param.pageSize);
      params.append("sortColumn", param.sortColumn);
      params.append("sortDirection", param.sortDirection);
      params.append("searchValue", param.searchValue);
      const result: any = await GetUserList(params);
      if (result && result.data.data) {
        let dataCount = result.data.data.totalCount;
        let UserInfo = result.data.data;
        let showDataCount = Math.ceil(dataCount / PAGE_SIZE);
        setResultData(UserInfo);
        setCounData(showDataCount);
        let startIndex = (page_Index - 1) * param.pageSize + 1;
        let endIndex =
          (page_Index - 1) * param.pageSize +
          (result.data.data.userCollection
            ? result.data.data.userCollection.length
            : 0);

        setPageStartIndex(startIndex);
        setPageEndIndex(endIndex);
        setTotalCount(dataCount);
      } else {
        console.log("Error");
      }
      setLoading(false);
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }
  }
  async function fetchDeleteUserList() {
    try {
      setLoading(true);
      //new http for API
      let params = new URLSearchParams();
      params.append("role", userContext.userRole);
      params.append("pageIndex", page_Index.toString());
      params.append("pageSize", param.pageSize);
      params.append("sortColumn", param.sortColumn);
      params.append("sortDirection", param.sortDirection);
      params.append("searchValue", param.searchValue);
      const result: any = await GetUserDeleteList(params);
      if (result && result.data.data) {
        let UserInfo = result.data.data.userDeleteCollection;
        console.table("UserInfo", UserInfo)
        setUserDeleteData(UserInfo);
        setUserDeleteCollection(UserInfo);
      } else {
        console.log("Error");
      }
      setLoading(false);
    } catch (e: any) {
      updateUserContext({
        ...userContext,
        isAlert: true,
        alertMessage: typeof e === 'string' ? e as string : e?.message as string,
        alertType: AlertType.Error,
      });
    }
  }
  async function submitForm(values: any, resetForm: any) {
    setLoading(true);
    setIsSubmitting(false);
    setSendEmail(true);
    setData({
      ...data,
      isSubmitting: false,
      sendEmail: true,
      isSubmitted: false,
      email: values.email,
    });
    let useContextData = userContext;
    const arr1 = values.userGroupId;
    let groupRecords = arr1.filter((e: any) => e.user_group_id >= 0);
    try {
      let formData = {
        email: values.email,
        fullName: useContextData.firstName + " " + useContextData.lastName,
        userId: useContextData.userId,
        role: (values?.userRole === true) ? 'Admin' : 'User',
        token: "",
        userGroupId: groupRecords.length > 0 ? JSON.stringify(groupRecords) : "",
        isDeleted: false,
        isPasswordSet: false,
        isReinvite: values?.isReinvite || false
      };

      const result: any = await SendInvite(formData);
      if (formData.isReinvite) {
        updateUserContext({
          ...userContext,
          isAlert: true,
          alertMessage: result.message,
          alertType: AlertType.Success,
        })
      }

      setIsSubmitting(false);
      setSendEmail(false);

      //--After seccessfully Submit--:
      if (result) {

        if (resetForm !== null) {
          resetForm()
        }
        // const result: any = await GetUserList(params);
        fetchUserList(null)
        setData({
          ...data,
          isSubmitting: false,
          sendEmail: false,
          isSubmitted: false,
          email: "",
          userRole: false,
        });
        setAlertMessage({
          type: AlertType.Success,
          isSuccess: true,
          message: result.message as string,
        });
        setEmailValue("");
        setLoading(false);
        setIsSubmitting(false);
        setSendEmail(false);
      }
    } catch (error) {
      setLoading(false);
      setData({
        ...data,
        isSubmitting: false,
        sendEmail: false,
        isSubmitted: false,
        email: values.email,
      });

      if (error) {
        setAlertMessage({
          type: AlertType.Error,
          isSuccess: false,
          message: error as string,
        });

        setIsSubmitting(false);
        setSendEmail(false);
      }
    }
    setTimeout(() => {
      setAlertMessage({
        type: 0,
        isSuccess: false,
        message: "",
      });
    }, 5000);
    // window.location.reload(false);
  }
  function getEmailValue(e: any) {
    setEmailValue(e);
    setAlertMessage(e);
  }

  //  Table Header 
  useEffect(() => {
    fetchUserList(null);
    fetchDeleteUserList();
    setSelected([]);
    let e: any = document.getElementById("root");
    e.scrollIntoView({
      block: "start",
      behavior: "smooth",
      inline: "start",
    });
  }, [page_Index, param]);

  useEffect(() => {
    if (Open === true) {
      fetchUserGroupList();
    }
  }, [Open]);

  return (
    <Formik
      initialValues={initialForm}
      validationSchema={validateSchema}
      enableReinitialize
      initialTouched={
        {
          email: true,
          userGroupId: true,
          userRole: true
        }
      }
      onSubmit={(values: any, { resetForm }) => {
        submitForm(values, resetForm);
      }}
    >
      {(props: any) => {
        const avatarStyle = { backgroundColor: "#1bbd7e" };
        return (
          <>
            <Box className='main-container'>
              <Grid item xs={12} container className='page-header'>
                <Grid xs={6} sm={6} item className="page-breadcrumbs">
                  <CustomBreadcrumbs />
                </Grid>
                <Grid xs={6} sm={6} item className='page-header-icon'>
                  <Button
                    className="button-border-remove"
                    color="primary"
                    variant="contained"
                    // onClick={() => navigate('users-save')}
                    onClick={handleClickOpen}
                    size="medium"
                    startIcon={<AddIcon />}
                  >
                    Add
                  </Button></Grid>
              </Grid>
              {isLoading === true ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
              </Box> :
                <Grid container className="list-container " sx={{ padding: '16px' }}>
                  <Grid item xs={12} sm={4} md={3} lg={2} className='search-bar' sx={{ gap: 2 }} >
                    <TextField
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          searchValue(value);
                        }
                      }}
                      placeholder="Search..."
                      type="text"
                      variant="outlined"
                      size="small"
                      onChange={(e) => setValue(e.target.value)}
                      // onKeyDown={() => searchValue(value)}
                      value={value}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              onClick={() => searchValue(value)}
                            >
                              {" "}
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),

                        endAdornment: value && (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => searchValue("")}
                          >
                            <ClearIcon />
                          </IconButton>
                        ),
                      }}
                    />
                  </Grid>

                  <TableContainer className='table'>
                    <Table
                      aria-labelledby="tableTitle"
                      size={dense ? "small" : "medium"}>
                      <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        //onSelectAllClick={handleCheckSelectAll}
                        //onRequestSort={handleRequestSort}
                        rowCount={resultData.userCollection.length}
                      />
                      <TableBody>
                        {stableSort(
                          resultData.userCollection,
                          getComparator(order, orderBy)
                        )
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((item: any, index: any) => {
                            return (
                              <>
                                {matches && <TableRow
                                  key={{ index }}
                                ><TableCell colSpan={10} sx={{ border: 'none !important' }}>{item.email}</TableCell></TableRow>}
                                <TableRow >
                                  {/* <TableCell
                                sx={{
                                  display: {
                                    xs: "table-cell",
                                    sm: "none",
                                    md: "none",
                                    lg: "none",
                                  },
                                }}
                              >
                                <Typography style={{ display: 'flex', flexWrap: 'wrap' }}>{item.email}</Typography>
                                <Typography sx={{
                                  fontSize: '11px !important', paddingTop: '10px !important'
                                }}>
                                  {item.role}
                                </Typography>
                              </TableCell> */}
                                  <TableCell
                                    sx={{
                                      display: {
                                        xs: "table-cell",
                                        sm: "none",
                                        md: "none",
                                        lg: "none",
                                      }
                                    }}
                                  >
                                    <Typography style={{ display: 'flex', flexWrap: 'wrap' }}>{item.firstName + " " + item.lastName}</Typography>
                                    <Typography sx={{
                                      fontSize: '11px !important', paddingTop: '10px !important'
                                    }}>
                                      {item.role}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      display: {
                                        xs: "none",
                                        sm: "table-cell",
                                        md: "table-cell",
                                        lg: "table-cell",
                                      }
                                    }}
                                  >{item.firstName + " " + item.lastName}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      display: {
                                        xs: "none",
                                        sm: "table-cell",
                                        md: "table-cell",
                                        lg: "table-cell",
                                      }
                                    }}
                                  >{item.role}</TableCell>
                                  <TableCell
                                    sx={{
                                      display: {
                                        xs: "none",
                                        sm: "table-cell",
                                        md: "table-cell",
                                        lg: "table-cell",
                                      },
                                      textAlign: "left"
                                    }}
                                  >
                                    {item.userGroupName}
                                  </TableCell>
                                  <TableCell sx={{
                                    display: {
                                      xs: "none",
                                      sm: "table-cell",
                                      md: "table-cell",
                                      lg: "table-cell",
                                    }
                                  }}>{item.email}</TableCell>

                                  <TableCell sx={{
                                    display: {
                                      xs: "table-cell",
                                      sm: "none",
                                      md: "none",
                                      lg: "none",
                                    }
                                  }}>
                                    <Typography style={{ display: 'flex', flexWrap: 'wrap' }}><span className='text-link' style={{ color: 'blue !important', cursor: 'pointer' }}
                                      onClick={
                                        () => {
                                          if (item.role === "Admin") {
                                            setselectedRole("Admin");
                                          } else if (item.role === "") {
                                            setselectedRole("User");
                                          }
                                          let ReinviteItem = item;
                                          ReinviteItem.isReinvite = true;
                                          submitForm(ReinviteItem, null);
                                        }}
                                    >
                                      Reinvite
                                    </span></Typography>
                                    <Typography sx={{
                                      fontSize: '11px !important', paddingTop: '10px !important'
                                    }}>
                                      {item.userGroupName}
                                    </Typography>
                                  </TableCell>
                                  {item.aspNetUserID === '' ?
                                    <TableCell sx={{
                                      display: {
                                        xs: "none",
                                        sm: "table-cell",
                                        md: "table-cell",
                                        lg: "table-cell",
                                      }
                                    }}>
                                      <span className='text-link' style={{ color: 'blue !important', cursor: 'pointer' }}
                                        onClick={
                                          () => {
                                            if (item.role === "Admin") {
                                              setselectedRole("Admin");
                                            } else if (item.role === "") {
                                              setselectedRole("User");
                                            }
                                            let ReinviteItem = item;
                                            ReinviteItem.isReinvite = true;
                                            submitForm(ReinviteItem, null);
                                          }}
                                      >
                                        Reinvite
                                      </span>
                                    </TableCell> : <TableCell sx={{
                                      display: {
                                        xs: "none",
                                        sm: "table-cell",
                                        md: "table-cell",
                                        lg: "table-cell",
                                      }
                                    }} />
                                  }

                                  <TableCell align="right">
                                    <Box sx={{ display: 'flex !important', width: '100%', justifyContent: 'end' }}>
                                      {userContext.userId === item.userId ?
                                        <Tooltip title="Me" placement="top" sx={{ cursor: "pointer" }}>
                                          <Chip color="primary" label="Me" />
                                        </Tooltip>
                                        :
                                        <>{item.isInvitaion !== true &&
                                          <Tooltip title="Edit" placement="top">
                                            <IconButton className='edit-container'
                                              onClick={() => handleEditUser(item.userId)}>
                                              <EditIcon />
                                            </IconButton>
                                          </Tooltip>
                                        }
                                          <Tooltip title="Delete" placement="top">
                                            <IconButton className='delete-container'
                                              onClick={() => handleOpenDeleteUserModal(item)
                                              }>
                                              <DeleteIcon />
                                            </IconButton>
                                          </Tooltip>
                                        </>
                                      }
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              </>
                            )
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {resultData.userCollection.length > 0 && (
                    <Grid
                      container
                      paddingTop={1}
                      alignItems={"center"}
                      justifyContent={"center"}
                      className='pagination'
                    >
                      <Grid item xs={12} sm={6}
                        className="pagination-label"
                      >
                        {pageStartIndex} - {pageEndIndex} of {totalCount}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Stack
                          component="div"
                          alignItems={mobileScreen ? " center" : "end"}
                          justifyContent={"center"}
                        >
                          <Pagination
                            color="primary"
                            count={counData}
                            page={page_Index}
                            onChange={handleChangePageNew}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  )}
                </Grid>}

            </Box>
            <Dialog
              sx={{
                "& .MuiDialog-paper": { width: "80%", maxHeight: 535, borderRadius: 0 },
                borderRadius: 0
              }}
              maxWidth="xs"
              open={Open}
              onClose={(event, reason) => {
                if (reason && reason === "backdropClick") {
                  return;
                } else {
                  handleClose(false);
                }
              }}
              className="invite-modal">
              <Box className="invite-member-main-box">
                <Grid container sx={{ padding: 5 }}>
                  <Grid item container xs={12} sx={{ padding: "0px !important" }}>
                    <Grid item xs={6} sx={{ fontSize: "20px", fontWeight: "bold", padding: "0px !important" }}>
                      Invite User
                    </Grid>
                    <Grid item xs={6} sx={{ display: "flex", justifyContent: "end" }}>
                      <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sx={{ padding: "0px !important" }}>
                    {showAlert.isSuccess === true &&
                      showAlert.type === AlertType.Success ? (
                      <Box >
                        <Box>
                          <Stack sx={{ width: "100%" }}>
                            <Alert severity="success">{showAlert.message}</Alert>
                          </Stack>
                        </Box>
                      </Box>
                    ) : showAlert.isSuccess === false &&
                      showAlert.type === AlertType.Error ? (
                      <Box >
                        <Stack sx={{ width: "100%" }}>
                          <Alert severity="error">{showAlert.message}</Alert>
                        </Stack>
                      </Box>
                    ) :
                      null
                    }
                  </Grid>
                  <DialogContent className="email-form-content">
                    <Form onSubmit={props.handleSubmit}>
                      <Grid item xs={12} sx={{ display: "flex" }}>
                        <FormControl>
                          <Typography>
                            <Checkbox
                              id={"userRole"}
                              value={props.values.userRole}
                              checked={props.values.userRole}
                              name="userRole"
                              onChange={(e) => {
                                props.setFieldValue(
                                  "userRole",
                                  e.target.checked !== null
                                    ? e.target.checked
                                    : "",
                                  true
                                );
                              }}
                            />
                            Is Admin
                          </Typography>
                        </FormControl>
                        <Box sx={{ display: "flex", paddingLeft: "5px" }}>
                          <BootstrapTooltip
                            onClose={handleTooltipClose}
                            open={openTooltip}
                            TransitionComponent={Zoom}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              'Admin can access Users.'
                            }
                          >
                            <span style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
                              <HelpOutlineIcon
                                onMouseEnter={handleTooltipOpen}
                                onMouseLeave={handleTooltipClose}
                                onClick={handleTooltipOpen}
                                sx={{ cursor: 'pointer' }}
                                color="primary" />
                            </span>
                          </BootstrapTooltip>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sx={{ paddingTop: 2 }}>
                        <FormControl fullWidth>
                          <MultiSelect
                            id={"userGroupId"}
                            FieldName={"userGroupId"}
                            menuPortalTarget={document.querySelector("body")}
                            placeholder="Group *"
                            onCreateOption={false}
                            openMenuOnFocus
                            isClearable={false}
                            options={UserGroupCollection}
                            // onFocus={(e) => {
                            //   setOpenCreateTag({ ...openCreateTag, event: e })
                            // }}
                            // handleChange={(selectedOptions) => {
                            //   props.setFieldValue(
                            //     "tagId",
                            //     selectedOptions.map((option) => option.value) || [],
                            //     true
                            //   );
                            // }}
                            handleChange={(selectedOptions: any[]) => {
                              const group = selectedOptions.map((option) => ({
                                user_group_id: option.value, // set tag value
                                group_name: option.label, // set tag label
                                is_deleted: false, // by default false
                              }));
                              props.setFieldValue("userGroupId", group, true);
                            }}
                            // Value={tagCollection.filter((option: any) =>
                            //   Array.isArray(props.values.tagId) && props.values.tagId.includes(option.value)
                            // )}
                            Value={GroupCollection.filter((option: any) =>
                              Array.isArray(props.values.userGroupId) &&
                              props.values.userGroupId.map(x => x.user_group_id)?.includes(option.value))
                            }

                          />
                          {/* <CustomSelect
                            id={"userGroupId"}
                            FieldName={"userGroupId"}
                            menuPortalTarget={document.querySelector("body")}
                            placeholder="User Group *"
                            isNotCreatable={true}
                            isClearable={false}
                            openMenuOnFocus
                            autoFocus
                            options={UserGroupCollection}
                            handleChange={(e: any) => {
                              setselectedRole(e?.value)
                              props.setFieldValue(
                                `userGroupId`,
                                e.value !== null ? e.value : null,
                                true
                              );
                            }}
                            Value={UserGroupCollection?.find((x: any) => x?.value?.toString() === props.values.userGroupId?.toString()) || null}
                          />
                          {props.errors.userGroupId &&
                            props.touched.userGroupId && (
                              <Typography
                                variant="inherit"
                                className={
                                  props.errors.userGroupId &&
                                    props.touched.userGroupId
                                    ? "validation-message text-input error"
                                    : "validation-message text-input"
                                }
                              >
                                {props.errors.userGroupId}
                              </Typography>
                            )} */}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sx={{ paddingTop: 2 }}>
                        <FormControl fullWidth>
                          <TextField
                            id="email"
                            placeholder="user-name@yourcompany.com"
                            label="Email*"
                            size="small"
                            fullWidth
                            margin="dense"
                            value={emailValue}
                            onChange={(e) => {
                              props.setFieldValue(
                                `email`,
                                e.target.value !== null ? e.target.value : null,
                                true
                              );
                              getEmailValue(e.target.value);
                            }}
                            onBlur={props.handleBlur}
                            error={
                              props.errors.email && props.touched.email
                                ? true
                                : false
                            }
                          />
                          {props.errors.email && props.touched.email && (
                            <Typography
                              className={
                                props.errors.email && props.touched.email
                                  ? "validation-message text-input error"
                                  : "validation-message text-input"
                              }>
                              {props.errors.email}
                            </Typography>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sx={{ paddingTop: 2 }}
                        textAlign="right"
                        className="button-main-box">
                        <LoadingButton
                          className="button-border-remove"
                          color="primary"
                          loading={sendEmail}
                          loadingPosition="start"
                          fullWidth
                          startIcon={<></>}
                          variant="contained"
                          type="submit">
                          Submit
                        </LoadingButton>
                      </Grid>
                    </Form>
                  </DialogContent>
                </Grid>
              </Box>
            </Dialog >
            {/* Delete modal popup - starts */}
            <Dialog
              sx={{
                "& .MuiDialog-paper": { width: "80%", maxHeight: 535, borderRadius: 0 },
                borderRadius: 0
              }}
              open={isOpenDeleteModal}
              onClose={handleCloseDeleteModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{ color: "black" }}>
                  Are you sure you want to delete the selected transfer data for the user?
                </DialogContentText>
              </DialogContent>
              <Grid item xs={12} sx={{ padding: 2 }}>
                {console.log('UserDeleteCollection', UserDeleteCollection)}
                <FormControl fullWidth>
                  <CustomSelect
                    id={"userDeleteId"}
                    FieldName={"userDeleteId"}
                    menuPortalTarget={document.querySelector("body")}
                    placeholder="User *"
                    onCreateOption={false}
                    openMenuOnFocus
                    isClearable={false}
                    options={UserDeleteCollection}
                    handleChange={(e: any) => {
                      debugger
                      setSelectedDeleteId(e?.value || null);  // Set the selected user ID
                      props.setFieldValue(
                        `userDeleteId`,
                        e?.value !== null ? e.value : null,  // Update form value
                        true
                      );
                    }}
                    // handleChange={(selectedOptions: any[]) => {
                    //   debugger
                    //   const Deletegroup = selectedOptions.map((option) => ({
                    //     userId: option.value, // set tag value
                    //   }));
                    //   props.setFieldValue("userDeleteId", Deletegroup, true);
                    // }}
                    // Value={tagCollection.filter((option: any) =>
                    //   Array.isArray(props.values.tagId) && props.values.tagId.includes(option.value)
                    // )}
                    Value={UserDeleteCollection?.find(x => x?.value === selectedDeleteId)
                    }
                  />
                  {
                    (selectedDeleteId === "" || selectedDeleteId === null || selectedDeleteId === undefined) &&
                    <Typography sx={{ color: "red", paddingTop: 1 }}>Please select user</Typography>
                  }
                </FormControl>
              </Grid>
              {/* {console.log('selectedDeleteId',selectedDeleteId)} */}
              <DialogActions sx={{
                display: "flex",
                justifyContent: "center"
              }}>
                <Button
                  onClick={() => handleDeleteUser(curDeleteData, selectedDeleteId)}
                  disabled={selectedDeleteId === "" || selectedDeleteId === null || selectedDeleteId === undefined}
                  sx={{
                    backgroundColor: "#d44d4a",
                    cursor: "pointer !important",
                    color: "white",
                    '&:hover': {
                      backgroundColor: "#e55b5f",
                      cursor: "pointer !important", // Change this to the desired hover color
                    },
                  }}
                >
                  Yes
                </Button>
                <Button
                  sx={{
                    backgroundColor: "#9e7b4f",
                    cursor: "pointer !important",
                    color: "white",
                    '&:hover': {
                      backgroundColor: "#b5935d",
                      cursor: "pointer !important", // Change this to the desired hover color
                    },
                  }}
                  onClick={handleCloseDeleteModal}
                  autoFocus
                >
                  No
                </Button>
              </DialogActions>
            </Dialog>
            {/* Delete modal popup - ends */}
          </>
        );
      }}
    </Formik >
  );
}
