import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import  Logo from "../assets/images/logo.png";

type TLogo = {
  className: string;
  size: number;
};

export default function TiyaDocsLogo(props: TLogo = { className: 'logo', size: 56 }) {
  let navigate = useNavigate();
  const homeClick = () => {
    navigate("/");
  };

  return (<>
    <div className={props.className} onClick={homeClick}>
        <img src={Logo} style={{ height: props.size+'px', width:'auto' }} alt="TRIRID Logo"></img>
    </div>
  </>);
}